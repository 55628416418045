import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { Carousel as ReactCarousel, Modal } from 'react-bootstrap';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import ImageMapper from 'react-img-mapper';
import InnerImageZoom from 'react-inner-image-zoom';
import CarouselTabs from './CarouselTabs/CarouselTabs';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton/PrimaryButton';
import { RoomInterfaceContext } from '../../../../../context/RoomInterface';
import { getColor } from '../MainFn';
import { isArrEmpty, isObjEmpty } from '../../../../../utils/utils';
import kitchen1 from '../../../../../assets/images/kitchen_1_image.jpg';
import interface1 from '../../../../../assets/images/interface.jpg';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';

const Carousel = props => {
  const { carousel, setCarousel } = props;
  const {
    detailsActive,
    setDetailsActive,
    calculationList,
    isCalculationChecked,
    setCalculationList,
    calculationIds,
    detailJSON,
    carouselIndex,
    setCarouselIndex,
    roomInterfaceData,
    allAreas,
    setCalculationActive,
    setSelectedArea,
    selectedArea,
    setCalculationIds,
    carouselPhotosIndex,
    setCarouselPhotosIndex,
    setPhotos,
  } = useContext(RoomInterfaceContext);
  const { interfaceData } = roomInterfaceData;
  const { images_links: images, floorPlan: floor, additionalImages_links: photos, damageImages_links: damagePhotosList, platformImages_links: fakeDamagePhotosList} = interfaceData;
  // const images = [kitchen1, interface1];
  const [areas, setAreas] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(carouselIndex);
  const [selectedPhotosIndex, setSelectedPhotosIndex] = useState(carouselPhotosIndex);

  const [imgScale, setImgScale] = useState(1);
  const [preview, setPreview] = useState(false);
  const [imgDimen, setImgDimen] = useState(null);
  const blockRef = useRef(null);

  const allPhotos = images.concat(photos);
  const damagePhotos = Array.isArray(damagePhotosList) && damagePhotosList.length > 0 ? damagePhotosList : []
  const fakeDamagePhotos = Array.isArray(fakeDamagePhotosList) && fakeDamagePhotosList.length > 0 ? fakeDamagePhotosList : []
  useEffect(() => {
    setSelectedImageIndex(carouselIndex);
    if (!isArrEmpty(allAreas)) {
      const area = allAreas[carouselIndex];
      setAreas(area);
    }
  }, [carouselIndex, loaded, carousel, detailJSON, allAreas]);

  useEffect(() => {
    setLoaded(carousel === 'floor');
    setPhotos(allPhotos);
  }, [carousel]);
  useEffect(() => {
    setLoaded(carousel === 'images');
  }, [carousel]);
  useEffect(() => {
    setSelectedPhotosIndex(carouselPhotosIndex);
  }, [carouselPhotosIndex]);

  const addHighlightColor = useCallback(
    (obj, bool, everyArea = allAreas[carouselIndex]) =>
      everyArea.map(cur => {
        const pureObject = Object.entries(obj).reduce((acc, [key, value]) => {
          if (value) {
            acc[key] = value;
            return acc;
          }
          return acc;
        }, {});
        if (JSON.stringify(cur.navigate) === JSON.stringify(pureObject)) {
          if (cur.id[0] == carouselIndex) setSelectedArea(cur);
          const temp = { ...cur };
          temp.preFillColor = temp.active ? 'rgba(255, 0, 0, 0.3)' : getColor(temp.history[0].id);
          return temp;
        }
        return bool ? cur : false;
      }),
    [allAreas, carouselIndex]
  );

  // HIGHLIGHT MAP WHEN ACCORDION CLICKED
  useEffect(() => {
    if (!isObjEmpty(detailsActive)) {
      const updatedAreas = addHighlightColor(detailsActive, true);
      setAreas(updatedAreas);
    }
  }, [detailsActive, addHighlightColor]);

  // HELP WHEN CHECKBOX UNCHECKED THEN REVERT TO THE LAST HIGHLIGHT
  useEffect(() => {
    if (!isArrEmpty(calculationIds) && isCalculationChecked) {
      let updatedIds;
      if (isCalculationChecked === 'on') {
        const uids = calculationList.map(data => data.unique_id);
        const calId = allAreas[carouselIndex].map(data => {
          if (uids.includes(data.unique_id)) {
            return data.navigate;
          }
        });
        const filterCalId = calId.filter(data => data !== undefined);
        updatedIds = filterCalId.flatMap(id => addHighlightColor(id, false).filter(cur => cur));
      } else if (isCalculationChecked === 'off') {
        updatedIds = addHighlightColor(detailsActive, true);
      }
      setAreas(updatedIds);
    }
  }, [detailsActive, calculationIds, isCalculationChecked, addHighlightColor, carouselIndex]);

  // HELP TO DO HIGHLIGHT ALL MAPS WHEN CHECKBOX CHECKED
  useEffect(() => {
    const updatedIds = calculationIds.flatMap(id =>
      addHighlightColor(
        id,
        false,
        allAreas.flatMap(cur => cur)
      ).filter(cur => cur)
    );
    if (updatedIds.length > 0) {
      let lastData = updatedIds[updatedIds.length - 1];
      let dupData = updatedIds.filter(item => item.unique_id !== lastData.unique_id);

      if (updatedIds.length - dupData.length > 1) {
        const newIds = dupData.map(item => item.navigate);
        setCalculationList(dupData);
        setCalculationIds(newIds);
      } else setCalculationList(updatedIds);
    } else setCalculationList([]);
  }, [calculationIds, allAreas]);

  // HighLight With Same Unique Id
  useEffect(() => {
    const everyArea = allAreas[carouselIndex];
    if (!selectedArea.name) return;
    const newArea = everyArea.map(cur1 => {
      const cur = { ...cur1 };
      if (cur.unique_id === selectedArea.unique_id) {
        setDetailsActive(cur.navigate);
        cur.preFillColor = cur.active ? 'rgba(255, 0, 0, 0.3)' : getColor(cur.history[0].id);
      }
      return cur;
    });
    setAreas(newArea);
  }, [carouselIndex]);

  useEffect(() => {
    if (!detailsActive.root) setSelectedArea({});
  }, [detailsActive]);

  const onSelectedModule = e => {
    const selectedArea = e;
    setSelectedArea(e);
    selectedArea.preFillColor = e.active ? 'rgba(255, 0, 0, 0.3)' : getColor(selectedArea.history[0].id);
    const updatedAreas = allAreas[carouselIndex].map(cur =>
      cur.key === selectedArea.key ? selectedArea : cur
    );
    setDetailsActive(e.navigate);
    setAreas(updatedAreas);
  };

  const handleCarouselSlide = e => {
    setCarouselIndex(e);
    setCalculationActive({});
  };
  const handlePhotosCarouselSlide = e => {
    setCarouselPhotosIndex(e);
  };

  const handleOnLoad = e => {
    if (+e.useMap.slice(-1) === images.length - 1 && !loaded) setLoaded(true);
  };

  // if (isArrEmpty(areas)) return null;

  const onNext = () => {
    setSelectedImageIndex((selectedImageIndex + 1) % images.length);
  };
  const onPrev = () => {
    setSelectedImageIndex(Math.abs(selectedImageIndex - 1) % images.length);
  };
  const onNextPhotos = () => {
    setSelectedPhotosIndex((selectedPhotosIndex + 1) % allPhotos.length);
  };
  const onPrevPhotos = () => {
    setSelectedPhotosIndex(Math.abs(selectedPhotosIndex - 1) % allPhotos.length);
  };

  const calWidth = relativeHeight => {
    return (relativeHeight * imgDimen?.width) / imgDimen?.height;
  };

  return (
    <div id="interface-carousel">
      <CarouselTabs carousel={carousel} setCarousel={setCarousel} />
      {carousel === 'images' ? (
        <ReactCarousel
          ref={blockRef}
          className="bootstrap_carousel"
          interval={null}
          prevIcon={<FiChevronLeft color="#8282828E" size={50} />}
          nextIcon={<FiChevronRight color="#8282828E" size={50} />}
          onSelect={handleCarouselSlide}
          activeIndex={carouselIndex}
        >
          {(images).map((cur, i) => {
            const props = {};
            if (areas.length) {
              props.parentWidth = (blockRef?.current?.element?.clientWidth || 0) - 3
              props.responsive = true
            }
            else
              props.natural = true
            return <ReactCarousel.Item key={i}>
              {blockRef.current && (
                <ImageMapper
                  className="carousel_image"
                  src={cur}
                  map={{ name: `interface-map-${i}`, areas }}
                  onClick={onSelectedModule}
                  onLoad={handleOnLoad}
                  areaKeyName="key"
                  {...props}
                />
              )}
            </ReactCarousel.Item>
          })}
        </ReactCarousel>
      ) :
        carousel === 'photos' ? (
          <ReactCarousel
            className="bootstrap_carousel"
            interval={null}
            prevIcon={<FiChevronLeft color="#8282828E" size={50} />}
            nextIcon={<FiChevronRight color="#8282828E" size={50} />}
            onSelect={handlePhotosCarouselSlide}
            activeIndex={carouselPhotosIndex}
          >
            {[...allPhotos, ...damagePhotos, ...fakeDamagePhotos].map((cur, i) => (
              <ReactCarousel.Item key={i}>
                <img
                  className="carousel_image"
                  src={cur}
                  responsive
                />
              </ReactCarousel.Item>
            ))}
          </ReactCarousel>
        ) : carousel === 'damage' ? (
          <ReactCarousel
            className="bootstrap_carousel"
            interval={null}
            prevIcon={<FiChevronLeft color="#8282828E" size={50} />}
            nextIcon={<FiChevronRight color="#8282828E" size={50} />}
            onSelect={handlePhotosCarouselSlide}
            activeIndex={carouselPhotosIndex}
          >
            {damagePhotos.map((cur, i) => {
              return <ReactCarousel.Item key={i}>
                <img
                  className="carousel_image"
                  src={cur}
                  responsive />
              </ReactCarousel.Item>
            })}
          </ReactCarousel>
        ) : (
          <img className="carousel_image w-100" src={floor} alt="First slide" />
        )}

      <div className="preview-btn">
        <PrimaryButton
          className="new_claims_btn float-left mr-3"
          title="Zoom"
          onClick={() => setPreview(true)}
        />
      </div>


      <Modal

        onHide={() => {
          setPreview(false);
          setSelectedPhotosIndex(carouselPhotosIndex);
          setSelectedImageIndex(carouselIndex);
        }}
        contentClassName="preview-img-modal modelzoomeffect"
        show={preview}
        centered
        animation
        size="md"
        className="shadow-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" />
        </Modal.Header>

        {carousel === 'images' && (
          <div onClick={onPrev} className="arrow arrow-left">
            <FiChevronLeft className="arrow-icon" color="#ffffff" />
          </div>
        )}
        {carousel === 'photos' && (
          <div onClick={onPrevPhotos} className="arrow arrow-left">
            <FiChevronLeft className="arrow-icon" color="#ffffff" />
          </div>
        )}

        {/* <img
          style={{
            width: '50vw',
            height: '50vh',
            objectFit: 'contain',
            transform: `scale(${imgScale})`,
          }}
          src={images[selectedImageIndex]}
        /> */}
        {/* <div style={{ transform: `scale(${imgScale})`, height: 400, width: calWidth(400) }}> */}
        <InnerImageZoom
          // width= {100}
          // height="10000px"
          className="image-wrapper"
          src={carousel === 'images' ? images[selectedImageIndex] : carousel === 'photos' ? allPhotos[selectedPhotosIndex] : floor}
          zoomSrc={carousel === 'images' ? images[selectedImageIndex] : carousel === 'photos' ? allPhotos[selectedPhotosIndex] : floor}
        />
        {/* <PinchZoomPan>
            <img
              onLoad={({ target }) =>
                setImgDimen({ width: target.offsetWidth, height: target.offsetHeight })
              }
              style={{
                objectFit: 'contain',
                transform: `scale(${imgScale})`,
              }}
              src={images[selectedImageIndex]}
            />
          </PinchZoomPan> */}
        {/* </div> */}
        {carousel === 'images' && (
          <div onClick={onNext} className="arrow arrow-right">
            <FiChevronRight className="arrow-icon" color="#ffffff" />
          </div>
        )}
        {carousel === 'photos' && (
          <div onClick={onNextPhotos} className="arrow arrow-right">
            <FiChevronRight className="arrow-icon" color="#ffffff" />
          </div>
        )}
        {/* <div className="bottom-wrapper">
          <div
            className="zoom-btn"
            onClick={() => {
              if (imgScale > 1.5) return;
              setImgScale(imgScale + 0.3);
            }}
          >
            <p>zoom in</p>
          </div>
          <div
            className="zoom-btn"
            onClick={() => {
              if (imgScale - 0.3 < 0) return;
              setImgScale(imgScale - 0.3);
            }}
          >
            <p>zoom out</p>
          </div>
        </div> */}
      </Modal>
    </div>
  );
};

Carousel.propTypes = {
  carousel: PropTypes.string.isRequired,
  setCarousel: PropTypes.func.isRequired,
};

export default Carousel;
