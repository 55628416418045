import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useDropzone } from 'react-dropzone';
import { IoClose, IoConstructOutline, IoTriangle } from "react-icons/io5";
import { IoIosClose } from 'react-icons/io';
import { Modal } from 'react-bootstrap';
import PrimaryButton from '../../../components/Buttons/PrimaryButton/PrimaryButton';
import Loader from '../../../components/Loader/Loader';
import ImageAdd from './ImageAdd/ImageAdd';
import img1 from '../../../images/damage/1-05.png';
import HostaAILogo from "../../../images/HostaAILogo.svg";
import instruction1 from '../../../images/instructions/Web_instructions-03.png';
import instruction2 from '../../../images/instructions/Web_instructions-01.png';
import { 
  createRoomWithLinkId, 
  getImageInformation,
  addImage,
  processRoom,
} from '../../../store/actions/linkApi';
import fallback from '../../../../src/images/fallimageh.png';
import NextIcon from '../../../assets/icons/nextIcon.png';
import Tip1 from '../../../assets/images/tip1.png';
import Tip2Wrong from '../../../assets/images/tip2Wrong.svg';
import Tip2Right from '../../../assets/images/tip2Right.png';
import Tip3Wrong from '../../../assets/images/tip3Wrong.svg';
import Tip3Right from '../../../assets/images/tip3Right.svg';
import Tip4 from '../../../assets/images/tip4.svg';
import TipCross from '../../../assets/images/tipCross.svg';
import TipTick from '../../../assets/images/tipTick.svg';
import './NewRoom.css';
import NewPortalContext from '../../../new-portal-context/NewPortalContext';

const NewRoomLink = () => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const { linkId } = params;
  const initialData = {
    address: '',
    damageType: '',
  };

  const handleClose = () => {
    alert("closeing")
  };

  const [address, setAddress] = useState('');
  const [showRules, setShowRules] = useState(true);
  const [apt, setApt] = useState();
  const [damageType, setDamageType] = useState('');
  const [error, setError] = useState(initialData);
  const [addressDetails, setAddressDetails] = useState({});
  const [modalShown, setModalShown] = useState(false);
  const [show, setShow] = useState(true);
  const [claimFields, setClaimFields] = useState({});
  const [oneImage, setoneImage] = useState(true);
  const [count, setCount] = useState(0);
  const [instruction, setInstruction] = useState(false);
  const [maxVal] = useState('8');
  const [maxDamageImages] = useState('1');
  const [maxOppositeImages] = useState('4');
  const [maxOppositeImages1] = useState('1');
  const [damageImages, setDamageImages] = useState([]);
  const [oppsiteImages, setOppositeImages] = useState(Array(Number(maxOppositeImages)).fill(null));
  const [oppsiteImages1, setOppositeImages1] = useState([]);
  const [files, setFiles] = useState(Array(2).fill(null));
  const [loading, setLoading] = useState(false);
  const [isRendered, setRendered] = useState(false);
  const [info, setInfo] = useState(false);

  const [claimroomid, setClaimroomid] = useState("");
  const [overlayimagessubbmited, setOverlayimagessubbmited] = useState(false);
  const [imagessubbmited, setImagessubbmited] = useState(false);

  const [oppsiteImagesError, setOppositeImagesError] = useState([]);
  const [oppsiteImagesUploading, setOppositeImagesUploading] = useState(false);
  const [filesError, setFilesError] = useState([]);
  const [filesUploading, setFilesUploading] = useState(false);
  //flag to call createRoomId ONLY when we're gone through new UI and roomTypeChoose
  // const [returnedFromNewUI, updateReturnedFromNewUI] =  useState(false);
  
  const onError = e => {
    // alert('ds')
    e.target.src = fallback;
  };


  //NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE : 
  //NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE NOTE :
  //ssLinkIdFromParameter is declared on /startme page
  //No claim id passed in here, rather LinkId pulled off of parametres
  //const { linkIdFromParameter}  = useContext(NewPortalContext);
  //const { roomType } = useContext(NewPortalContext);
  const roomType = sessionStorage.roomType;
  const currentRoomId = JSON.parse(sessionStorage.getItem("ssRoomId"));
  console.log('CURRENT ROOM ID', currentRoomId);
  const emptyRoomId = currentRoomId === null ? true : false;
 
  useEffect(() => {
    if (emptyRoomId) {
      const getRoomId = async () => {
        const roomIdObj = await createRoomWithLinkId(linkId, { roomType });
        //sessionStorage.ssRoomId = roomIdObj;
        sessionStorage.setItem("ssRoomId", JSON.stringify(roomIdObj));
        setClaimroomid(roomIdObj);
        console.log('ROOMID', roomIdObj);
        console.log('NEWROOMLINK-roomId:', JSON.parse(sessionStorage.getItem("ssRoomId")));
      }
      getRoomId().catch(console.error);
    }
    if (!emptyRoomId) {
      setClaimroomid(currentRoomId);
    }
  }, []);

  // sessionStorage["baz"] =JSON.stringify(object)
  // var object = JSON.parse(sessionStorage.getItem("baz"));

  // useEffect(() => {
  //   console.log('LOADING sessionStorage  roomId Is:', sessionStorage.ssRoomId);
  // }, []);

  useEffect(() => {
    const arrLength = damageImages.length;
    if (arrLength > maxDamageImages) {
      damageImages.splice(1, arrLength - maxDamageImages);
      setError({ ...error, damageImages: 'You can upload only 1 image' });
    } else {
      setError({ ...error, damageImages: '' });
    }
  }, [damageImages, maxDamageImages]);

  useEffect(() => {
    const arrLength = oppsiteImages.length;
    if (arrLength > maxOppositeImages) {
      oppsiteImages.splice(maxOppositeImages, arrLength - maxOppositeImages);
      setError({ ...error, oppsiteImages: 'You can upload upto 2 images' });
    } else {
      setError({ ...error, oppsiteImages: '' });
    }
  }, [oppsiteImages, maxOppositeImages]);

  useEffect(() => {
    const arrLength = oppsiteImages1.length;
    if (arrLength > maxOppositeImages1) {
      oppsiteImages1.splice(maxOppositeImages1, arrLength - maxOppositeImages1);
      setError({ ...error, oppsiteImages1: 'You can upload upto 2 images' });
    } else {
      setError({ ...error, oppsiteImages1: '' });
    }
  }, [oppsiteImages1, maxOppositeImages1]);

  useEffect(() => {
    const arrLength = files.length;
    if (arrLength > maxVal) {
      files.splice(8, arrLength - maxVal);
      setError({ ...error, files: 'You can upload upto 8 images' });
    } else {
      setError({ ...error, files: '' });
    }
  }, [files, maxVal]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxFiles: maxVal,
    onDrop: acceptedFiles => {
      const ids = acceptedFiles.map(() => uuidv4());
      const newFiles = acceptedFiles.map((file, i) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          id: ids[i],
        })
      );

      if (count === 0) {
        setOppositeImages([...oppsiteImages, ...newFiles]);
      } else if (count === 1) setOppositeImages1([...oppsiteImages1, ...newFiles]);
      else if (count === 2) setFiles([...files, ...newFiles]);
      else if (count === 3) setDamageImages([...damageImages, ...newFiles]);
    },
  });

  const handleImgDelete = e => {
    if (count === 0) {
      oppsiteImages.map(() => {
        const filteredFiles = oppsiteImages.filter(item => e !== item.id);
        setOppositeImages(filteredFiles);
        return true;
      });
    } else if (count === 1) {
      oppsiteImages1.map(() => {
        const filteredFiles = oppsiteImages1.filter(item => e !== item.id);
        setOppositeImages1(filteredFiles);
        return true;
      });
    } else if (count === 2) {
      files.map(() => {
        const filteredFiles = files.filter(item => e !== item.id);
        setFiles(filteredFiles);
        return true;
      });
    } else if (count === 3) {
      damageImages.map(() => {
        const filteredFiles = damageImages.filter(item => e !== item.id);
        setDamageImages(filteredFiles);
        return true;
      });
    }
  };

  const makeFormData = (uploadFields, file) => {
    const data = new FormData();
    data.append('key', uploadFields.key);
    data.append('x-amz-algorithm', uploadFields['x-amz-algorithm']);
    data.append('x-amz-credential', uploadFields['x-amz-credential']);
    data.append('x-amz-date', uploadFields['x-amz-date']);
    data.append('x-amz-security-token', uploadFields['x-amz-security-token']);
    data.append('policy', uploadFields.policy);
    data.append('x-amz-signature', uploadFields['x-amz-signature']);
    data.append('x-amz-meta-claimId', uploadFields.claimId);
    data.append('x-amz-meta-roomId', uploadFields.roomId);
    data.append('x-amz-meta-submitter', uploadFields.submitter);
    data.append('x-amz-meta-imageField', uploadFields.imageField);
    if (uploadFields.imageNumber) {
      data.append('x-amz-meta-imageNumber', uploadFields.imageNumber);
    };
    data.append('file', file);
    return data;
  };

  
  

  const handleHide = () => history.goBack();
  const handleHideOneImage = () => setoneImage(false);



//COUNT===0  COUNT===0 COUNT===0COUNT===0 COUNT===0
//COUNT===0  COUNT===0 COUNT===0COUNT===0 COUNT===0
//COUNT===0  COUNT===0 COUNT===0COUNT===0 COUNT===0
//COUNT===0  COUNT===0 COUNT===0COUNT===0 COUNT===0

  // const { linkIdFromParameter}  = useContext(NewPortalContext);
  // const { roomType } = useContext(NewPortalContext);
  const handleRoomId = async () => {
    // updateReturnedFromNewUI(true);
    setoneImage(true);
    setShow(false);
    // const roomId = await createRoomWithLinkId(linkIdFromParameter, { roomType });
    // setClaimroomid(roomId);
    setLoading(false);
  };



//COUNT===1 COUNT===1/COUNT===1COUNT===1COUNT===1COUNT===1
//COUNT===1 COUNT===1/COUNT===1COUNT===1COUNT===1COUNT===1
//COUNT===1 COUNT===1/COUNT===1COUNT===1COUNT===1COUNT===1
//COUNT===1 COUNT===1/COUNT===1COUNT===1COUNT===1COUNT===1
  const handleOverlayFirst = async data => {
    handleAddClaimAndRoomoverviewImages();
  };

// OVERVIEW IMAGES  OVERVIEW IMAGES OVERVIEW IMAGES OVERVIEW IMAGES OVERVIEW IMAGES 
// OVERVIEW IMAGES  OVERVIEW IMAGES OVERVIEW IMAGES OVERVIEW IMAGES OVERVIEW IMAGES 
// OVERVIEW IMAGES  OVERVIEW IMAGES OVERVIEW IMAGES OVERVIEW IMAGES OVERVIEW IMAGES 
// OVERVIEW IMAGES  OVERVIEW IMAGES OVERVIEW IMAGES OVERVIEW IMAGES OVERVIEW IMAGES 
  const handleAddClaimAndRoomoverviewImages = async () => {
    let err = false;
    let oppositeErrors = []
    setOppositeImagesUploading(true);

    for (const file of [...oppsiteImages, ...oppsiteImages1]) {
      const indexOf = [...oppsiteImages, ...oppsiteImages1].indexOf(file);
      const imageNumber = (indexOf + 1).toString();
     //HERE  HERE  HERE  HERE  HERE  HERE  HERE  HERE  HERE  HERE  HERE  HERE 
       //HERE  HERE  HERE  HERE  HERE  HERE  HERE  HERE  HERE  HERE  HERE  HERE 
         //HERE  HERE  HERE  HERE  HERE  HERE  HERE  HERE  HERE  HERE  HERE  HERE 
           //HERE  HERE  HERE  HERE  HERE  HERE  HERE  HERE  HERE  HERE  HERE  HERE 
      const ext = file.name.split('.').pop(); 
      const imgInfo = await getImageInformation(linkId, claimroomid.roomId, ext, 'overviewImages', imageNumber);
      if (imgInfo === undefined) {
        oppositeErrors.push(true);
        err = true;
      } else {
        const data = makeFormData(imgInfo.uploadFields, file);
        oppositeErrors.push(false);
        addImage(imgInfo.uploadLink, data);
      }
    }

    setOppositeImagesError(oppositeErrors);
    if (err !== true) {
      setCount(count + 1);
      setShow(false);
      setOppositeImagesUploading(false);
    } else {
      setOppositeImagesUploading(false);
      alert('Image type is not valid');
    }
  };

  //COUNT===2COUNT===2COUNT===2COUNT===2COUNT===2COUNT===2
  //COUNT===2COUNT===2COUNT===2COUNT===2COUNT===2COUNT===2
  //COUNT===2COUNT===2COUNT===2COUNT===2COUNT===2COUNT===2
  const handleImagesFirst = () => {
    handleAddClaimAndRoomImages();
  };

  const handleAddClaimAndRoomImages = async () => {
    let err = false;
    let filesError = [];
    setFilesUploading(true);
    for (const file of files) {
      if (file !== null) {
        const ext = file.name.split('.').pop();
        const imgInfo = await getImageInformation(linkId, claimroomid.roomId, ext, 'images');
        if (imgInfo === undefined) {
          filesError.push(true)
          err = true;   
        } else {
          const data = makeFormData(imgInfo.uploadFields, file);
          filesError.push(false);
          await addImage(imgInfo.uploadLink, data);
        }
      }
    }

    setFilesError(filesError);
    if (err !== true) {
      await processRoom(linkId, claimroomid.roomId, { roomType });
      setFilesUploading(false);
      history.push({
        pathname: `/app/roomaddedlinkpopup/${linkId}`,
        state: { newLink: true, roomType: roomType },
      });
    } else {
      setFilesUploading(false);
      alert('Image type is not valid');
    }
  };


  //COUNT===3 COUNT===3COUNT===3COUNT===3COUNT===3COUNT===3
  //COUNT===3 COUNT===3COUNT===3COUNT===3COUNT===3COUNT===3
  //COUNT===3 COUNT===3COUNT===3COUNT===3COUNT===3COUNT===3
  //COUNT===3 COUNT===3COUNT===3COUNT===3COUNT===3COUNT===3
    const handleFormSubmit = () => {
      setLoading(true);
      handleAddClaimAndRoom();
      setLoading(false);
    };

    const handleAddClaimAndRoom = async () => {
      let err = false;
      setLoading(true);
      // const roomId = await dispatch(createRoom(linkId, { roomType }));
      await Promise.all(
        damageImages.map(async file => {
          const ext = file.name.split('.').pop();
          const imgInfo = await getImageInformation(linkId, claimroomid, ext, 'damageImages');
          if (imgInfo === undefined) {
            setLoading(false);
            err = true;
            return;
          } else {
            const data = makeFormData(imgInfo.uploadFields, file);
            return await addImage(imgInfo.uploadLink, data);
          }
        })
      );
      await Promise.all(
       
        [...oppsiteImages, ...oppsiteImages1].map(async file => {
          const indexOf = [...oppsiteImages, ...oppsiteImages1].indexOf(file);
          const imageNumber = (indexOf + 1).toString();
          const ext = file.name.split('.').pop();
          const imgInfo = await getImageInformation(linkId, claimroomid, ext,'overviewImages', imageNumber);
          if (imgInfo === undefined) {
            setLoading(false);
            err = true;
            return;
          } else {
            const data = makeFormData(imgInfo.uploadFields, file);
            return await addImage(imgInfo.uploadLink, data);
          }
        })
      );
      await Promise.all(
        files.map(async file => {
          if (file !== null) {
            const ext = file.name.split('.').pop();
            const imgInfo = await getImageInformation(linkId, claimroomid, ext, 'images');
            if (imgInfo === undefined) {
              setLoading(false);
              err = true;
              return;
            } else {
              const data = makeFormData(imgInfo.uploadFields, file);
              return await addImage(imgInfo.uploadLink, data);
            }
          }
        })
      );
      if (err !== true) {
        setLoading(false);
        history.push({
          pathname: `/app/roomaddedlinkpopup/${linkId}`,
          state: { newLink: true, roomType: roomType },
        });
      } else {
        alert('Image type is not valid');
      }
    };
    
  //method to handle the case of the <back button renderPopup modal count===0  going back 
  //to the screen previous to it in the old flow, instead going back to  previous  view
  //in the new flow
  const returnToTips = () => {
    history.push(`/selectRoomType`);
  }
  const renderPopup = () => {
    if (count === 0) {
      console.log('COUNT === 0');
      return (
        <div >
          {/* <div className="next-position">
            <a
              role="none"
              className="back-link-oneImage"
              onClick={() => {
                returnToTips();
              }}
            >
              {'<'} Back
            </a>
          </div> */}
          <div className=" uploadImageTitle">
            Please photograph each wall of the room, moving in a clockwise direction
          </div>
          <div className=" uploadImageSubTitle">
            <p>
              Stand as far back from the wall as you can to make sure the ceiling, floor, and both
              corners are in each picture. Use the wide angle camera feature if you can.
            </p>
          </div>
          <div className="oneImage-bg ImageUploadBg">
            {instruction == true ? (
              <div>
                <p className="instruction-p">
                  {' '}
                  Use Landscape Mode If Possible. If The Room Is Too Narrow, Use Portrait Mode.{' '}
                </p>{' '}
                <div className="two-img">
                  <img src={instruction1} className="instruction-img1" />
                  <img src={instruction2} className="instruction-img2" />
                </div>{' '}
              </div>
            ) : 
              null}
            <div className="imageUploadDiv">
              {oppsiteImages?.map((currImage, index) => (
                <ImageAdd
                  key={index}
                  index={index}
                  currImg={currImage}
                  images={oppsiteImages}
                  setImages={setOppositeImages}
                  isWall={true}
                />
              ))}
            </div>
          </div>
          <span>&nbsp;</span>
          <div className='dotsDiv'>
            <p className='activeDot'></p>
            <p className='dot'></p>
            <p className='dot'></p>
            <p className='dot'></p>
          </div>
          <div className='uploadImageSubTitle' >
            <p>Got Q's?&nbsp;</p> 
            <p className='p-cursor underline-text' style={{color:"#6a9eea"}} onClick={()=>{setCount(4)}}>See our tips here</p>
          </div>
          <div className='centerDiv'>
            <button
              title="Next"
              className="imageUploadButton"
              onClick={()=> {
                handleRoomId();
                if (oppsiteImages?.includes(null)) {
                  setInfo(true)
                }
                else {
                  let valid = true;
                  oppsiteImages.map(cur => {
                    const parts = cur.name.split('.').pop();
                    if (parts === 'jpeg' || parts === 'jpg'  || parts === 'png'|| parts === 'heic') {
                    valid = true;
                    } else {valid = false}
                  });
                  if (valid === false) {
                    alert('image type is not supported. We support JPEG, PNG and HEIC');
                  } else {
                    setCount(count + 1);
                    setShow(false);
                  }
                }
              }}
            >
              <p className="next-Txt">Next</p>
            </button>
            {info ?
              <>
                <IoTriangle className='tostArrow1' />
                <div className='tostMessageDiv1'>
                  <IoClose style={{ float: "right", marginLeft: 5 }} onClick={() => setInfo(false)} />
                  <p>Please submit 4 photos to continue</p>
                </div>
              </>
              : null
            }
          </div>
        </div>
      );
    }
    if (count === 1) {
      console.log('COUNT ===1');
      return (
         <div>
          {/* <div className="next-position">
            <a role="none" className="back-link-oneImage" onClick={() => setCount(count - 1)}>
              {'<'} Back
            </a>
          </div> */}
          <div className=" uploadImageTitle">
            How does it look?
          </div>
          <div className=" uploadImageSubTitle">
            <p>Please confirm each photo contains a full wall</p>
          </div>
          <div className="oneImage-bg ImageUploadBg">
           
            <div className="imageShowDiv">
              {oppsiteImages?.map((currImage, index) => (
                <ImageAdd
                  key={index}
                  index={index}
                  currImg={currImage}
                  images={oppsiteImages}
                  setImages={setOppositeImages}
                  isWall={true}
                  isImagesError={oppsiteImagesError[index]}
                  isUploadingImages={oppsiteImagesUploading}
                />
              ))}
            </div>
          </div>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <div className='dotsDiv'>
            <p className='dot'></p>
            <p className='activeDot'></p>
            <p className='dot'></p>
            <p className='dot'></p>
          </div>
          <div className='centerDiv'>
            <button
              title="Looks good!"
              className="imageUploadButton"
              disabled={oppsiteImages.length < 4}
              onClick={()=>{
                let valid = true;
                oppsiteImages.map(cur => {
                  const parts = cur.name.split('.').pop();
                  if (parts === 'jpeg' || parts === 'jpg'  || parts === 'png'|| parts === 'heic') {
                    valid = true;
                  } else { valid = false}
                });
                if (valid === false) {
                  alert(' image type is not supported. We support JPEG, PNG and HEIC');
                } else {
                  if (overlayimagessubbmited == false) {
                    handleOverlayFirst();
                  }
                }
              }}
            >
              <p className="next-Txt">Looks good!</p>
            </button>
          </div>
          <div className='centerDiv'>
            <button
              className="imageUploadSecondaryButton"
            >
              <p className="next-Txt" onClick={() => setCount(count - 1)}>Back</p>
            </button>
          </div>
        </div>
      );
    }
    if (count === 2) {
      console.log('COUNT ===2');
      return (
        <div>
          <div className="uploadImageTitle">
             Time to fill in the rest of the room!
          </div>
          <div className=" uploadImageSubTitle">
            <p>
              Try and capture any areas of the room that were not visible in the pictures you took
              earlier
            </p>
          </div>
          <div className="oneImage-bg ImageUploadBg">
            <div className="imageUploadDiv">
              {files?.map((currFile, index) => (
                <ImageAdd
                  key={index}
                  index={index}
                  currImg={currFile}
                  images={files}
                  setImages={setFiles}
                  isTrash={true}
                  isImagesError={filesError[index]}
                  isUploadingImages={filesUploading}
                />
              ))}
            </div>
          </div>
          <span>&nbsp;</span>
          <p className="CenterText">If a wall is too long, take two photos that overlap</p>
          <span>&nbsp;</span>
          <div className="uploadImageTitle">
            Pictures you took earlier
          </div>
          <div className="imageUploadDiv">
              {oppsiteImages?.map((currFile, index) => (
                <div style={{display:"flex",flexDirection:"column"}}>
                  <img
                className='uploadedimg uploadedimage'
                  src={currFile?.preview}
                />
                <p className='CenterText'>Wall&nbsp;{index + 1}</p>
                </div>
              ))}
            </div>
          <span>&nbsp;</span>
          <div className='dotsDiv'>
            <p className='dot'></p>
            <p className='dot'></p>
            <p className='activeDot'></p>
            <p className='dot'></p>
          </div>
          <div className='centerDiv'>
            <button
              title={files?.some(el => el !== null) === true ? 'Submit Room' : 'Skip this step'}
              className="imageUploadButton"
              onClick={ async ()=>{
                let valid = true;
                files?.map(cur => {
                  if (cur !== null) {
                    const parts = cur.name.split('.').pop();
                    if (parts === 'jpeg' || 'jpg'  || 'png'|| 'heic') {
                      valid = true;
                    } else {valid = false};
                  }
                });
                if (valid === false) {
                  alert(' image type is not supported. We support JPEG, PNG and HEIC');
                } else {
                  if (imagessubbmited == false) {
                    handleImagesFirst();
                  }
                  setShow(false);
                }
              }}
            >
              <p className="next-Txt">{files?.some(el => el !== null) === true ? 'Submit Room' : 'Skip this step'}</p>
            </button>
          </div>
        </div>
      );
    }
    if (count === 3) {
      console.log('COUNT ===3');
      return (
        <div>
          <div>
            <p className="title-one-img">
              Last but not least, capture a{' '}
              <span className="bold-text">close-up of the damage -</span> include{' '}
              <span className="bold-text">floor & ceiling</span> in the shot
            </p>
          </div>
          <div className="oneImage-bg ImageUploadBg">
            <img className="f434" src={img1} />
            <div className="imgList">
              {damageImages.map(file => (
                <div key={file.id}>
                  <div className="img" key={file.name}>
                    <IoIosClose
                      size={50}
                      color="#red"
                      className="closeImgIcon"
                      onClick={() => handleImgDelete(file.id)}
                    />
                    <img
                      src={file.preview}
                      className="uploadedimg"
                      id={file.id}
                      alt="upload"
                      onError={e => onError(e)}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />

              <div>
                {damageImages.length < 1 ? (
                  <div>
                    <PrimaryButton title="UPLOAD IMAGE" className="btn-upload-img" />
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
          <div className="next-position">
            <a role="none" className="back-link-oneImage" onClick={() => setCount(count - 1)}>
              Back
            </a>
            <button
              className="next-twoimg new-btn-next"
              disabled={oppsiteImages.length < 1}
              onClick={async () => {
                let valid = true;
                let type = '';
                oppsiteImages.map(cur => {
                  const parts = cur.name.split('.').pop();
                  if (parts === 'jpeg' || parts === 'jpg'  || parts === 'png'|| parts === 'heic') {
                  valid = true;
                  } else {valid = false}
                });
                if (valid === false) {
                  alert(type + ' image type is not supported. We support JPEG, PNG and HEIC');
                } else {
                  handleFormSubmit();
                  setShow(false);
                }
              }}
            >
              <img className={`next-icon`} src={NextIcon} />
              <p className="next-Txt">Finish</p>
            </button>
          </div>
        </div>
      );
    }
    if (count === 4) {
      return (
        <div >
          <div className="closeImage-top" role="none" onClick={() => setCount(0)}>
            <IoIosClose size={30} color="#316FD0" />
          </div>
          {/* <div className="next-position">
            <a
              role="none"
              className="back-link-oneImage"
              onClick={() => {
                setCount(0)
              }}
            >
              {'<'} Back
            </a>
          </div> */}

          <div className=" uploadImageTitle uploadImageTitleTip">
            Tip 1
          </div>
          <div className=" uploadImageSubTitle">
            <p>
            Pick one wall to start with. Stand back to get a photo of each wall and the edges
            </p>
          </div>
          <div className="oneImage-bg ImageUploadBg">
              <div>
                <div className="two-img">
                  <img src={Tip1} className="tip-image1" />
                </div>{' '}
              </div>
          </div>

          <div className=" uploadImageTitle uploadImageTitleTip">
            Tip 2
          </div>
          <div className=" uploadImageSubTitle">
            <p>
            Make sure the ceiling, the floor, and all four corners are in the photo
            </p>
          </div>
          <div className="oneImage-bg ImageUploadBgTip">
              <div>
                <div className="two-tip-img">
                  <div className='tipdivwithsign'>
                    <img src={Tip2Wrong} className="tip-image2" />
                    <img src={TipCross} className="tipStatus-image" />
                  </div>
                  <div className='tipdivwithsign'>
                    <img src={Tip2Right} className="tip-image2" />
                    <img src={TipTick} className="tipStatus-image" />
                  </div>
                </div>{' '}
              </div>
          </div>

          <div className=" uploadImageTitle uploadImageTitleTip">
            Tip 3
          </div>
          <div className=" uploadImageSubTitle">
            <p>
            Hold the camera straight on and avoid blurry shots
            </p>
          </div>
          <div className="oneImage-bg ImageUploadBgTip">
              <div>
                <div className="two-tip-img">
                  <div className='tipdivwithsign'>
                    <img src={Tip3Wrong} className="tip-image2" />
                    <img src={TipCross} className="tipStatus-image" />
                  </div>
                  <div className='tipdivwithsign'>
                    <img src={Tip3Right} className="tip-image2" />
                    <img src={TipTick} className="tipStatus-image" />
                  </div>
                </div>{' '}
              </div>
          </div>

          <div className=" uploadImageTitle uploadImageTitleTip">
            Tip 4
          </div>
          <div className=" uploadImageSubTitle">
            <p>
            <b></b> Capture walls in a clockwise direction, moving from left to right
            </p>
          </div>
          <div className="oneImage-bg ImageUploadBgTip">
              <div>
                <div className="two-img">
                  <img src={Tip4} className="tip-image1" />
                </div>{' '}
              </div>
          </div>

          <div className=" uploadImageTitle uploadImageTitleTip">
            Tip 5
          </div>
          <div className=" uploadImageSubTitle">
            <p>
            If you have an open concept kitchen/dining room, you can picture imaginary walls separating the kitchen and dining room space.
            <br/>
            <br/>
Then, take pictures of the space as if it were two rooms
            </p>
          </div>

          <div className='centerDiv'>
            <button
              title="Next"
              className="imageUploadButton"
              // disabled={oppsiteImages?.includes(null)}
              onClick={async () => {
                setCount(0);
              }}
            >
              <p className="next-Txt">I'm Ready to Start!</p>
            </button>
          </div>
        </div>
      );
    }
    return <div />;
  };

  return (
    <div className="roomf">
      <Loader loading={loading} absolute overlay />
      <Modal show={modalShown} centered animation size="md" className="shadow-lg">
        <Modal.Body className="text-center p-5">
          <h3 className="m-0">Property Added Successfully</h3>
        </Modal.Body>
      </Modal>
      <Modal
        backdrop="static"
        show={oneImage}
        onHide={handleHideOneImage}
        centered
        animation
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="shadow-lg roomdd fullModelCss"
      >
        <Modal.Body>
          {count <= 4 ?
            <div>
              <div className='HostaAILogoDiv'>
                <img src={HostaAILogo} />
              </div>
              {renderPopup()}
            </div>

            : handleClose()}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NewRoomLink;
