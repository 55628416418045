import React from 'react';
import { ThemeProvider } from "styled-components";

const theme = {
  color: {
  },
  fontsize: {
    largeHeadline: "2rem",
    smallHeadline: "1.5rem",
    secondaryHeadline: "1rem",
  },
  fontweight: {
    largeHeadline: "900",
    smallHeadline: "900",
    secondaryHeadline: "400",
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
