import { globalAPICallback } from './UserPool';
import axios from '../axios';

// eslint-disable-next-line import/prefer-default-export
export const getCredits = () => async dispatch =>
  globalAPICallback(async () => {
    const response = await axios({
      method: 'GET',
      url: `/organization/credits`,
    });

    dispatch({
      type: 'GET_CREDITS',
      payload: response.data,
    });
    return response.data;
  });

export const setCredits = val => async (dispatch, getState) => {
  const { credits } = getState().credit;
  if (val === 'add' && credits <= 0) return;
  dispatch({
    type: 'SET_CREDITS',
    payload: val === 'add' ? credits - 1 : credits + 1,
  });
};

export const setCreditsBackend = (claimId, roomId) => async dispatch =>
  globalAPICallback(async () => {
    try {
      const result = await axios({
        method: 'POST',
        url: `/claim/${claimId}/room/${roomId}/submit`,
      });
      dispatch(setCredits('add'));
    } catch (e) {
      console.log(e);
    }
    return true;
  });
