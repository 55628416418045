import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { IoIosClose, IoIosArrowBack } from 'react-icons/io';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { createClaim } from '../../../../../store/actions/allClaim';
import {
  addImage,
  createRoom,
  getAllRoom,
  getImageInformation,
} from '../../../../../store/actions/allRoom';
import { setCreditsBackend } from '../../../../../store/actions/credits';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton/PrimaryButton';
import { isObjEmpty } from '../../../../../utils/utils';
import Loader from '../../../../../components/Loader/Loader';
import img1 from '../../../../../images/damage/1-05.png';
import img2 from '../../../../../images/damage/1-06.png';
import img2a from '../../../../../images/damage/1-06a.png';
import img3 from '../../../../../images/damage/1.png';
import img4 from '../../../../../images/damage/2.png';
import img5 from '../../../../../images/damage/3.png';
import instruction1 from '../../../../../images/instructions/Web_instructions-03.png';
import instruction2 from '../../../../../images/instructions/Web_instructions-01.png';
import fallback from '../../../../../../src/images/fallimageh.png';
import NextIcon from '../../../../../assets/icons/nextIcon.png';

import iimg1 from '../../../../../images/damage/iimg1.png';
import iimg2 from '../../../../../images/damage/iimg2.png';
import iimg3 from '../../../../../images/damage/animation-20.png';
import iimg4 from '../../../../../images/damage/1-and-2-capture.gif';
import iimg5 from '../../../../../images/damage/CornerShots.gif';
import { IoClose, IoTriangle } from "react-icons/io5";

import ImageAdd from '../../../../../pages/app/NewRoom/ImageAdd/ImageAdd';

import HostaAILogo from "../../../../../images/HostaAILogo.svg";
import "../../../../../pages/app/NewRoom/NewRoom.css";

import Tip1 from '../../../../../assets/images/tip1.png';
import Tip2Wrong from '../../../../../assets/images/tip2Wrong.svg';
import Tip2Right from '../../../../../assets/images/tip2Right.png';
import Tip3Wrong from '../../../../../assets/images/tip3Wrong.svg';
import Tip3Right from '../../../../../assets/images/tip3Right.svg';
import Tip4 from '../../../../../assets/images/tip4.svg';
import TipCross from '../../../../../assets/images/tipCross.svg';
import TipTick from '../../../../../assets/images/tipTick.svg';
import UserType from '../../../RoomInterface/Layouts/UserType/UserType';

const AddressForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const initialData = {
    addressLine1: '',
    city: '',
    cityCode: '',
    zip: '',
    customerID: "",
  };
  const credits = useSelector(state => state.credit.credits);
  const [showRules, setShowRules] = useState(true);
  const [damageType, setDamageType] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [error, setError] = useState(initialData);
  const [modalShown, setModalShown] = useState(false);
  const [show, setShow] = useState(false);
  const [claimFields, setClaimFields] = useState({});
  const [roomType, setroomType] = useState('');
  const [roomName, setRoomName] = useState('');
  const [oneImage, setoneImage] = useState(false);
  const [count, setCount] = useState(0);
  const [instruction, setInstruction] = useState(false);
  const [maxVal] = useState('8');
  const [maxDamageImages] = useState('1');
  const [maxOppositeImages] = useState('4');
  const [maxOppositeImages1] = useState('1');
  const [damageImages, setDamageImages] = useState([]);
  // const [oppsiteImages, setOppositeImages] = useState([]);
  const [oppsiteImages, setOppositeImages] = useState(Array(Number(maxOppositeImages)).fill(null));
  const [oppsiteImages1, setOppositeImages1] = useState([]);
  const [files, setFiles] = useState(Array(2).fill(null));
  const [loading, setLoading] = useState(false);
  const [customerID, setCustomerID] = useState("");

  const [info, setInfo] = useState(false);
  const [claimtempid, setClaimtempid] = useState("");
  const [claimroomid, setClaimroomid] = useState("");
  const [overlayimagessubbmited, setOverlayimagessubbmited] = useState(false);
  const [imagessubbmited, setImagessubbmited] = useState(false);

  const [oppsiteImagesError, setOppositeImagesError] = useState([]);
  const [oppsiteImagesUploading, setOppositeImagesUploading] = useState(false);
  const [filesError, setFilesError] = useState([]);
  const [filesUploading, setFilesUploading] = useState(false);
  const [open, setOpen] = useState(null);

  const onError = e => {
    // alert('ds')
    e.target.src = fallback;
  };

  const usaStates = [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
  ];

  useEffect(() => {
    // damageImages.forEach(file => URL.revokeObjectURL(file.preview));
    if (damageImages.length > maxDamageImages) {
      //setError(true);
    }
    const arrLength = damageImages.length;
    if (arrLength > maxDamageImages) {
      damageImages.splice(1, arrLength - maxDamageImages);
      setError({ ...error, damageImages: 'You can upload only 1 image' });
    } else {
      setError({ ...error, damageImages: '' });
    }
  }, [damageImages, maxDamageImages]);

  useEffect(() => {
    // oppsiteImages.forEach(file => URL.revokeObjectURL(file.preview));
    if (oppsiteImages.length > maxOppositeImages) {
      //setError(true);
    }
    const arrLength = oppsiteImages.length;
    if (arrLength > maxOppositeImages) {
      oppsiteImages.splice(maxOppositeImages, arrLength - maxOppositeImages);
      setError({ ...error, oppsiteImages: 'You can upload upto 2 images' });
    } else {
      setError({ ...error, oppsiteImages: '' });
    }
  }, [oppsiteImages, maxOppositeImages]);

  useEffect(() => {
    // oppsiteImages.forEach(file => URL.revokeObjectURL(file.preview));
    if (oppsiteImages1.length > maxOppositeImages1) {
      //setError(true);
    }
    const arrLength = oppsiteImages1.length;
    if (arrLength > maxOppositeImages1) {
      oppsiteImages1.splice(maxOppositeImages1, arrLength - maxOppositeImages1);
      setError({ ...error, oppsiteImages1: 'You can upload upto 2 images' });
    } else {
      setError({ ...error, oppsiteImages1: '' });
    }
  }, [oppsiteImages1, maxOppositeImages1]);

  useEffect(() => {
    // files.forEach(file => URL.revokeObjectURL(file.preview));
    if (files.length > maxVal) {
      //setError(true);
    }
    const arrLength = files.length;
    if (arrLength > maxVal) {
      files.splice(8, arrLength - maxVal);
      setError({ ...error, files: 'You can upload upto 8 images' });
    } else {
      setError({ ...error, files: '' });
    }
  }, [files, maxVal]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxFiles: maxVal,
    onDrop: acceptedFiles => {
      const ids = acceptedFiles.map(() => uuidv4());
      const newFiles = acceptedFiles.map((file, i) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          id: ids[i],
        })
      );

      if (count === 0) setOppositeImages([...oppsiteImages, ...newFiles]);
      else if (count === 1) setOppositeImages1([...oppsiteImages1, ...newFiles]);
      else if (count === 2) setFiles([...files, ...newFiles]);
      else if (count === 3) setDamageImages([...damageImages, ...newFiles]);
    },
  });

  const handleRoomId = async data => {
    // setLoading(true);

    setoneImage(true);
    setShow(false);
    if (claimroomid == "") {
      const roomId = await dispatch(createRoom(claimtempid, { roomName, roomType }));
      setClaimroomid(roomId);

    }
    setLoading(false);


    // handleAddClaimAndRoom();
  };
  const handleOverlayFirst = async data => {
    // setLoading(true);
    handleAddClaimAndRoomoverviewImages();
  };
  const handleImagesFirst = async data => {
    // setLoading(true);
    handleAddClaimAndRoomImages();
  };

  const handleAddClaimAndRoomoverviewImages = async () => {
    let err = false;
    let oppositeErrors = []
    // setLoading(true);
    setOppositeImagesUploading(true);

    for (const file of [...oppsiteImages, ...oppsiteImages1]) {
      const ext = file?.type?.split('/')[1] || file?.type;
      const imgInfo = await dispatch(getImageInformation(claimtempid, claimroomid, ext, 'overviewImages'));
      if (imgInfo === undefined) {
        // setLoading(false);
        err = true;
        oppositeErrors.push(true);
      } else {
        const data = makeFormData(imgInfo.uploadFields, file);
        oppositeErrors.push(false);
        console.log('data', [...data]);
        await dispatch(addImage(imgInfo.uploadLink, data));
      }
    }

    await setOppositeImagesError(oppositeErrors);
    if (err !== true) {
      setCount(count + 1);
      setShow(false);
      setOppositeImagesUploading(false);
    } else {
      setOppositeImagesUploading(false);
      alert('Image type is not valid');
    }
  };
  const handleAddClaimAndRoomImages = async () => {
    let err = false;
    let filesError = [];
    setFilesUploading(true);
    // setLoading(true);

    for (const file of files) {
      if (file !== null) {

        const ext = file?.type?.split('/')[1] || file?.type;
        const imgInfo = await dispatch(getImageInformation(claimtempid, claimroomid, ext, 'images'));
        if (imgInfo === undefined) {
          // setLoading(false);
          filesError.push(true)
          err = true;
        } else {
          const data = makeFormData(imgInfo.uploadFields, file);
          filesError.push(false)
          console.log('data', [...data]);
          await dispatch(addImage(imgInfo.uploadLink, data));
        }
      }
    }

    await setFilesError(filesError)
    if (err !== true) {
      setLoading(false);
      //this need to call at last submit
      await dispatch(setCreditsBackend(claimtempid, claimroomid));
      await dispatch(getAllRoom(claimtempid, { start: 0, end: 9 }));
      setFilesUploading(false);
      history.push(`/app/roomaddedpopup/${claimtempid}`);
    } else {
      setFilesUploading(false);
      alert('Image type is not valid');
    }
  };
  const handleImgDelete = e => {
    if (count === 0) {
      oppsiteImages.map(() => {
        const filteredFiles = oppsiteImages.filter(item => e !== item.id);
        setOppositeImages(filteredFiles);
        return true;
      });
    } else if (count === 1) {
      oppsiteImages1.map(() => {
        const filteredFiles = oppsiteImages1.filter(item => e !== item.id);
        setOppositeImages1(filteredFiles);
        return true;
      });
    } else if (count === 2) {
      files.map(() => {
        const filteredFiles = files.filter(item => e !== item.id);
        setFiles(filteredFiles);
        return true;
      });
    } else if (count === 3) {
      damageImages.map(() => {
        const filteredFiles = damageImages.filter(item => e !== item.id);
        setDamageImages(filteredFiles);
        return true;
      });
    }
  };

  const handleHide = () => {
    setShow(false);
    setoneImage(false);
    setShowRules(true);
    setCount(0);
  };
  const handleHideOneImage = () => setoneImage(false);

  let reqImg = false;

  const handleClose = () => {
    history.goBack();
  };

  const handleGoBack = () => {
    const alert = window.confirm('Do you want to go back?');
    if (alert) history.goBack();
  };

  const handleImageRequest = () => {
    reqImg = true;
    handleChecks();
  };
  const renderPopup = () => {
    if (count === 0) {
      return (
        <div>
          <div className="closeImage-top" role="none" onClick={() => handleHide()}>
            <IoIosClose size={30} color="#316FD0" />
          </div>
          <div className="next-position">
            <a
              role="none"
              className="back-link-oneImage"
              onClick={() => {
                setShow(true);
                setoneImage(false);
              }}
            >
              {'<'} Back
            </a>
          </div>
          {/* <div className="title-one-img">{roomType}</div> */}
          <div className="uploadImageTitle">
            Please photograph each wall of the room, moving in a clockwise direction
          </div>
          <div className=" uploadImageSubTitle">
            <p>
              Stand as far back from the wall as you can to make sure the ceiling, floor, and both
              corners are in each picture. Use the wide angle camera feature if you can.
            </p>
          </div>

          <div className="oneImage-bg ImageUploadBg">
            {instruction == true ? (
              <div>
                <p className="instruction-p">
                  {' '}
                  Use Landscape Mode If Possible. If The Room Is Too Narrow, Use Portrait Mode.{' '}
                </p>{' '}
                <div className="two-img">
                  <img src={instruction1} className="instruction-img1" />
                  <img src={instruction2} className="instruction-img2" />
                </div>{' '}
              </div>
            ) :
              null}
            <div className="imageUploadDiv">
              {oppsiteImages?.map((currImage, index) => (
                <ImageAdd
                  key={index}
                  index={index}
                  currImg={currImage}
                  images={oppsiteImages}
                  setImages={setOppositeImages}
                  isWall={true}
                />
              ))}
            </div>
          </div>
          <span>&nbsp;</span>
          <div className='dotsDiv'>
            <p className='activeDot'></p>
            <p className='dot'></p>
            <p className='dot'></p>
            <p className='dot'></p>
          </div>
          <div className='uploadImageSubTitle' >
            <p>Got Q's?&nbsp;</p>
            <p className='p-cursor underline-text' style={{ color: "#6a9eea" }} onClick={() => { setCount(4) }}>See our tips here</p>
          </div>
          <div className='centerDiv'>
            <button
              title="Next"
              className="imageUploadButton"
              // disabled={oppsiteImages?.includes(null)}
              onClick={async () => {
                if (oppsiteImages?.includes(null)) {
                  setInfo(true)
                }
                else {
                  let valid = true;
                  let type = '';
                  await oppsiteImages.map(cur => {
                    var parts = cur.path.split('.');
                    type = parts[parts.length - 1];
                    if (type === 'webp' || type === 'gif' || type === 'tiff') {
                      valid = false;
                    }
                  });
                  if (valid === false) {
                    alert(type + ' image type is not supported. We support JPEG, PNG and HEIC');
                  } else {
                    setCount(count + 1);
                    setShow(false);
                  }
                }
              }}
            >
              <p className="next-Txt">Next</p>
            </button>
            {info ?
              <>
                <IoTriangle className='tostArrow1' />
                <div className='tostMessageDiv1'>
                  <IoClose style={{ float: "right", marginLeft: 5 }} onClick={() => setInfo(false)} />
                  <p>Please submit 4 photos to continue</p>
                </div>
              </>
              : null
            }
          </div>
        </div>
      );
    }
    if (count === 1) {
      return (

        <div>
          <div className="closeImage-top" role="none" onClick={() => handleHide()}>
            <IoIosClose size={30} color="#316FD0" />
          </div>
          <div className="next-position">
            <a role="none" className="back-link-oneImage" onClick={() => setCount(count - 1)}>
              {'<'} Back
            </a>
          </div>
          <div className=" uploadImageTitle">
            How does it look?
          </div>
          <div className=" uploadImageSubTitle">
            <p>Please confirm each photo contains a full wall</p>
          </div>
          <div className="oneImage-bg ImageUploadBg">
            <div className="imageShowDiv">
              {oppsiteImages?.map((currImage, index) => (
                <ImageAdd
                  key={index}
                  index={index}
                  currImg={currImage}
                  images={oppsiteImages}
                  setImages={setOppositeImages}
                  isWall={true}
                  isImagesError={oppsiteImagesError[index]}
                  isUploadingImages={oppsiteImagesUploading}
                />
              ))}
            </div>
          </div>
          <span>&nbsp;</span>
          {/* <p className="CenterText">Got Q’s? See our tips <u>here</u></p> */}
          <span>&nbsp;</span>
          <div className='dotsDiv'>
            <p className='dot'></p>
            <p className='activeDot'></p>
            <p className='dot'></p>
            <p className='dot'></p>
          </div>
          <div className='centerDiv'>
            <button
              title="Looks good!"
              className="imageUploadButton"
              disabled={oppsiteImages.length < 4}
              onClick={async () => {
                let valid = true;
                let type = '';
                await oppsiteImages.map(cur => {
                  var parts = cur.path.split('.');
                  type = parts[parts.length - 1];
                  if (type === 'webp' || type === 'gif' || type === 'tiff') {
                    valid = false;
                  }
                });
                if (valid === false) {
                  alert(type + ' image type is not supported. We support JPEG, PNG and HEIC');
                } else {
                  if (overlayimagessubbmited == false) {
                    handleOverlayFirst();
                  }
                }
              }}
            >
              <p className="next-Txt">Looks good!</p>
            </button>
          </div>
          <div className='centerDiv'>
            <button
              className="imageUploadSecondaryButton"
            >
              <p className="next-Txt" onClick={() => setCount(count - 1)}>Back</p>
            </button>
          </div>
        </div>
      );
    }
    if (count === 2) {
      return (

        <div>
          <div className="closeImage-top" role="none" onClick={() => handleHide()}>
            <IoIosClose size={30} color="#316FD0" />
          </div>
          <div className="next-position">
            <a role="none" className="back-link-oneImage" onClick={() => setCount(count - 1)}>
              {'<'} Back
            </a>
          </div>
          <div className=" uploadImageTitle">
            Time to fill in the rest of the room!

          </div>
          <div className=" uploadImageSubTitle">
            <p>
              Try and capture any areas of the room that were not visible in the pictures you took
              earlier
            </p>
          </div>

          <div className="oneImage-bg ImageUploadBg">

            <div className="imageUploadDiv">
              {files?.map((currFile, index) => (
                <ImageAdd
                  key={index}
                  index={index}
                  currImg={currFile}
                  images={files}
                  setImages={setFiles}
                  isTrash={true}
                  isImagesError={filesError[index]}
                  isUploadingImages={filesUploading}
                />
              ))}
            </div>
          </div>
          <span>&nbsp;</span>
          <p className="CenterText">If a wall is too long, take two photos that overlap</p>
          <span>&nbsp;</span>
          <div className="uploadImageTitle">
            Pictures you took earlier
          </div>
          <div className="imageUploadDiv">
            {oppsiteImages?.map((currFile, index) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <img
                  className='uploadedimg uploadedimage'
                  src={currFile?.preview}
                />
                <p className='CenterText'>Wall&nbsp;{index + 1}</p>
              </div>
            ))}
          </div>
          <span>&nbsp;</span>
          <div className='dotsDiv'>
            <p className='dot'></p>
            <p className='dot'></p>
            <p className='activeDot'></p>
            <p className='dot'></p>
          </div>
          <div className='centerDiv'>
            <button
              title={files?.some(el => el !== null) === true ? 'Submit Room' : 'Skip this step'}
              className="imageUploadButton"
              // disabled={files.length < 2}
              onClick={async () => {
                let valid = true;
                let type = '';
                await files?.map(cur => {
                  if (cur !== null) {
                    var parts = cur?.path?.split('.');
                    type = parts[parts?.length - 1];
                    if (type === 'webp' || type === 'gif' || type === 'tiff') {
                      valid = false;
                    }
                  }
                });
                if (valid === false) {
                  alert(type + ' image type is not supported. We support JPEG, PNG and HEIC');
                } else {
                  if (imagessubbmited == false) {
                    handleImagesFirst();
                    // setImagessubbmited(true);
                  }
                  setShow(false);
                }
              }}
            >
              <p className="next-Txt">{files?.some(el => el !== null) === true ? 'Submit Room' : 'Skip this step'}</p>
            </button>
          </div>
        </div>
      );
    }
    if (count === 3) {
      return (
        <div>
          <div className="closeImage-top" role="none" onClick={() => handleHide()}>
            <IoIosClose size={30} color="#316FD0" />
          </div>
          <div>
            <p className="title-one-img">
              Last but not least, capture a{' '}
              <span className="bold-text">close-up of the damage -</span> include{' '}
              <span className="bold-text">floor & ceiling</span> in the shot
            </p>
          </div>
          {/* <div className="p-text">{roomType}</div> */}
          <div className="oneImage-bg ImageUploadBg">
            {/* <img className="oneImage" /> */}
            <img className="f434" src={img1} />
            <div className="imgList">
              {damageImages.map(file => (
                <div key={file.id}>
                  <div className="img" key={file.name}>
                    <IoIosClose
                      size={50}
                      color="#red"
                      className="closeImgIcon"
                      onClick={() => handleImgDelete(file.id)}
                    />
                    <img
                      src={file.preview}
                      className="uploadedimg"
                      id={file.id}
                      alt="upload"
                      onError={e => onError(e)}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />

              <div>
                {damageImages.length < 1 ? (
                  <div>
                    <PrimaryButton title="UPLOAD IMAGE" className="btn-upload-img" />
                  </div>
                ) : (
                  <div />
                )}

                {/* <p className="mobileviewTxt">Add Photo</p> */}
              </div>
            </div>
          </div>
          <div className="next-position">
            <a role="none" className="back-link-oneImage" onClick={() => setCount(count - 1)}>
              Back
            </a>
            <button
              className="next-twoimg new-btn-next"
              disabled={oppsiteImages.length < 1}
              onClick={async () => {
                let valid = true;
                let type = '';
                await oppsiteImages.map(cur => {
                  var parts = cur.path.split('.');
                  type = parts[parts.length - 1];
                  if (type === 'webp' || type === 'gif' || type === 'tiff') {
                    valid = false;
                  }
                });
                if (valid === false) {
                  alert(type + ' image type is not supported. We support JPEG, PNG and HEIC');
                } else {
                  handleFormSubmit();
                  setShow(false);
                }
              }}
            >
              <img className={`next-icon`} src={NextIcon} />
              <p className="next-Txt">Finish</p>
            </button>
          </div>
        </div>
      );
    }
    if (count === 4) {
      return (
        <div >
          <div className="closeImage-top" role="none" onClick={() => setCount(0)}>
            <IoIosClose size={30} color="#316FD0" />
          </div>
          <div className="next-position">
            <a
              role="none"
              className="back-link-oneImage"
              onClick={() => {
                setCount(0)
              }}
            >
              {'<'} Back
            </a>
          </div>

          <div className=" uploadImageTitle uploadImageTitleTip">
            Tip 1
          </div>
          <div className=" uploadImageSubTitle">
            <p>
              Pick one wall to start with. Stand back to get a photo of each wall and the edges
            </p>
          </div>
          <div className="oneImage-bg ImageUploadBg">
            <div>
              <div className="two-img">
                <img src={Tip1} className="tip-image1" />
              </div>{' '}
            </div>
          </div>

          <div className=" uploadImageTitle uploadImageTitleTip">
            Tip 2
          </div>
          <div className=" uploadImageSubTitle">
            <p>
              Make sure the ceiling, the floor, and all four corners are in the photo
            </p>
          </div>
          <div className="oneImage-bg ImageUploadBgTip">
            <div>
              <div className="two-tip-img">
                <div className='tipdivwithsign'>
                  <img src={Tip2Wrong} className="tip-image2" />
                  <img src={TipCross} className="tipStatus-image" />
                </div>
                <div className='tipdivwithsign'>
                  <img src={Tip2Right} className="tip-image2" />
                  <img src={TipTick} className="tipStatus-image" />
                </div>
              </div>{' '}
            </div>
          </div>

          <div className=" uploadImageTitle uploadImageTitleTip">
            Tip 3
          </div>
          <div className=" uploadImageSubTitle">
            <p>
              Hold the camera straight on and avoid blurry shots
            </p>
          </div>
          <div className="oneImage-bg ImageUploadBgTip">
            <div>
              <div className="two-tip-img">
                <div className='tipdivwithsign'>
                  <img src={Tip3Wrong} className="tip-image2" />
                  <img src={TipCross} className="tipStatus-image" />
                </div>
                <div className='tipdivwithsign'>
                  <img src={Tip3Right} className="tip-image2" />
                  <img src={TipTick} className="tipStatus-image" />
                </div>
              </div>{' '}
            </div>
          </div>

          <div className=" uploadImageTitle uploadImageTitleTip">
            Tip 4
          </div>
          <div className=" uploadImageSubTitle">
            <p>
              <b></b> Capture walls in a clockwise direction, moving from left to right
            </p>
          </div>
          <div className="oneImage-bg ImageUploadBgTip">
            <div>
              <div className="two-img">
                <img src={Tip4} className="tip-image1" />
              </div>{' '}
            </div>
          </div>

          <div className=" uploadImageTitle uploadImageTitleTip">
            Tip 5
          </div>
          <div className=" uploadImageSubTitle">
            <p>
              If you have an open concept kitchen/dining room, you can picture imaginary walls separating the kitchen and dining room space.
              <br />
              <br />
              Then, take pictures of the space as if it were two rooms
            </p>
          </div>

          <div className='centerDiv'>
            <button
              title="Next"
              className="imageUploadButton"
              // disabled={oppsiteImages?.includes(null)}
              onClick={async () => {
                setCount(0);
              }}
            >
              <p className="next-Txt">I'm Ready to Start!</p>
            </button>
          </div>
        </div>
      );
    }
    return <div />;
  };

  const handleChecks = async () => {
    const errorTemplate = {};
    if (address1.trim().length === 0) {
      errorTemplate.addressLine1 = 'Address Cannot be empty';
      setError(errorTemplate);
    }
    if (city.trim().length === 0) {
      errorTemplate.city = 'City cannot be empty';
      setError(errorTemplate);
    }
    if (!state) {
      errorTemplate.cityCode = 'State cannot be empty';
      setError(errorTemplate);
    }
    if (zip.trim().length === 0) {
      errorTemplate.zip = 'Zip cannot be empty';
      setError(errorTemplate);
    }
    if (zip.trim().length < 5 || zip.trim().length > 5) {
      errorTemplate.zip = 'Zip should be of 5 digits';
      setError(errorTemplate);
    } else if (isObjEmpty(errorTemplate)) {
      setError(initialData);
      // handleImgReq()
      // await handleFormSubmit();
      if (isObjEmpty(errorTemplate)) {
        setError({});
        if (reqImg) {
          // handleFormSubmit();
          if (claimroomid == "") {
            await handleFormSubmit();
          }
        }
        else {
          if (claimroomid == "") {
            await handleFormSubmit();
          }
          setShow(true);
          const data = {
            damageType,
            address1,
            address2: '',
            city,
            state,
            zip,
            country: 'USA',
            notes: '',
            customerID,
            handleaddressform: "address"
          };
          setClaimFields(data);
        }
      }
    }
  };

  const makeFormData = (uploadFields, file) => {
    const data = new FormData();
    data.append('key', uploadFields.key);
    data.append('x-amz-algorithm', uploadFields['x-amz-algorithm']);
    data.append('x-amz-credential', uploadFields['x-amz-credential']);
    data.append('x-amz-date', uploadFields['x-amz-date']);
    data.append('x-amz-security-token', uploadFields['x-amz-security-token']);
    data.append('policy', uploadFields.policy);
    data.append('x-amz-signature', uploadFields['x-amz-signature']);
    data.append('x-amz-meta-claimId', uploadFields.claimId);
    data.append('x-amz-meta-roomId', uploadFields.roomId);
    data.append('x-amz-meta-submitter', uploadFields.submitter);
    data.append('x-amz-meta-imageField', uploadFields.imageField);
    data.append('file', file);
    return data;
  };

  const handleAddClaimAndRoom = async claimId => {
    if (claimroomid == "") {
      const roomId = await dispatch(createRoom(claimId, { roomName, roomType }));
      setClaimroomid(roomId);
    }
    setClaimtempid(claimId);
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    const data = {
      damageType,
      address1,
      address2,
      city,
      state,
      zip,
      country: 'USA',
      notes: '',
      customerID,
      handleaddressform: "address"
    };
    const claimData = await dispatch(createClaim(data, customerID));
    if (reqImg) {
      setModalShown(true);
      setLoading(false);
      setTimeout(() => setOpen(claimData), 1500);
    } else {
      setLoading(false);
      // if (claimroomid == "") {
      //   const roomId = await dispatch(createRoom(claimData.claimId, { roomName, roomType }));
      //   setClaimroomid(roomId);
      //   // setLoading(false);
      // }
      setClaimtempid(claimData.claimId);
      // handleAddClaimAndRoom(claimData.claimId);
    }
  };

  const handleSelect = (userType) => {
    history.push(`/app/reqimgpopup/${open.claimId}/${userType}`)
  }

  return (
    <div id="new-claim-popup1" className="new-claim">
      <Loader loading={loading} absolute overlay />
      <UserType open={Boolean(open)} onClose={() => setOpen(null)} onSelect={handleSelect} />
      <div className="main-address-popup">
        {show || oneImage ? null : (
          <div className="form-newclaim">
            <div className="closeImage" role="none" onClick={handleClose}>
              <IoIosClose size={30} color="#316FD0" />
            </div>
            <div className="main_form_div">
              <label htmlFor="enterPropertyName" className="heading-labels">
                Enter Property Address
              </label>
              <div className="both_form">
                <div className="left_form">
                  <div className="completeaddress">
                    <div className="input-box-Div ">
                      <label htmlFor="address1" className="input-labels">
                        Street Address
                      </label>
                      <input
                        size="25"
                        type="text"
                        placeholder="123 Highland Street"
                        className="input-text"
                        id="address1"
                        value={address1}
                        onChange={e => setAddress1(e.target.value)}
                      />
                    </div>
                    {error.addressLine1 ? <div className="error">{error.addressLine1} </div> : null}
                    <div className="input-box-Div ">
                      <label htmlFor="address2" className="input-labels">
                        Apt/Suite (optional)
                      </label>
                      <input
                        size="25"
                        type="text"
                        placeholder="1"
                        className="input-text"
                        id="address2"
                        value={address2}
                        onChange={e => setAddress2(e.target.value)}
                      />
                    </div>
                    <div className="input-box-Div ">
                      <div className="input-box-Div">
                        <label htmlFor="city" className="input-labels">
                          City/State/ZIP
                        </label>
                        <input
                          size="25"
                          type="text"
                          placeholder="Sacramento"
                          className="input-text"
                          id="city"
                          value={city}
                          onChange={e => {
                            if (/^[a-zA-Z]*$/.test(e.target.value)) {
                              setCity(e.target.value);
                            }
                          }}
                        />
                      </div>
                      {error.city ? <div className="error">{error.city} </div> : null}

                      <div className="cityCodeinner">
                        <div className="cityCode">
                          <select
                            className="selectcity"
                            value={state}
                            onChange={e => setState(e.target.value)}
                          >
                            {usaStates.map(stateVal => (
                              <option value={stateVal}>{stateVal}</option>
                            ))}
                          </select>
                          {error.cityCode ? <div className="error">{error.cityCode} </div> : null}
                        </div>

                        <div className="countryCodeDiv">
                          <input
                            size="13"
                            type="number"
                            placeholder="02145"
                            className="input-text new-input hide_number_icon"
                            id="countryCode"
                            value={zip}
                            onChange={e => setZip(e.target.value)}
                          />
                          {error.zip ? <div className="error">{error.zip} </div> : null}
                        </div>

                      </div>
                    </div>
                    <div className="input-box-Div ">
                      <label htmlFor="claiminfo" className="input-labels">
                        Claim ID (optional)
                      </label>
                      <input
                        size="25"
                        type="text"
                        className="input-textadd"
                        placeholder="Type your Claim ID Here"
                        id="address1"
                        value={customerID}
                        onChange={e => setCustomerID(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="right_form">
                  <PrimaryButton
                    className="new_claims_btn_b"
                    title="REQUEST IMAGE"
                    onClick={() => {
                      // handleChecks();
                      handleImageRequest();
                    }}
                  />
                  <PrimaryButton
                    disabled={credits < 1}
                    className="new_claims_btn_b new_claims_btn"
                    title="ADD ROOM"
                    onClick={() => {
                      handleChecks();
                      // setShow(true)
                    }}
                  />
                  {credits < 1 ? (
                    <div className="btn_hover_text">
                      <p>Additional credits required. Contact info@hostalabs.com</p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="go_back" role="none" onClick={handleGoBack}>
              <IoIosArrowBack color="#537BB8" />
              <p className="m-0"> Back</p>
            </div>
          </div>
        )}
      </div>
      <Modal
        show={show}
        onHide={handleHide}
        // centered
        centered
        animation
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="shadow-lg roomdd"
      >
        {/* modal-dialog modal-lg modal-dialog-centered */}
        <Modal.Body className="btn-position">
          <div className="closeImage-top" role="none" onClick={() => setShow(false)}>
            <IoIosClose size={30} color="#316FD0" />
          </div>
          {!showRules ? (
            <div id="add-room" className="input-labels">
              <a role="none" className="back-link" onClick={() => setShowRules(true)}>
                {'<'} Back
              </a>
              <div>
                <label htmlFor="selectRoomType" className="uploadImageTitle">
                  Select Room Type
                </label>
              </div>
              <div className='centerDiv'>
                <select
                  className="select-input large-select-input"
                  value={roomType}
                  onChange={e => setroomType(e.target.value)}
                >
                  <option value="">Select Type</option>
                  <option value="Bathroom">Bathroom</option>
                  <option value="Bedroom">Bedroom</option>
                  <option value="Basement">Basement</option>
                  <option value="Den">Den</option>
                  <option value="DiningRoom">Dining Room</option>
                  <option value="Hall">Hall</option>
                  <option value="Sunroom">Sunroom</option>
                  <option value="Kitchen">Kitchen</option>
                  <option value="Laundry">Laundry</option>
                  <option value="Living">Living</option>
                  <option value="Library">Library</option>
                </select>
              </div>
              <div className='centerDiv'>
                {/* <a role="none" className="back-link" onClick={() => setShowRules(true)}>
                  Back
                </a> */}
                <button
                  className="imageUploadButton"
                  disabled={roomType === ''}
                  onClick={() => {
                    handleRoomId();
                    // setoneImage(true);
                    // setShow(false);
                  }}
                >
                  {/* <img
                    className={`next-icon ${roomType === '' ? 'disable-icon' : ''}`}
                    src={NextIcon}
                  /> */}
                  <p className="next-Txt">Next</p>
                </button>
              </div>
            </div>
          ) : (
            <div id="add-room" className="input-labels">
              <label htmlFor="selectRoomType" className="uploadImageTitle">
                First, please make sure your room is ready
              </label>
              <div className="list-style">
                <ul>
                  <li>
                    Open all curtains <br className="break-line" />
                    (window, shower, etc.)
                  </li>
                  <li>Make sure the room is well lit</li>
                  <li>
                    If possible, please ensure that at least one electrical receptacle is visible
                    and unplugged
                  </li>
                  <li>
                    Assess how to best capture most of the wall and room -{' '}
                    <br className="break-line" />
                    holding the phone up or sideways?
                  </li>
                  <li>Stand inside the room, even if the room is small</li>
                </ul>
                <div className="addimagesd">
                  <img className="iimg1" src={iimg1} />
                  <img className="iimg2" src={iimg2} />
                </div>
              </div>
              <div className='centerDiv'>
                {/* <a role="none" className="back-link" onClick={() => handleHide()}>
                  Back
                </a> */}
                <button className="imageUploadButton" onClick={() => setShowRules(false)}>
                  {/* <img className="next-icon" src={NextIcon} /> */}
                  <p className="next-Txt">Next</p>
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={oneImage}
        onHide={handleHideOneImage}
        animation
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        className="shadow-lg fullModelCss"
      >
        <Modal.Body>
          {count <= 4 ?
            <div>
              <div className='HostaAILogoDiv'>
                <img src={HostaAILogo} />
              </div>
              {renderPopup()}
            </div>
            : handleClose()}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddressForm;
