import React, { useContext, useState, useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import classNames from 'classnames';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton/PrimaryButton';
import BrokenList from '../../../../../components/BrokenList/BrokenList';
import Checkbox from '../../../../../components/Checkbox/Checkbox';
import { RoomInterfaceContext } from '../../../../../context/RoomInterface';

const Details = () => {
  const [checked, setChecked] = useState({});
  const {
    detailsActive,
    setDetailsActive,
    detailJSON,
    carouselIndex,
    allAreas,
    setCalculationIds,
    setCalculationCheckbox,
    calculationIds,
    calculationCheckbox,
  } = useContext(RoomInterfaceContext);
  const detailsRootName = 'root';
  const currentActive = detailsActive[detailsRootName];

  console.log('context', useContext(RoomInterfaceContext));

  useEffect(() => {
    const arrChecked = allAreas[carouselIndex]?.filter(
      item => !calculationCheckbox.includes(item.unique_id)
    );

    if (arrChecked?.length === 0) setChecked({ ...checked, [carouselIndex]: true });
    else setChecked({ ...checked, [carouselIndex]: false });
  }, [calculationIds, calculationCheckbox]);

  const onCheckboxChange = () => {
    if (!checked[carouselIndex]) {
      const allCalIds = allAreas[carouselIndex].map(item => item.navigate);
      const allCheckBox = allAreas[carouselIndex].map(item => item.unique_id);
      setCalculationIds([...calculationIds, ...allCalIds]);
      setCalculationCheckbox([...calculationCheckbox, ...allCheckBox]);
      setChecked({ ...checked, [carouselIndex]: true });
    } else {
      setCalculationIds([]);
      setCalculationCheckbox([]);
      setChecked({});
    }
  };

  const RightCheckbox = () => (
    <Checkbox
      id="tempCalculationCheckbox"
      checked={checked[carouselIndex]}
      onChange={onCheckboxChange}
    />
  );

  return (
    <div id="interface-details">
      <div className="interface_details_top">
        <p />
        <p>Unit</p>
        <p>Qty.</p>
        <p>Add to list</p>
      </div>
      <Accordion
        className="interface_details_bottom"
        onSelect={val => setDetailsActive({ [detailsRootName]: val })}
        activeKey={currentActive}
      >
        {detailJSON.length > 0 &&
          Object.entries(detailJSON[carouselIndex]).map((cur, i) => {
            const activeKey = `${carouselIndex}_0${i + 1}`;
            const [key, value] = cur;
            const ChevronIcon = currentActive === activeKey ? FiChevronDown : FiChevronRight;
            if(Array.isArray(value) && value.length < 1) return <div key={i}></div>
            return (
              <div className="details_root_container" key={i}>
                <Accordion.Toggle
                  as="div"
                  variant="link"
                  eventKey={activeKey}
                  className={classNames('details_root_header', {
                    details_root_header_active: activeKey === currentActive,
                    [`details_root_header--${key.toLowerCase()}`]: true,
                  })}
                >
                  <ChevronIcon size={25} />
                  <p className="details_root_title">{key}</p>
                </Accordion.Toggle>
                <Accordion.Collapse
                  className={classNames(
                    `details_root_content details_root_content--${key.toLowerCase()}`
                  )}
                  eventKey={activeKey}
                >
                  <div className="details_root_sub_details">
                    <BrokenList
                      data={value}
                      dataKey={key}
                      active={detailsActive}
                      activeKey={activeKey}
                      type="details"
                    />
                  </div>
                </Accordion.Collapse>
              </div>
            );
          })}
      </Accordion>
      <div className="float-right mt-3">
        <PrimaryButton
          className="new_claims_btn float-left mr-3 w-100 pe-none"
          title="Select All"
          Right={RightCheckbox}
        />
      </div>
    </div>
  );
};

export default Details;
