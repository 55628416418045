import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { verify } from '../../../store/actions/auth';
import PrimaryButton from '../../../components/Buttons/PrimaryButton/PrimaryButton';
// import newLogo from '../../../assets/images/logo.png';
// import logoText from '../../../assets/images/logoText.png';
import newLogo from '../../../assets/images/HostaAI_LogoWithTagline.png';
import '../Common.scss';

const NewLogo = styled.img`

  @media (max-width: 768px) {
    width: 75%;
    height:120%;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    width: 55%;
    height:120%;
  }

  @media (min-width: 991px)  {
    width: 35%;
    height:120%;
  }
`;

const Verify = () => {
    const [code, setCode] = useState('');
    const [modalShown, setModalShown] = useState(false);
    const [modalErrorText, setModalErrorText] = useState('');
    const [success, setSuccess] = useState(false);    
    const history = useHistory();
    const { accountInfo } = useParams()

    const handleVerify = async () => {
        const email = atob(accountInfo)
        try {
            await verify(email, code);
            setModalErrorText(
                'You have successfully verified your email. Please login to continue.'
            );
            setSuccess(true);
        } catch (err) {
            console.log(err)
            setModalErrorText("Invalid Code. Cannot Verify Email");
            setSuccess(false);
        }
        setModalShown(true);
    }


    const handleModalConfirm = () => {
        if (success) {
            setModalShown(false);
            history.push('/login');
        } else {
            setModalShown(false);
        }
    };



    return (
        <div id="login" className="main-conatiner">
            <div className="bgImg" />
            <div className="inner-div">
                <NewLogo src={newLogo} />
                <div className="floating-input-div">
                    <input
                        className="floating-input"
                        type="text"
                        placeholder="Verfication Code"
                        onChange={e => setCode(e.target.value)}
                        value={code}
                    />
                </div>

                <div className="button">
                    <Button className="newButton" onClick={handleVerify}>
                        Verify Account
                    </Button>
                </div>
            </div>
            <Modal
                show={modalShown}
                onHide={() => setModalShown(false)}
                centered
                animation
                size="md"
                className="shadow-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" />
                </Modal.Header>
                <Modal.Body className="text-center">
                    <h3>{modalErrorText}</h3>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <div>
                        <PrimaryButton className="new_claims_btn" title="OK" onClick={handleModalConfirm} />
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Verify;
