import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SecondarySpan = styled.p`
  font-weight: 350;
  font-size: 1.2rem;
  color: #2e3949;

  @media (min-width: 768px) {
    font-size: 1.25rem;
    text-align: center;
  }
`;


const SecondaryHeadline = ({ text }) => <SecondarySpan>{text}</SecondarySpan>;

export default SecondaryHeadline;

SecondaryHeadline.defaultProps = {
  text: 'text here'
}

SecondaryHeadline.propTypes = {
  text: PropTypes.string
}