import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import logoLoader from '../../assets/images/LoadingExporttrans.gif';
import './Loader.scss';

// TYPE FULL WHERE NO HEADER ELSE HALF
const Loader = props => {
  const { type, loading, absolute, overlay } = props;

  if (!loading) return null;

  return (
    <div
      className={classNames('loader_container', {
        'loader_container--absolute': absolute,
        'loader_container--overlay': overlay,
      })}
      style={{ minHeight: type === 'full' ? '100vh' : 'calc(100vh - 80px)', zIndex: 11111 }}
    >
      <img className="loader" src={logoLoader} alt="loader" />
    </div>
  );
};

Loader.defaultProps = {
  type: 'full',
  loading: true,
  absolute: false,
  overlay: false,
};

Loader.propTypes = {
  type: PropTypes.string,
  loading: PropTypes.bool,
  absolute: PropTypes.bool,
  overlay: PropTypes.bool,
};

export default Loader;
