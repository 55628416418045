import React from 'react';

const NotFound = () => (
  <div className="not-found-container">
    <p className="error-heading">Error 404</p>
    <p className="error-text">Sorry we couldn&apos;t find the page you were looking for</p>
  </div>
);

export default NotFound;
