const initialClaimState = {
  activeClaimPage: 1,
  activeRoomPage: 1,
};

const PaginationReducer = (state = initialClaimState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_ACTIVE_CLAIM': {
      return {
        ...state,
        activeClaimPage: payload,
      };
    }
    case 'SET_ACTIVE_ROOM': {
      return {
        ...state,
        activeRoomPage: payload,
      };
    }
    default:
      return state;
  }
};

export default PaginationReducer;
