import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import { IoIosClose, IoIosArrowBack } from 'react-icons/io';
import { Carousel, Row, Col, Table, Pagination, Modal } from 'react-bootstrap';

import PrimaryButton from '../../../components/Buttons/PrimaryButton/PrimaryButton';

const RequestLink = () => {
  const data = [
    'http://testyourapp.online/hostalabapp/hostalabimages/1.png',
    'http://testyourapp.online/hostalabapp/hostalabimages/2.png',
    'http://testyourapp.online/hostalabapp/hostalabimages/3.png',
    'http://testyourapp.online/hostalabapp/hostalabimages/4.png',
    'http://testyourapp.online/hostalabapp/hostalabimages/5.png',
    'http://testyourapp.online/hostalabapp/hostalabimages/6.png',
    'http://testyourapp.online/hostalabapp/hostalabimages/7.png',
    'http://testyourapp.online/hostalabapp/hostalabimages/8.png',
  ];
  const caption = [
    "Picture is best taken in landscape mode, with both floor and ceiling visible in the image. If you're unable to capture both floor and ceiling in one image, use portrait mode.",

    'On order to get the most accurate measurements of the room and its contents, we need a picture of every wall in the room and corners.',

    'Aim your camera at the wall close to a 90 degree angle if possible while still keeping the floor and ceiling in the image.',

    'To capture a wall, stand as far back as possible from the space and point the camera directly at the wall you want to capture.',

    'In case the wall is too long to capture in one image, capture one part of the wall, move sideways and capture the next part of the wall. Ensure there is at least 30% overlap between images. Repeat if there are more wall area to cover.',

    'Once you finish capture a wall, move on to the corner.',

    "Capturing the corner is the same as capturing walls, ensure both the floor and ceiling are visible. If your phone can't capture both floor and ceiling, use portrait mode.",

    'After capturing all the walls and corner of the room, find a spot where you can capture most of the features of the room, this could be somewhere inside the room or a doorway looking into the room. Capture 1 or 2 pictures of the room overall.',
  ];

  const history = useHistory();
  const handleClose = () => {};
  const handleBack = () => {
    history.goBack();
  };

  return (
    <div className="main-popup-container" id="instructions">
      <div className="popup-container">
        <div className="closeImage" role="none" onClick={handleClose}>
          <IoIosClose size={30} color="#316FD0" />
        </div>
        <div className="carousel_instsructions">
          <div className="inner_carousel_instaructions">
            <Carousel
              prevIcon={<FaChevronLeft color="#537BB8" size={80} />}
              nextIcon={<FaChevronRight color="#537BB8" size={80} />}
            >
              {data.map((cur, i) => (
                <Carousel.Item key={i}>
                  <Carousel.Caption>
                    <p className="img_caption">
                      <b>{caption[i]}</b>
                    </p>
                  </Carousel.Caption>
                  <img className="carousel_image" src={cur} alt="First slide" />
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>
        <div className="capturingBtn">
          <PrimaryButton className="capturing_btn" title="START CAPTURING" onClick={handleBack} />
        </div>
      </div>
    </div>
  );
};
export default RequestLink;
