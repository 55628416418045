import React, { Fragment, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Header from './components/Header/Header';
import Login from './pages/Auth/Login/Login';
import ForgotPassword from './pages/Auth/ForgotPassword/ForgotPassword';
import Signup from './pages/Auth/Signup/Signup';
import RoomInterface from './pages/app/RoomInterface/RoomInterface';
import NewClaim from './pages/app/NewClaim/NewClaim';
import NewRoom from './pages/app/NewRoom/NewRoom';
import NewRoomLink from './pages/app/NewRoom/NewRoomLink';
import AllClaim from './pages/app/AllClaim/AllClaim';
import QaClaim from './pages/app/AllClaim/Qaclaim';
import AddressForm from './pages/app/NewClaim/Layouts/AddressForm/AddressForm';
import ReqImage from './pages/app/NewClaim/Layouts/ReqImage/ReqImage';
import RequestLink from './pages/app/NewClaim/Layouts/RequestLink/RequestLink';
import Instructions from './pages/app/Instructions/Instructions';
import AllRoom from './pages/app/AllRoom/AllRoom';
import RoomAdded from './pages/app/NewRoom/Layouts/RoomAdded/RoomAdded';
import RoomAddedLink from './pages/app/NewRoom/Layouts/RoomAdded/RoomAddedLink';
import NotFound from './pages/app/NotFound/NotFound';
import UploadRoomImages from "./pages/app/NewRoom/UploadRoomImages";
import store from './store/store';
import { getAllClaim } from './store/actions/allClaim';
import { loadUser } from './store/actions/auth';
import { getCredits } from './store/actions/credits';
import ProcessingRoomInterface from './pages/app/RoomInterface/processingRoomInterface';
import ReleaseNotes from './pages/app/ReleaseNotes/ReleaseNotes';

//new-portal-imports-------------------
import ReferencePageLayout from './new-portal-pages/ReferencePageLayout/ReferencePageLayout';
import One_Combined_V2 from './new-portal-pages/One_Combined_V2/One_Combined_V2';
import Two_SelectTheRoom from './new-portal-pages/Two_SelectTheRoom/Two_SelectTheRoom';
import Three_Tips from './new-portal-pages/Three_Tips/Three_Tips';
import Three_TipsInstructions from './new-portal-pages/Three_TipsInstructions/Three_TipsInstructions';
import NewPortalContext from './new-portal-context/NewPortalContext';
// /new-portal-imports------------------

import Verify from './pages/Auth/Verify/Verify';
import Errors from './pages/Auth/Errors/Errors';
import NewCapture from './pages/app/NewRoom/NewCapture';

const AppRoute = props => {
  const { 
    component: Component, 
    isNewPortal, 
    showHeader, 
    nonAuthenticated, 
    freeRoute,
    ...rest } = props;
  //freeroute might be something like a condition together with 
  //nonauthenticated sends user somewhere
  //nonauthenticated makes it ok for non-loggedin to access certain like login
  //>>>Maybe google aws cognito best practices for how to handle these flags
  // const { noHeader, nonAuthenticated, freeRoute } = rest;
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  return (
    <Route
      {...rest}
      render={renderProps => {
        const { history } = renderProps;
        
        //if its not a freeroute(a route ok to access wo login)
        if (!freeRoute) {
          if (nonAuthenticated && isAuthenticated) {
            history.push('/app/allclaim');
            return null;
          }
          if (!nonAuthenticated && !isAuthenticated) {
            history.push('/login');
            return null;
          }
        }

        return (
          <Fragment>
            {/* render either newPortalComponent with ReferencePageLayout
                or Latitude component  */}
            {isNewPortal ? (
              <ReferencePageLayout  {...rest} {...renderProps}>
                <Component {...renderProps} />
              </ReferencePageLayout>
            ):(
              <Fragment> 
                {/* noHeader is  for screens like photoupload that shouldnt show a header */}
                <Header showHeader={showHeader} />
                {/* removed rest in Component below */}
                <Component 
                  nonAuthenticated 
                  freeRoute
                  {...renderProps} />
              </Fragment> 
            )}
          </Fragment>
        );
      }}
    />
  );
};








const Routing = () => {
  const [isRendered, setRendered] = useState(false);
  //returnURLPath is used to provide the return path to Latitude UI
  //from TipsInstructionsPage
  // const ssReturnUrlPath = sessionStorage.getItem(returnUrlPath');
  const returnUrlPath  =  sessionStorage.returnUrlPath;

  useEffect(() => {
    (async () => {
      const res = await store.dispatch(loadUser());
      if (res) {
        if (store.getState().auth.userType === 'AppUser') {
          await store.dispatch(
            getAllClaim({
              start: 0,
              end: 10,
              search: '',
            })
          );
        }
        await store.dispatch(getCredits());
      }
      setRendered(true);
    })();
  }, []);

  if (!isRendered) return null;

  return (
    <HashRouter>
      <ScrollToTop scrollDisabledPathname={[]}>
        <Switch>
          <AppRoute path="/" component={Login} exact  nonAuthenticated />
          <AppRoute path="/login" component={Login} exact  nonAuthenticated />
          <AppRoute path="/signup" component={Signup} exact nonAuthenticated />
          <AppRoute path="/verify/:accountInfo" component={Verify} exact nonAuthenticated />
          <Route path="/errors/:roomId/:linkId" component={Errors} exact nonAuthenticated />
          <AppRoute
            path="/forgotpassword"
            component={ForgotPassword}
            exact
            nonAuthenticated
          />
{/*Protected Routes*/}
{/* new-portal-routes------------------- */}
          <AppRoute 
            path="/startme/:linkId"
            component={One_Combined_V2}
            hideHeaderBackButton={true}
            hideHeaderResetButton={true}
            ThreeButtonTopButtonParameter={"/selectRoomType"}
            isNewPortal
            freeRoute
          />
          <AppRoute 
            path="/selectRoomType" 
            component={Two_SelectTheRoom}
            buttonOneText={"Continue"}
            ThreeButtonTopButtonParameter={"/threeTips"} 
            isNewPortal
            freeRoute
          />

          <AppRoute 
            path="/threeTips" 
            component={Three_Tips}
            buttonOneShow={false}
            isNewPortal
            freeRoute
          />

          <AppRoute path="/tipsInstructions" 
            component={Three_TipsInstructions}
            buttonOneText={"I'm Ready to Start!"}
            ThreeButtonTopButtonParameter={returnUrlPath}
            isNewPortal
            freeRoute
           />
{/* /new-portal-routes-------------------  */}
          
          <AppRoute path="/app/interface/:claimId/:roomId/" component={RoomInterface} exact />
          <AppRoute
            path="/app/interface/:claimId/:roomId/:startd/:endd/:roomstartd/:roomendd"
            component={RoomInterface}
            exact
          />
          <AppRoute
            path="/app/processingInterface/:claimId/:roomId/"
            component={ProcessingRoomInterface}
            exact
          />
          <AppRoute
            path="/app/processingInterface/:claimId/:roomId/:startd/:endd/:roomstartd/:roomendd"
            component={ProcessingRoomInterface}
            exact
          />
           <AppRoute path="/app/releasenotes" component={ReleaseNotes}  isNewPortal  buttonOneShow={false} exact />
          <AppRoute path="/app/newclaim" component={NewClaim} showHeader={false} exact />
          <AppRoute path="/app/newroom/:claimId" component={NewRoom} />
          <AppRoute path="/app/newroomlink/:linkId" component={NewRoomLink} freeRoute />
          {/* <!-- TODO DELETE --> */}
          <AppRoute path="/app/newcapture/:captureId" component={NewCapture} showHeader={true} />
          <AppRoute path="/app/allroom/:claimId/:startd/:endd" component={AllRoom} showHeader />
          <AppRoute path="/app/allroom/:claimId/" component={AllRoom} showHeader />
          <AppRoute path="/app/room/:claimId/upload-images" component={UploadRoomImages} showHeader exact />
          <AppRoute path="/app/allclaim" component={AllClaim} showHeader exact />
          <AppRoute path="/app/qaclaim" component={QaClaim} showHeader exact />
          <AppRoute path="/app/newclaimpopup" component={AddressForm} exact />
          <AppRoute path="/app/reqimgpopup/:claimId/:userType?" component={ReqImage} />
          <AppRoute path="/app/reqlinkpopup" component={RequestLink} exact />
          <AppRoute path="/app/roomaddedpopup/:claimId" component={RoomAdded} showHeader />
          <AppRoute path="/app/roomaddedlinkpopup/:linkId"
            component={RoomAddedLink} 
            freeRoute
          />
          <AppRoute path="/app/instructions" component={Instructions} showHeader exact />
          <Route path="*"  component={NotFound} showHeader />
        </Switch>
      </ScrollToTop>
    </HashRouter>
  );
};

AppRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default Routing;