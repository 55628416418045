import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = props => {
  const { id, className, random, highlight, disabled, ...inputProps } = props;
  const randomKey = Math.floor(Math.random() * 1001);
  const randomId = `${id}--${randomKey}`;
  const customId = typeof random === 'boolean' ? randomId : `${id}--${random}`;

  return (
    <div className={`custom-control custom-checkbox checkhigh ${className}`}>
      {highlight ? <p className="checkhighp">Highlight All</p> : null}
      <input disabled={disabled} type="checkbox" className="custom-control-input" id={customId} {...inputProps} />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="custom-control-label" htmlFor={customId} />
    </div>
  );
};

Checkbox.defaultProps = {
  className: '',
  random: true,
  highlight: false,
  disabled: false
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  random: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  highlight: PropTypes.bool,
  disabled: PropTypes.bool
};

export default Checkbox;
