import React, { useState, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { RoomInterfaceContext } from '../../../../../context/RoomInterface';
import Box from '../../../../../components/Box/Box';
import BrokenList from '../../../../../components/BrokenList/BrokenList';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton/PrimaryButton';
import Checkbox from '../../../../../components/Checkbox/Checkbox';
import { saveCalculationList, downloadFloorPlan } from '../../../../../store/actions/allRoom';

const Calculation = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { claimId, roomId } = params;
  const {
    calculationActive,
    calculationList,
    setCalculationIds,
    setCalculationList,
    setCalculationCheckbox,
    isCalculationChecked,
    setCalculationChecked,
    setCalculationActive,
  } = useContext(RoomInterfaceContext);
  const [modal, setModal] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const dataKey = 'calculation';

  const handleClearList = useCallback(async () => {
    await dispatch(saveCalculationList(claimId, roomId, []));
    setCalculationIds([]);
    setCalculationList([]);
    setCalculationCheckbox([]);
    setModal(false);
  }, [setCalculationCheckbox, setCalculationIds, setCalculationList]);

  const onCheckboxChange = () => {
    setCalculationChecked(prev => (prev === 'on' ? 'off' : 'on'));
    setCalculationActive({ [dataKey]: null });
  };

  const RightCheckbox = () => (
    <Checkbox
      id="tempCalculationCheckbox"
      highlight
      checked={isCalculationChecked === 'on'}
      onChange={onCheckboxChange}
    />
  );

  const handleSave = async () => {
    const ids = calculationList.map(cur => cur.unique_id);
    await dispatch(saveCalculationList(claimId, roomId, ids));
    setModal(true);
    setSaveSuccess(true);
  };

  return (
    <div id="interface-calculation">
      <Box type="header" title="Calculation list" className="calculation_box" Right={RightCheckbox}>
        <div className="calculation_wrapper">
          <BrokenList
            dataKey={dataKey}
            data={calculationList}
            active={calculationActive}
            activeKey="calculation"
            type="calculation"
          />
        </div>
        <div className="calculation_bottom">
          <p role="none" className="clear_list" onClick={() => setModal(true)}>
            Clear List
          </p>
          <div className="btn_wrapper">
            <PrimaryButton title="Save" className="calculation_button" onClick={handleSave} />
            <PrimaryButton
              title="Download"
              className="calculation_button"
              onClick={() => downloadFloorPlan(claimId, roomId)}
            />
          </div>
        </div>
      </Box>
      <Modal
        show={modal}
        onHide={() => {
          setSaveSuccess(false);
          setModal(false);
        }}
        centered
        animation
        size="md"
        className="shadow-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" />
        </Modal.Header>
        <Modal.Body className="text-center">
          {saveSuccess ? <h3>Saved Successfully!</h3> : <h3>Are You Sure You Want To Delete?</h3>}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          {!saveSuccess ? (
            <div>
              <PrimaryButton
                className="new_claims_btn float-left mr-3"
                title="Confirm"
                onClick={handleClearList}
              />
              <PrimaryButton
                className="new_claims_btn"
                title="Cancel"
                onClick={() => setModal(false)}
              />
            </div>
          ) : null}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Calculation;
