import { globalAPICallback } from './UserPool';
import axios from '../axios';

export const downloadReport = claimId => async dispatch =>
  globalAPICallback(async () => {
    const ress = axios({
      method: 'GET',
      responseType: 'blob',
      url: `/claim/${claimId}/report`,
      headers: {
        'Content-Type': 'application/octet-stream',
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'PropertyReport.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
    return ress;
  });

export const downloadInterfaceReport = (claimId, roomId, format = '') => async dispatch =>
  globalAPICallback(async () => {
    const ress = axios({
      method: 'GET',
      responseType: 'blob',
      url: `/claim/${claimId}/room/${roomId}/report?includeAll=True&${format}`,
      headers: {
        'Content-Type': 'application/octet-stream',
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      if (format === '')
        link.setAttribute('download', 'PropertyReport.xlsx'); //or any other extension
      else
        link.setAttribute('download', 'PropertyReport.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
    return ress;
  });

export const downloadDrawings = claimId => async dispatch =>
  globalAPICallback(async () => {
    const ress = axios({
      method: 'GET',
      responseType: 'blob',
      url: `/claim/${claimId}/drawings`,
      headers: {
        'Content-Type': 'application/octet-stream',
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'PropertyDrawings.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
    return ress;
  });
