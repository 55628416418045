import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LargeHeadline from '../SpanHeadlines/LargeHeadline';
import takePhoto from '../../new-portal-images/Modals/takePhoto.svg';
import photoError from '../../new-portal-images/Modals/photoError.svg';
import SecondaryHeadline from '../SpanHeadlines/SecondaryHeadline';
import ThreeButtonPanel from '../ThreeButtonPanel/ThreeButtonPanel';
// NEW MODAL AND MODAL BODY ARE CURRENTLY INTEGRATED INTO THREE TIPS PAGE
const BodyContainer = styled.div`
`;

const CloseButton = styled.button`
  margin-top: 1rem;
  margin-bottom: 2rem; 
  Border: none;
  Background: none;
  font-size: 2.6rem;
  font-weight: 350;
  color: #2E3949;
  &:active{ 
    color: gray;
  }
`;

const ModalImg = styled.img`
  width: 13rem;
  height: 8.75rem;
  margin-top: 2rem;
  margin-left: 5.25rem;
  margin-bottom: 2.25rem;

  @media (min-width: 768px) {
    width: 10rem;
    height: 6.5rem;
    margin-top: 1.4rem;
  }
`;

const ModalBody = (props) => {
  const {
    closeModal,
    children,
    modalProps: {
      modalHeadline,
      modalImage,
      modalSecondaryText
    },
    ...rest 
  }  = props; 
  console.log('MI', modalImage);

  return (
    <BodyContainer>
      <CloseButton onClick={()=>closeModal()}>&#215;</CloseButton>
      { modalHeadline && <LargeHeadline text={modalHeadline} /> }
      {/* wonky workaround for dynamic img var import problems */}
      { modalImage === 'takePhoto' && <ModalImg src={takePhoto} /> }
      { modalImage === 'photoError' && <ModalImg src={photoError} /> }
      { modalSecondaryText && <SecondaryHeadline text={modalSecondaryText} /> }
      {children}
      <ThreeButtonPanel {...rest} />
    </BodyContainer>
  );
};

export default ModalBody;

ModalBody.defaultProps = {
  closeModal: ()=>{}, 
  children: '',
  modalHeadline: '',
  modalImage: 'imgTwo',
  modalSecondaryText: ''
}
 
ModalBody.propTypes = {
  closeModal: PropTypes.func,
  children: PropTypes.node,  
  modalProps: PropTypes.shape({ 
    modalHeadline: PropTypes.string,
    modalImage: PropTypes.string,
    modalSecondaryText: PropTypes.string
  })
}
