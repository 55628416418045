import axios from '../axios';
import { globalAPICallback } from './UserPool';

export const getAllClaim = data => async (dispatch, getState) => {
  if (getState().auth.userType === 'AppUser') {
    return globalAPICallback(async () => {
      const {start, end, search, searchByOrg, startTimestamp, endTimestamp} = data;
      let queryStr = [];
      if (start) queryStr.push(`start=${start}`);
      if (end) queryStr.push(`end=${end}`);
      if (search) queryStr.push(`search=${search}`);
      if (searchByOrg) queryStr.push(`searchByOrg=${searchByOrg}`);
      if (startTimestamp) queryStr.push(`startTime=${startTimestamp}`);
      if (endTimestamp) queryStr.push(`endTime=${endTimestamp}`);
      const url = `/claim?${queryStr.join('&')}`;
      const response = await axios({
        method: 'GET',
        url
      });
      dispatch({
        type: 'GET_ALL_CLAIM',
        payload: response.data,
      });
      return response.data;
    });
  }
};

export const createClaim = (data, customerID) => async dispatch =>
  globalAPICallback(async () => {
    console.log('customerID', customerID);
    const res = await axios({
      method: 'POST',
      url: '/claim',
      data: {
        claimInfo: data,
        customerID: customerID,
      },
    });
    dispatch({
      type: 'ADD_CLAIM',
      payload: {
        claimInfo: data,
        timeInitiated: (Date.now() / 1000).toString(),
        ...res.data,
      },
    });
    return res.data;
  });

export const createSharablelink = (data, userType) => dispatch =>
  globalAPICallback(async () => {
    const res = await axios({
      method: 'POST',
      url: `/claim/${data}/room/request?returnLink=True&userType=${userType}&expirationHours=2160`,
      data: {
        // claimInfo: data,
        // customerID: customerID
      },
    });
    dispatch({
      type: 'ADD_SHARABLE_LINK',
      payload: {
        sharablablink: res.data,
      },
    });
    return res.data;
  });

export const updateClaim = (claim, customerID) => async dispatch =>
  globalAPICallback(async () => {
    await axios({
      method: 'PUT',
      url: `/claim/${claim.claimId}`,

      data: {
        fieldsToUpdate: {
          claimInfo: claim.claimInfo,
          customerID: customerID,
        },
      },
    });
    dispatch({
      type: 'UPDATE_CLAIM',
      payload: claim,
    });
    return true;
  });

export const deleteClaim = id => async dispatch =>
  globalAPICallback(async () => {
    try {
      await axios({
        method: 'DELETE',
        url: `/claim/${id}`,
      });
      dispatch({
        type: 'DELETE_CLAIM',
        payload: id,
      });
      return true;
    }
    catch (ex) {
      dispatch({ type: 'SHOW_ERROR', payload: ex?.response?.data || 'Unable to delete claim' });
    }
  });

export const sortClaim = data => async dispatch =>
  dispatch({
    type: 'SORT_CLAIM',
    payload: data,
  });
