import React, { Fragment } from 'react'
import "./ReleaseNotes.scss"


function ReleaseNotes() {


  return (
    <div className='ReleaseNotes'>
      <div className='releaseNotesDiv'>
        <p id="releasenotes-title">Hosta Release Notes</p>
        {releaseNotes.length > 0 &&
          releaseNotes.reverse().map((data, index) => {
            return (
              <div className='individualSlack' key={index}>
                <h3><b>Release {data.version}</b></h3>
                <p>{data.date}</p>
                {data.notes.new.length > 0 && <Fragment><p><b>New ✨</b></p>
                  <ul>
                    {data.notes.new.map(e => {
                      return <li>{e}</li>
                    })}
                  </ul>
                </Fragment>}
                {data.notes.improvements.length > 0 && <Fragment><p><b>Improvements 🙌</b></p>
                  <ul>
                    {data.notes.improvements.map(e => {
                      return <li>{e}</li>
                    })}
                  </ul></Fragment>}
                <Fragment><p><b>Bug Fixes 🐛</b></p>
                  <ul>
                    {data.notes.bugFixes.map(e => {
                      return <li>{e}</li>
                    })}
                  </ul></Fragment>
                {/* {userType === 'AppUser' &&<button className='deleteBtn' onClick={()=>deletePost(index)} >Delete Post</button>} */}
              </div>
            )
          })
        }
      </div>
    </div>
  )
}


let releaseNotes = [
  {
    version: '1.14',
    date: '02/14/22',
    notes: {
      new: [],
      improvements: ['Internal software update for faster operations and processing'],
      bugFixes: []
    }
  },
  {
    version: '1.16',
    date: '04/29/2022',
    notes: {
      new: ['Onboarding & Tips feature'],
      improvements: ['Improved API support for new features', 'Faster capture experience'],
      bugFixes: []
    }
  },
  {
    version: '1.17',
    date: '03/31/2022',
    notes: {
      new: ['Internal integrations and updates'],
      improvements: [],
      bugFixes: []
    }
  },
  {
    version: '1.17.1',
    date: '04/01/2022',
    notes: {
      new: ['Internal integrations and updates'],
      improvements: ['Improved upload times', 'Software updates', 'Updates to our proprietary AI models for secured processing of customer information'],
      bugFixes: []
    }
  },
  {
    version: '1.18',
    date: '04/29/2022',
    notes: {
      new: ['New automated proprietary AI models '],
      improvements: ['Improved to our UI'],
      bugFixes: []
    }
  },
  {
    version: '1.18.1',
    date: '06/09/2022',
    notes: {
      new: ['Implemented our automated AI models for faster processing'],
      improvements: ['Updates to the UI'],
      bugFixes: []
    }
  },
  {
    version: '1.19',
    date: '07/20/2022',
    notes: {
      new: ['New improved floor plan updates'],
      improvements: ['Images upload is faster', 'updates to our proprietary AI models for great detection'],
      bugFixes: []
    }
  },
  {
    version: '1.21',
    date: '08/10/2022',
    notes: {
      new: ['PDF report'],
      improvements: ['Improvements to Hosta ai’s proprietary models for faster floor plan output', 'Updates to UI'],
      bugFixes: []
    }
  },
  {
    version: '1.22',
    date: '08/24/2022',
    notes: {
      new: ['New API for Damage capture pattern'],
      improvements: ['Updates to floor plan-V1'],
      bugFixes: []
    }
  },
  {
    version: '1.23',
    date: '09/07/2022',
    notes: {
      new: ['Significant new automated process'],
      improvements: ['Updates to operations and optimized version'],
      bugFixes: []
    }
  },
  {
    version: '1.24',
    date: '09/21/2022',
    notes: {
      new: ['None at this time'],
      improvements: ['API update', 'Improvements to capture experience'],
      bugFixes: []
    }
  },
  {
    version: '2.0',
    date: '10/01/2022',
    notes: {
      new: ['New AI models implemented for enhanced accuracy & detection', 'Now we can process complex customer rooms'],
      improvements: ['Major upgrades to automated AI models'],
      bugFixes: []
    }
  },
  {
    version: '2.1',
    date: '12/12/2022',
    notes: {
      new: ['Optimized operations pipeline for faster processing of customer requests'],
      improvements: ['Internal operations optimization & improved support'],
      bugFixes: []
    }
  },
  {
    version: '2.2.0',
    date: '01/17/2023',
    notes: {
      new: ['Image capture to upto 15 images', 'Crawford invoice messaging/notification'],
      improvements: ['FML opening fix', 'Rotating animation for capture pattern improvements', 'Super Annotate SDK library upgrade'],
      bugFixes: []
    }
  },
  {
    version: '2.3.0',
    date: '02/10/2023',
    notes: {
      new: ['Image capture to upto 15 images', 'Invoice messaging/notification'],
      improvements: ['updated FML file format', 'Improved rotating animation for capture pattern for customer ease'],
      bugFixes: []
    }
  },
  {
    version: '2.4.0',
    date: '02/16/2023',
    notes: {
      new: ['Customer specific feature support'],
      improvements: ['Updates to the AI model', 'Updates to the UI'],
      bugFixes: []
    }
  },
  {
    version: '2.5.0',
    date: '03/09/2023',
    notes: {
      new: ['Customer specific customized features'],
      improvements: ['Updates to capture pattern', 'Advance floor plan support', 'Self Service Portal', 'Extended Excel Report'],
      bugFixes: []
    }
  },
]

export default ReleaseNotes;
