import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { FiChevronRight } from 'react-icons/fi';
import Loader from '../../../components/Loader/Loader';
import Carousel from './Layouts/Carousel/Carousel';
import Details from './Layouts/Details/Details';
import Calculation from './Layouts/Calculation/Calculation';
import Variables from './Layouts/Variables/Variables';
import { getRoom, getJSONData, getClaim } from '../../../store/actions/roomInterface';
import RoomInterfaceProvider from '../../../context/RoomInterface';
import { makingArea } from './Layouts/MainFn';
import Photos from './Layouts/Photos/Photos';

const RoomInterface = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { claimId, roomId } = params;
  const [isRendered, setRendered] = useState(false);
  const [carousel, setCarousel] = useState('images');
  const userType = useSelector(state => state.auth.user.userType);
  const [state, setState] = useState({
    claimData: {},
    roomData: {},
    interfaceData: {},
    detailsJSON: {},
    allArea: {},
    calculationInfo: {
      calculationsCheckbox: [],
      calculationsIds: [],
      calculationsList: [],
    },
  });

  const getAllRoomInterfaceData = async room => {
    const { frontEndJSON_links, images_links, calculationList } = room;

    const fetchData = await Promise.all(
      frontEndJSON_links.map(async cur => dispatch(getJSONData(cur)))
    );

    const allAreas = [...Array(images_links.length)].map((_, index) =>
      makingArea(fetchData[index], index)
    );

    const calculationData = calculationList.map(id =>
      allAreas.flatMap(cur => cur).find(cur => cur.unique_id === id)
    );

    return [fetchData, allAreas, calculationData];
  };

  const setRoomInterfaceData = async () => {
    const [claim, room] = await Promise.all([
      userType === 'AppUser' ? dispatch(getClaim(claimId)) : claimId,
      dispatch(getRoom(claimId, roomId)),
    ]);
    const [fetchData, allAreas, calculationData] = await getAllRoomInterfaceData(room);
    setState({
      claimData: claim,
      roomData: room,
      interfaceData: room,
      detailsJSON: fetchData,
      allArea: allAreas || [],
      calculationInfo: {
        calculationsCheckbox: [...room.calculationList],
        calculationsIds: calculationData?.map(cur => cur?.navigate).filter(e => e) || [],
        calculationsList: calculationData || [],
      },
    });
    if (userType === 'AppUser') dispatch(getClaim(claimId));
  };

  useEffect(() => {
    (async () => {
      await setRoomInterfaceData();
      setRendered(true);
    })();
  }, []);

  if (!isRendered) return <Loader type="half" />;

  const { claimData, roomData, interfaceData, detailsJSON, allArea, calculationInfo } = state;

  return (
    <RoomInterfaceProvider
      data={{
        interfaceData: { roomIds: params, claimData, roomData, interfaceData },
        allArea,
        detailsJSON,
        calculationInfo,
        getAllRoomInterfaceData,
      }}
    >
      {claimData.claimInfo?.customerID !== undefined && claimData.claimInfo?.customerID !== '' ? (
        <p className="claimIDHeader">Claim ID: {JSON.stringify(claimData.claimInfo?.customerID)}</p>
      ) : null}
      <div id="room-interface">
        {userType === 'AppUser' ? (
          <span>
            <span
              className="dd"
              role="none"
              onClick={() => history.push(`/app/allroom/${claimId}/`)}
            >
              {claimData.claimInfo?.address2 ? `${claimData.claimInfo.address2}, ` : ''}
              {claimData.claimInfo?.address1},&nbsp;
              {claimData.claimInfo?.city},&nbsp;
              {claimData.claimInfo?.state},&nbsp;{claimData.claimInfo?.country},&nbsp;
              {claimData.claimInfo?.zip}
            </span>
            <FiChevronRight size={30} color="#537BB8" />
          </span>
        ) : null}
        <div className="interface_top">
          <span style={{ textTransform: 'capitalize' }} className="breadcrumb_title">{roomData.roomName}</span>
        </div>
        <Row className="room_interface_container">
          <Col className="carousel_room_interface" lg={6}>
            <Carousel carousel={carousel} setCarousel={setCarousel} />
          </Col>
          <Col className="details_room_interface" lg={6}>
            {carousel === 'photos' ? <Photos /> : <Details />}
          </Col>
          <Col className="summary_room_interface" lg={6}>
            <Variables />
          </Col>
          <Col className="calculation_room_interface" lg={6}>
            <Calculation />
          </Col>
        </Row>
      </div>
    </RoomInterfaceProvider>
  );
};

export default RoomInterface;