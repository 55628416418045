import { globalAPICallback } from './UserPool';
import axios from '../axios';

/* eslint-disable import/prefer-default-export */
export const postRoom = (claimId, userType, data) => async dispatch =>
  globalAPICallback(async () => {
    try {
      const response = await axios({
        method: 'POST',
        url: `/claim/${claimId}/room/request?userType=${userType}`,
        data,
      });
      return response;
    }
    catch (err) {
      return err;
    }
  });
