import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { createRoom } from '../../store/actions/allRoom';
import { createRoomWithLinkId } from '../../store/actions/linkApi';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import NewHeader from '../../new-portal-components/NewHeader/NewHeader';
import ThreeButtonPanel from '../../new-portal-components/ThreeButtonPanel/ThreeButtonPanel';
import NewPortalContext from '../../new-portal-context/NewPortalContext';

const ReferenceBackground = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: #ffffff;
  flex-direction: column;
  align-items: center;
  scrollbar-width: none;
`;

const ReferenceContainer = styled.div`
  // @media (max-width: 767px) {
  position: absolute;
  top: 0;
  left: 0;
  width: 375px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: .7rem;
  padding-right: .7rem;
  margin-bottom: 20px;
  background-color: #ffffff;
  
  @media (min-width: 768px) and (max-width: 990px) {
    width: 990px;
    flex-direction: column;
    margin-bottom: 20px;
  }

  @media (min-width: 991px) {
    width: 1440px;
    flex-direction: row;
    margin-bottom: 20px;
    padding-left: 100px;
  }
`;

const MainBodyContainer = styled.div`
  top: 0;
  left: 0;
  
  @media (min-width: 768px) and (max-width: 990px) {
    width: 100vw;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }

  @media (min-width: 991px) {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  
  }
`;

const MainBodyContainerInner = styled.div`
  @media (min-width: 768px) {
    width: 620px;
    margin-top: 40px;
    margin-bottom: 2rem;
  }
  
  @media (min-width: 768px) and (max-width: 990px) {
    position: relative;
`;

const MainBodyContainerGuts = styled.div`
  margin-bottom: 3rem;
  
  @media (min-width: 768px) {
    //margin-bottom: 1rem;
  }
`;

const ReferencePageLayout = ({
  children,
  referenceContainerHeight, 
  hideHeaderBackButton, 
  hideHeaderResetButton,
  // setting this prop on the route will cause the top
  //button of the threeButtonPanel to default to 'moveToNextPage
  //NOTE: default prop value is true, so by default top button always 
  //passes moveToNextPage
  callbackIsMoveToNextPage,
  //...rest are props specific to 3 button panel 
  ...rest
}) => { 
  
  const history = useHistory();
  const roomType  = sessionStorage.roomType;
  // const { preservedTempClaimIdInContext } = useContext(NewPortalContext);
  // const { updatePreservedRoomIdInContext } = useContext(NewPortalContext);
  // const { linkIdFromParameter}  = useContext(NewPortalContext);
    
  const thisPathname = useLocation().pathname;
  console.log('roomType from RoomMapper', roomType);
  //controls disableButton on roomButtonMapper
  //disabled only when route is roomSelect 
  //&& selectedRoom is '' (unchosen)
  const selectRoomPath = `/selectRoomType`;
  const samePath = thisPathname === selectRoomPath ? true : false ;
  console.log('SAMEPATH:', samePath)
  const selectedRoomUnchosen = roomType === undefined ? true : false;
  const nextButtonDisabled = samePath && selectedRoomUnchosen ? true : false;

  const moveToNextPage = (routeName) => {  
    //history.replace needed for hashrouter and navigation between
    //new ui and latitude ui
    console.log('MOVE TO NEXT PAGE IS:', routeName);
    history.push({
        pathname: routeName,
      });
    // }
  }  ;

  //default behaviour of buttonOne is moveToNextPage
  //BUT if callBackToNextPage is passed in FALSE(default is true) on the route
  //corresponding genericFunction can be called INSTEAD of moveToNextPage
  //AND functionFlag is passed in to 3 button panel as ThreeButtonTopButtonParameter
  //allowing choice of infinite number of additional functions by flag for the top button to call
  const genericFunction = (functionFlag) => {
    console.log('HERE AT GENERIC FUNCTION');
    functionFlag === '1' && setClaimRoomId(roomType);
  };

  //setClaimRoomId NO LONGER USED-save as a model for future reference
  //setClaimRoomId NO LONGER USED-save as a model for future reference
  // sets roomId with chosen roomtype on backend when next button is clicked in room chooser
  //preservedRoomIdInContext is made available in /newroom /newclaim /newRoomLink  for creation of claimRoomId
  //the case of reqImg&NewRoomLink  LinkId api path is different than newRoom&newClaim
  //in the case of creatRoomLinkId linkId is passed into context as preservedTempClaimIdInContext, instead of 
  //claimTempIdfor use here calling createRoomWithLinkId
  const setClaimRoomId = async (roomType) => {
    // let roomId;
    // if (  linkIdFromParameter === 'param'  ) {
    //   console.log('I AM calling NonLinkid createRoom, PARAM IS', linkIdFromParameter);
    //   roomId = await createRoom(preservedTempClaimIdInContext, { roomType });

    // } else {
    //   console.log('Got the Link ID from parameter', linkIdFromParameter);
    //   console.log('I WILL call  Linkid createRoom with the Linkid', );
    //   roomId = await createRoomWithLinkId(linkIdFromParameter, { roomType });
    // }
    // console.log ('RPLO roomId', roomId);
    // updatePreservedRoomIdInContext(roomId);
    moveToNextPage("/threeTips");
  };
  
 

  return (
      <ReferenceBackground>
        <ReferenceContainer 
          containerHeight={referenceContainerHeight}> 
          <NewHeader 
            hideBackButton={hideHeaderBackButton} 
            hideResetButton={hideHeaderResetButton} />
          <MainBodyContainer>  
            <MainBodyContainerInner>
              <MainBodyContainerGuts>
                {children}
              </MainBodyContainerGuts>
              <ThreeButtonPanel 
                buttonOneCallback={callbackIsMoveToNextPage ? moveToNextPage : genericFunction}
                nextButtonDisabled={nextButtonDisabled}
                {...rest} />
            </MainBodyContainerInner>
          </MainBodyContainer>
        </ReferenceContainer>
      </ReferenceBackground>
 
  );
};

export default ReferencePageLayout;


ReferencePageLayout.defaultProps = {
  children: '',
  hideButtonConsole: false, 
  referenceContainerHeight: '300%', 
  hideHeaderBackButton: false, 
  hideHeaderResetButton: true,
  callbackIsMoveToNextPage: true,
  rest: {},
  genericFunctionFlag: ''

}

ReferencePageLayout.propTypes = {
  children: PropTypes.node,
  hideButtonConsole: PropTypes.bool,
  referenceContainerHeight: PropTypes.string, 
  hideHeaderBackButton: PropTypes.bool, 
  hideHeaderResetButton: PropTypes.bool,
  callbackIsMoveToNextPage: PropTypes.bool,
  rest: PropTypes.object,
  genericFunctionFlag: PropTypes.string
}