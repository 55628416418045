import React, { useContext, useState, useEffect } from 'react';
import Box from '../../../../../components/Box/Box';
import { RoomInterfaceContext } from '../../../../../context/RoomInterface';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton/PrimaryButton';
import "./Photos";
import { saveAs } from 'file-saver';
import { useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import simpleLoader from '../../../../../assets/images/simpleLoader.gif';
import { Divider } from '@material-ui/core';

var JSZip = require("jszip");

const Photos = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const { claimId } = params;
  const {
    carouselPhotosIndex,
    setCarouselPhotosIndex,
    photos, roomInterfaceData
  } = useContext(RoomInterfaceContext);

  const Photos = photos;
  const download = imgUrl => {
    window.open(imgUrl);
  };
  return (
    <div id="interface-photos">
      <Box type="header" title="All Images" className="photos_box">
        <h4>Overview Images</h4>
        {Photos.map((cur, i) => {
          if (!roomInterfaceData?.roomData?.damageImages_links.includes(cur))
            return <img
              src={cur}
              style={carouselPhotosIndex === i ? { cursor: "pointer", width: "23%", height: "23%", margin: "1%" } : { opacity: 0.5, cursor: "pointer", width: "23%", height: "23%", margin: "1%" }}
              onClick={() => { setCarouselPhotosIndex(i) }}
              responsive
            />
        })}
        {roomInterfaceData?.roomData?.damageImages_links?.length > 0 && <>
          <Divider />
          <h4>Damage Images</h4>
          {(roomInterfaceData?.roomData?.damageImages_links || []).map((cur, i) => {
            return <img
              src={cur}
              onClick={() => { setCarouselPhotosIndex(Photos.length + i) }}
              style={carouselPhotosIndex === Photos.length + i ? { cursor: "pointer", width: "23%", height: "23%", margin: "1%" } : { opacity: 0.5, cursor: "pointer", width: "23%", height: "23%", margin: "1%" }}
              responsive
            />
          })}
        </>}
        {roomInterfaceData?.roomData?.platformImages_links?.length > 0 && <>
          <Divider />
          <h4>Additional Images</h4>
          {(roomInterfaceData?.roomData?.platformImages_links || []).map((cur, i) => {
            return <img
              src={cur}
              onClick={() => { setCarouselPhotosIndex(Photos.length + i) }}
              style={carouselPhotosIndex === Photos.length + i ? { cursor: "pointer", width: "23%", height: "23%", margin: "1%" } : { opacity: 0.5, cursor: "pointer", width: "23%", height: "23%", margin: "1%" }}
              responsive
            />
          })}
        </>}
        <div className="btn_wrapper" style={{ display: "flex", justifyContent: "flex-end" }}>
          <PrimaryButton
            title={loading ? <img src={simpleLoader} style={{ width: 20, margin: "0px 50px" }} /> : "Download All"}
            className="photos_button"
            onClick={() => {
              setLoading(true);
              var zip = new JSZip();
              photos.concat(roomInterfaceData?.roomData?.damageImages_links || []).concat(roomInterfaceData?.roomData?.platformImages_links || []).forEach((cur, i) => {
                var date = new Date();
                //uncomment below code for 4 image downoads
                const imageBlob = fetch(`${cur}`, { method: "GET", headers: { "Cache-Control": 'no-cache' } }).then(response => response.blob());
                console.log(imageBlob);
                zip.file(`${claimId}_` + (i + 1) + ".jpg", imageBlob, {
                  base64: true
                });

                //code for download  additionalImages
                // if(i>=2){
                // const imageBlob = fetch(`${cur}`).then(response => response.blob());
                // console.log(imageBlob);
                // zip.file(`${claimId}_` + (i + 1) + ".jpg", imageBlob, {
                //   base64: true
                // });}
              })
              zip.generateAsync({ type: "blob" })
                .then(function (content) {
                  saveAs(content, "downloadImages.zip");
                  setLoading(false);
                  setSaveSuccess(true);
                  setModal(true);
                });
            }}
          /><simpleLoader style={{ width: 30, height: 30 }} />
        </div>
      </Box>
      <Modal
        show={modal}
        onHide={() => {
          setSaveSuccess(false);
          setModal(false);
        }}
        centered
        animation
        size="md"
        className="shadow-lg roomps buttondonload"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" />
        </Modal.Header>
        <Modal.Body className="text-center fgd">
          {saveSuccess ? <><h3>Successful download!</h3> <h3>Please check your downloads folder to access your photos</h3></>
            : <h3>Somethin went wrong</h3>}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Photos;
