import React, { useState, Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Table, Pagination, Modal } from 'react-bootstrap';
import { MdSort } from 'react-icons/md';
import { FiEdit } from 'react-icons/fi';
import { BiX } from 'react-icons/bi';
import { deleteClaim, getAllClaim, updateClaim } from '../../../store/actions/allClaim';
import { setActiveClaimPage } from '../../../store/actions/pagination';
import Box from '../../../components/Box/Box';
import PrimaryButton from '../../../components/Buttons/PrimaryButton/PrimaryButton';
import Loader from '../../../components/Loader/Loader';
import { getCredits } from '../../../store/actions/credits';
import { IoIosSearch } from "react-icons/io";
import MuiPagination from '@material-ui/lab/Pagination';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import moment from 'moment';
const AllClaim = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activePagination = useSelector(state => state.pagination.activeClaimPage);
  const auth = useSelector(state => state.auth.isAuthenticated);
  const error = useSelector(state => state.error.error);
  const token = useSelector(state => state.auth.token);
  const isRendered = useSelector(state => state.claims.isRendered);
  const allClaimInitialData = useSelector(state => state.claims.allClaims);
  const [allClaimData, setClaimData] = useState([...allClaimInitialData]);
  const totalClaims = useSelector(state => state.claims.totalClaims);
  const credits = useSelector(state => state.credit.credits);
  const userType = useSelector(state => state.auth.user.userType);
  const [showOrg, setShowOrg] = useState(false);

  const [allClaimActiveClasses, setAllClaimActiveClasses] = useState(
    allClaimData.map(item => false)
  );
  const now = new Date();

  const [editShown, setEditShown] = useState(false);
  const [claimKeyIndex, setClaimKeyIndex] = useState('');
  const [damageType, setDamageType] = useState('');
  const [address, setAddress] = useState('');
  const [notes, setNotes] = useState('');
  const [apt, setApt] = useState('');
  const [city, setCity] = useState('');
  const [customerID, setCustomerId] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [date, setDate] = useState('');
  const [modalShown, setModalShown] = useState(false);
  const [sucmodalShown, setsucModalShown] = useState(false);
  const [modalErrorText, setModalErrorText] = useState('');
  const [removeClaimId, setRemoveClaimId] = useState('');
  const [types, setTypes] = useState({});
  const [lastIndex, setLastIndex] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [startd, setStartd] = useState(0);
  const [endd, setEndd] = useState(10);
  const [startDate, setStartDate] = React.useState(moment().subtract(6, 'months').toDate());
  const [endDate, setEndDate] = React.useState(moment().toDate());

  const handleDateChange = (date, type) => {
    if (type === 'start') setStartDate(date);
    if (type === 'end') setEndDate(date);
  };

  const handleHide = () => setsucModalShown(false);

  useEffect(() => {
    if (!credits && auth && token) {
      setTimeout(() => {
        dispatch(getCredits());
      }, 1000);
    }
  }, [credits, auth, token]);

  useEffect(() => {
    setClaimData(allClaimInitialData);
  }, [allClaimInitialData]);

  useEffect(() => {
    if (totalClaims % 10 === 0) setLastIndex(Math.floor(Number(totalClaims / 10)));
    else setLastIndex(Math.floor(Number(totalClaims / 10)) + 1);
  }, [totalClaims]);

  const GoToRoom = keyIndex => {
    const claim = allClaimData[keyIndex];

    history.push(`/app/allroom/${claim.claimId}/`);
  };

  const handleProperty = () => {

    history.push('/app/newclaim');
    console.log('HERE I GO TO NEW CLAIM');

  };

  const showEditBox = keyIndex => {
    for (let i = 0; i < allClaimActiveClasses.length; i++) {
      allClaimActiveClasses[i] = false;
    }

    allClaimActiveClasses[keyIndex] = true;
    const claim = allClaimData[keyIndex].claimInfo;
    console.log(claim);
    setClaimKeyIndex(keyIndex);
    setDamageType(claim.damageType);
    setAddress(claim.address1);
    setNotes(claim.notes);
    setApt(claim.address2);
    setCity(claim.city);
    setState(claim.state);
    setZip(claim.zip);
    setCustomerId(claim.customerID)
    if (claim.claimDate) setDate(new Date(claim.claimDate).toLocaleString('en-US'));
    setEditShown(true);
  };

  const hideEditBox = () => {
    setAllClaimActiveClasses(allClaimData.map(item => false));
    setClaimKeyIndex('');
    setDamageType('');
    setAddress('');
    setNotes('');
    setApt('');
    setCity('');
    setState('');
    setZip('');
    setDate('');
    setCustomerId('');
    setEditShown(false);
  };

  const updateProperty = async () => {
    const claim = allClaimData[claimKeyIndex];
    claim.claimInfo = {
      ...claim.claimInfo,
      damageType,
      address1: address,
      address2: apt,
      city,
      state,
      zip,
      customerID,
      notes,
    };

    await dispatch(updateClaim(claim, customerID));
    hideEditBox();
    setsucModalShown(true);
  };

  const ShowRemovePopup = (keyIndex, claimId) => {
    allClaimActiveClasses[keyIndex] = true;
    setRemoveClaimId(claimId);
    setModalErrorText('Are you sure? You want to remove?');
    setModalShown(true);
  };

  const RemoveClaim = () => {
    dispatch(deleteClaim(removeClaimId));
    //const newList = allClaimData.filter(item => item.claimId !== removeClaimId);
    //setClaimData(newList);
    hideEditBox();
    setModalShown(false);
  };
  const onOpened = e => {
    e.component._popup.option('position', {
      my: 'center',
      at: 'center',
      of: window,
    });
  };
  const onValueChanged = e => {
    setDate(e.value);
  };

  const hideModel = () => {
    setAllClaimActiveClasses(allClaimData.map(item => false));
    setModalShown(false);
  };

  const handleSort = async type => {
    let newData = [...allClaimData];
    if (types[type]) {
      console.log(type)
      if (type === 'date') {
        newData = newData.sort(
          (a, b) => new Date(b.timeInitiated * 1000) - new Date(a.timeInitiated * 1000)
        );
        setClaimData(newData);
      }
      if (type === 'damage') {
        newData = newData.sort((a, b) =>
          a.claimInfo.damageType <= b.claimInfo.damageType ? 1 : -1
        );
        setClaimData(newData);
      }
      if (type === 'address') {
        newData = newData.sort((a, b) =>
          a.claimInfo.address1 <= b.claimInfo.address1 ? 1 : -1
        );
        setClaimData(newData);
      }
      if (type === 'customerid') {
        console.log(newData)
        newData = newData.sort((a, b) => (a.customerID <= b.customerID ? 1 : -1));
        setClaimData(newData);
      }
      if (type === 'status') {
        newData = newData.sort((a, b) => (a.status <= b.status ? 1 : -1));
        setClaimData(newData);
      }
      setTypes({ ...types, [type]: false });
      return;
    }
    if (type === 'date') {

      newData = newData.sort(
        (a, b) => new Date(a.timeInitiated * 1000) - new Date(b.timeInitiated * 1000)
      );
      setClaimData(newData);
    }
    if (type === 'damage') {
      newData = newData.sort((a, b) => (b.claimInfo.damageType <= a.claimInfo.damageType ? 1 : -1));
      setClaimData(newData);
    }
    if (type === 'status') {
      newData = newData.sort((a, b) => (b.status <= a.status ? 1 : -1));
      setClaimData(newData);
    }
    if (type === 'customerid') {
      newData = newData.sort((a, b) => (b.customerID <= a.customerID ? 1 : -1));
      setClaimData(newData);
    }
    setTypes({ ...types, [type]: true });
  };
  const handlePagination = async page => {
    if (activePagination !== page) {
      var check = document.getElementById("checkSearch").checked;
      const into10 = (page - 1) * 10;
      setStartd(into10);
      setEndd(into10 + 10);
      const stats = { start: into10, end: into10 + 10, search: searchText, searchByOrg: check };
      localStorage.setItem('stats', stats);
      const allClaimss = await dispatch(getAllClaim(stats));
      dispatch(setActiveClaimPage(page));
    }
  };
  const handleSearch = async () => {
    handlePagination(1);
    console.log(startDate, endDate);
    const stats = { start: 0, end: 10, search: searchText, searchByOrg: showOrg, startTimestamp: startDate.getTime() / 1000, endTimestamp: endDate.getTime() / 1000 };
    await dispatch(getAllClaim(stats));
  };
  const usaStates = [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
  ];
  if ((!isRendered && userType == "AppUser")) return <Loader type="half" />;

  return (
    <Fragment>
      <div id="allclaim">
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={Boolean(error)}
          autoHideDuration={6000}
          message={error}
        >
          <Alert elevation={6} onClose={() => dispatch({ type: 'HIDE_ERROR' })} severity="error">
            {error}
          </Alert>
        </Snackbar>
        <Row>
          <Col sm={12} md={12} lg={3}>
            {editShown ? (
              <div id="editSection" className="fixedLeft_a">
                <Box className="info_box " type="noheader">
                  <div className="closeBtn">
                    <BiX size={25} onClick={hideEditBox} style={{ cursor: 'pointer' }} />
                  </div>
                  <div className="info_title">
                    <div className="completeaddress">
                      <div className="input-box-Div ">
                        <label htmlFor="address1" className="input-labels">
                          Street Address
                        </label>
                        <input
                          value={address}
                          onChange={e => setAddress(e.target.value)}
                          size="30"
                          type="text"
                          className="input-text"
                          id="address1"
                        />
                      </div>
                      <div className="input-box-Div ">
                        <label htmlFor="address2" className="input-labels">
                          Apt/Suite (optional)
                        </label>
                        <input
                          value={apt}
                          onChange={e => setApt(e.target.value)}
                          size="30"
                          type="text"
                          className="input-text"
                          id="address2"
                        />
                      </div>
                      <div className="input-box-Div">
                        <label htmlFor="city" className="input-labels">
                          City/State/ZIP
                        </label>
                        <input
                          value={city}
                          onChange={e => setCity(e.target.value)}
                          size="30"
                          type="text"
                          placeholder="Sacramento"
                          className="input-text"
                          id="city"
                        />
                      </div>
                      <div className="text-center">
                        <Row>
                          <Col sm={12} md={6} className="mt-1">
                            <select
                              className="selectcity"
                              value={state}
                              onChange={e => setState(e.target.value)}
                            >
                              {usaStates.map(stateVal => (
                                <option value={stateVal}>{stateVal}</option>
                              ))}
                            </select>
                          </Col>
                          <Col sm={12} md={6} className="mt-1">
                            <input
                              value={zip}
                              onChange={e => setZip(e.target.value)}
                              size="13"
                              type="text"
                              placeholder="02145"
                              className="input-text new-input"
                              id="countryCode"
                            />
                          </Col>
                        </Row>
                      </div>
                      <div className="input-box-Div">
                        <label htmlFor="customerid" className="input-labels">
                          Claim ID (optional)
                        </label>
                        <input
                          value={customerID}
                          onChange={e => setCustomerId(e.target.value)}
                          size="30"
                          type="text"
                          placeholder="Type your Claim ID Here"
                          className="input-text"
                          id="customerID"
                        />
                      </div>
                      <div className="input-box-Div ">
                        <label htmlFor="notes" className="input-labels">
                          Notes
                        </label>
                        <input
                          value={notes}
                          onChange={e => setNotes(e.target.value)}
                          size="30"
                          type="text"
                          placeholder="Enter Notes"
                          className="input-text notes_txt"
                          id="address2"
                        />
                      </div>
                      <div className="input-box-Div ">
                        <Col sm={12} md={12} className="mt-3 text-center">
                          <PrimaryButton
                            className="d-inline-block"
                            title="Save"
                            onClick={updateProperty}
                          />
                        </Col>
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
            ) : (
              <div id="btnSection" className="fixedLeft">
                {userType == 'AppUser' ? (
                  <Col sm={12} md={12} lg={12} className="mt-3">
                    <PrimaryButton
                      className="new_claims_btn"
                      title="Add Property"
                      onClick={handleProperty}
                    />
                    {credits < 1 ? (
                      <div className="btn_hover_text">
                        <p>Additional credits required. Contact info@hostalabs.com</p>
                      </div>
                    ) : null}
                  </Col>
                ) : null}

              </div>
            )}
          </Col>
          <Col sm={12} md={12} lg={8} className="mt-4 margin-t">
            <div id="ClaimsTable">
              <Table responsive className="table-curved" role="grid">
                <thead>
                  <tr>
                    <th style={{ width: "5%" }} />
                    <th style={{ width: "10%" }} />
                    <th colSpan="4" style={{ width: "42%", paddingRight: 0 }}>
                      <div className="search-text-main">
                        {userType == 'AppUser' ? (
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <PrimaryButton
                              className="searchBtn"
                              id="searchBtn"
                              title={<IoIosSearch color="#ffffff" size="24" />}
                              onClick={() => { setSearchText(searchText); handleSearch(searchText) }}
                            />
                            <input
                              id="searchInp"
                              size="42"
                              type="text"
                              placeholder="Search here"
                              className="search-text"
                              value={searchText}
                              onChange={(e) => { setSearchText(e.target.value) }}
                              onKeyPress={(event) => {
                                if (event.key == "Enter") {
                                  setSearchText(searchText); handleSearch(searchText)
                                }
                              }}
                            />
                            {searchText ?
                              <BiX size={30} color="#415878" onClick={() => { setSearchText(""); handleSearch("") }} style={{ cursor: 'pointer' }} />
                              : null}
                          </div>
                        ) : null}

                      </div>
                      {userType == 'AppUser' ? (
                        <div style={{ display: "flex", marginTop: 15 }} className="round">
                          <input type="checkbox" id="checkSearch" className="checkSearch" style={{ height: 18, width: 18 }} onChange={() => setShowOrg(!showOrg)} />
                          <label htmlFor="checkSearch" className="label"></label>
                          <label htmlFor="checkSearch" style={{ marginLeft: 22, marginTop: 5 }} >Show results from organization</label>
                        </div>
                      ) : null}
                      {showOrg && userType == 'AppUser' && (
                        <>
                          <div style={{ display: 'flex', gap: 5, alignItems: 'baseline' }}>
                            <KeyboardDatePicker
                              label="Date From"
                              format="MM/dd/yyyy"
                              margin="normal"
                              value={startDate}
                              onChange={e => handleDateChange(e, 'start')}
                            />
                            <KeyboardDatePicker
                              label="Date To"
                              format="MM/dd/yyyy"
                              margin="normal"
                              value={endDate}
                              onChange={e => handleDateChange(e, 'end')}
                            />
                            <Button disabled={!moment(startDate).isValid() || !moment(endDate).isValid()} style={{ backgroundColor: moment(startDate).isValid() && moment(endDate).isValid() ? '#6a9eea' : 'lightgray', color: 'white' }} variant='contained' onClick={handleSearch}>Search</Button>
                        </div>
                        <small style={{color: 'gray'}}>Select Date Range up to 6 months</small>
                        </>
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allClaimData?.map((room, i) => (
                    <tr key={room.claimId}>
                      <td
                        className={allClaimActiveClasses[i] ? 'activeTD' : null}
                        style={{ verticalAlign: 'middle' }}
                      >
                        {!allClaimActiveClasses[i] ? (
                          <FiEdit size={16} onClick={() => showEditBox(i)} />
                        ) : null}
                      </td>
                      <td
                        className={allClaimActiveClasses[i] ? 'activeTD' : null}
                        role="gridcell"
                        onClick={() => GoToRoom(i)}
                      >
                        <b>Claim ID:</b>
                        <br />
                        <p>{room.claimInfo.customerID}</p>
                      </td>
                      <td
                        className={allClaimActiveClasses[i] ? 'activeTD' : null}
                        role="gridcell"
                        onClick={() => GoToRoom(i)}
                      >
                        <b>Address:</b>
                        <br />
                        <p>
                          {room.claimInfo.address2 ? `${room.claimInfo.address2}, ` : ''}
                          {room.claimInfo.address1},&nbsp;
                          {room.claimInfo.city},&nbsp;{room.claimInfo.state},&nbsp;{room.claimInfo.country}
                          ,&nbsp;
                          {room.claimInfo.zip}
                        </p>
                      </td>
                      <td
                        className={allClaimActiveClasses[i] ? 'activeTD' : null}
                        role="gridcell"
                        onClick={() => GoToRoom(i)}
                      >
                        <b>Initiated on:</b>
                        <br />
                        <p>{new Date(room.timeInitiated * 1000).toLocaleString('en-US')}</p>
                      </td>
                      <td
                        className={allClaimActiveClasses[i] ? 'activeTD' : null}
                        style={{ width: '1%' }}
                      >
                        {!allClaimActiveClasses[i] ? (
                          <BiX size={25} onClick={() => ShowRemovePopup(i, room.claimId)} />
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            {lastIndex > 1 && <MuiPagination variant="outlined" color='primary' shape="rounded" showFirstButton showLastButton onChange={(evt, page) => handlePagination(page)} count={lastIndex} />}
            {/* {lastIndex > 1 ? (
              <div id="ClaimsPagination">
                <Pagination>
                  <Pagination.First active={false} onClick={() => handlePagination(1)} />
                  <Pagination.Prev
                    active={false}
                    onClick={() => handlePagination(activePagination - 1)}
                  />
                  {[...Array(lastIndex)].map((i, number) => (
                    <Pagination.Item
                      key={`allcalim_paginate_${number + 1}`}
                      onClick={() => handlePagination(number + 1)}
                      active={number + 1 === activePagination}
                    >
                      {number + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    active={false}
                    onClick={() => handlePagination(activePagination + 1)}
                  />
                  <Pagination.Last active={false} onClick={() => handlePagination(lastIndex)} />
                </Pagination>
              </div>
            ) : null} */}
          </Col>
        </Row>
      </div>

      <Modal
        show={sucmodalShown}
        onHide={handleHide}
        centered
        animation
        size="md"
        className="shadow-lg roomdd"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" />
        </Modal.Header>
        <Modal.Body className="text-center p-5">
          <h3 className="m-0">Property Updated Successfully</h3>
        </Modal.Body>
        <Modal.Footer>
          <div className="m-3" />
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalShown}
        onHide={() => hideModel()}
        centered
        animation
        size="md"
        className="shadow-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" />
        </Modal.Header>
        <Modal.Body className="text-center">
          <h3>{modalErrorText}</h3>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <div>
            <PrimaryButton
              className="new_claims_btn float-left mr-3"
              title="Remove"
              onClick={() => RemoveClaim()}
            />
            <PrimaryButton className="new_claims_btn" title="Cancel" onClick={() => hideModel()} />
          </div>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default AllClaim;







