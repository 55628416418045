import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Select = props => {
  const { className, options, variant } = props;
  const selectProps = { ...props };
  delete selectProps.className;
  delete selectProps.options;
  delete selectProps.variant;

  return (
    <select
      id="custom_select"
      className={classNames('custom_select', {
        [className]: className,
        'custom_select--sm': variant === 'sm',
        'custom_select--md': variant === 'md',
        'custom_select--lg': variant === 'lg',
      })}
      {...selectProps}
    >
      {options.map((cur, i) => (
        <option key={i} value={cur}>
          {cur}
        </option>
      ))}
    </select>
  );
};

Select.defaultProps = {
  className: '',
};

Select.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Select;
