import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import NewPortalContext from '../../new-portal-context/NewPortalContext';
import ShowMeTheTips from '../../new-portal-images/Three_Tips/ShowMeTheTips.png';
import SkipTheInstructions from '../../new-portal-images/Three_Tips/SkipTheInstructions.png';
import NewModal from '../../new-portal-components/NewModal/NewModal';
import ModalBody from '../../new-portal-components/ModalBody/ModalBody';
import SecondaryHeadline from '../../new-portal-components/SpanHeadlines/SecondaryHeadline';

const CombinedContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem;

  @media (min-width: 768px) {
    height: 33rem;
  }
`;

const TipsBaseButton = styled.button`
  width: 100%;
  height: 7rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
  border: none;
  border-radius: 16px;
  font-family: 'Work Sans';
  font-size: 1.1rem;
  font-weight: 700;

  @media (min-width: 768px) {
    width: 70%;
    height: 6.5rem;
    font-size: 1.4rem;
  }
`;

const TipsHeadline = styled.p`
  font-size: 1.7rem;
  font-weight: 680;
  margin-bottom: 4rem;
  line-height: normal;
  
  @media (min-width: 768px) {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 4rem;
  }
`;

const TipsButtonTop = styled(TipsBaseButton)`
  background-image: linear-gradient(81.87deg, #2C5085 -17.77%, rgba(106, 169, 255, 0) 260.11%);
  color: #ffffff;
  margin-bottom: 1rem;
  &:active {
    background-color: #aac7f2;
  }

  @media (min-width: 768px) {
    text-align: center;
`;

const TipsButtonBottom = styled(TipsBaseButton)`
  background-color: #ffffff;
  border: 1px solid #6A9EEA;
    color: #6A9EEA;
  margin-bottom: 2rem;
  &:active {
    background-color: #e9ebf0;
  }
`;

const TipsImg = styled.img`
  width: 3.75rem;
  height: 3.75rem; 
  margin-left: 1.2rem;
`;

const TipsTextContainer= styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TipsTextSpan = styled.span`
  margin-left: 1.2rem;
`;

const Three_Tips = () => {
  const history = useHistory();

  const { returnUrlPath } = useContext(NewPortalContext);
  const moveToNextPage = (routeName) => history.push(`${routeName}`);
  //props for threeTips & open close modal view
  const smallText = 'Here are some tips to take great photos';
  const [ modalState, toggleModal ] = useState(false);
  //props to open modalBody
  const switchModal = () => toggleModal(!modalState);
  //props to display photoProps or errorProps modal screen
  const [ scrnOneOrTwo, toggleScrnOneOrTwo ] = useState(false);
  const toggleScreen = () => toggleScrnOneOrTwo(!scrnOneOrTwo);
  //props for ModalBody
  const capturePhotoProps = {
    modalHeadline: 'Add Photo',
    modalImage: 'takePhoto',
    modalSecondaryText: 'Add a photo with your camera, from your photo library or files on your phone'
  };
  //props for ModalBody
  const errorProps = {
    modalHeadline: 'Sorry, thats the wrong format',
    modalImage: 'photoError', 

  }
 
  return (
    <CombinedContainer>
      {/* Commented code below is for modal for camera activation--wip */}
      {/* Not necessarily needed here, just bootstrapped off of Threee_Tips as starting point */}
      {/* <NewModal modalState={modalState}>
        {/* >>>ModalBody is props.children inside NewModal*/}
        {/* <ModalBody 
          closeModal={switchModal}
          modalProps={scrnOneOrTwo ? capturePhotoProps : errorProps}
          buttonOneText={'Add photo'}
          buttonOneCallback={toggleScreen}> */}
          {/* >>>SecondaryHeadline is props.children inside modalBody */}
          {/* <SecondaryHeadline text={'Available file formats:'} />
        </ModalBody>
      </NewModal> */}
      <TipsHeadline>{smallText}</TipsHeadline>
      <TipsButtonTop onClick={()=>moveToNextPage('/tipsInstructions')}>
        <TipsImg src={ShowMeTheTips}></TipsImg>
        <TipsTextContainer>
          <TipsTextSpan>Yes!</TipsTextSpan>
          <TipsTextSpan>Show me the tips</TipsTextSpan>
        </TipsTextContainer>
      </TipsButtonTop>
      <TipsButtonBottom onClick={()=>moveToNextPage(sessionStorage.returnUrlPath)}>
        <TipsImg src={SkipTheInstructions}></TipsImg>
        <TipsTextContainer>
          <TipsTextSpan>Nope</TipsTextSpan>
          <TipsTextSpan>Skip the instructions</TipsTextSpan>
        </TipsTextContainer>
      </TipsButtonBottom>
    </CombinedContainer>
  );
};

export default Three_Tips;