import React, { useState } from 'react';
import { FiChevronRight, FiEdit } from 'react-icons/fi';
import PropTypes from 'prop-types';

const Breadcrumb = ({ step1Onclick, data }) => {
  const [editBoxShown, setEditBoxShown] = useState(false);
  return (
    <div id="custom-breadcrumb">
      {!editBoxShown ? (
        <div id="custom-breadcrumb">
          <span
            className="breadcrumb_parent"
            onClick={step1Onclick}
            onKeyDown={step1Onclick}
            role="button"
            tabIndex="0"
          >
            All Property
          </span>
          <span
            className="breadcrumb_title"
            onClick={() => setEditBoxShown(pass => pass)}
            onKeyDown={() => setEditBoxShown(pass => pass)}
            role="button"
            tabIndex="0"
          >
            <FiChevronRight size={35} color="#537BB8" />
            {data.name}
            <FiChevronRight size={35} color="#537BB8" />
            All Rooms
          </span>
        </div>
      ) : (
        <div className="form_group" id="form_group">
          <div className="input_wrapper col-md-4 col-sm-12 p-0">
            <input
              id={data.id}
              type="text"
              value={data.name}
              className="col-sm-12 col-md-12 variable_input variable_input--sm variable_input--4"
              placeholder="Enter Title"
            />
            <div className="input_inner">
              <FiEdit
                color="#8282828E"
                size={20}
                style={{ cursor: 'pointer' }}
                onClick={() => setEditBoxShown(pass => !pass)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Breadcrumb.defaultProps = {
  data: {},
};

Breadcrumb.propTypes = {
  step1Onclick: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default Breadcrumb;
