import { combineReducers } from 'redux';
import AuthReducer from './auth';
import ClaimReducer from './claims';
import CreditReducer from './credits';
import PaginationReducer from './pagination';
import ErrorReducer from './errors';

const rootReducer = combineReducers({
  auth: AuthReducer,
  claims: ClaimReducer,
  credit: CreditReducer,
  pagination: PaginationReducer,
  error: ErrorReducer
});

export default rootReducer;
