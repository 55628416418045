import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Carousel as ReactCarousel, Modal } from 'react-bootstrap';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import Loader from '../../../components/Loader/Loader';
import { getRoom, getClaim } from '../../../store/actions/roomInterface';
import { isObjEmpty } from '../../../utils/utils';
import Box from '../../../components/Box/Box';
import PrimaryButton from '../../../components/Buttons/PrimaryButton/PrimaryButton';
import simpleLoader from "../../../assets/images/simpleLoader.gif";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import InnerImageZoom from 'react-inner-image-zoom';
import { Divider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

const ProcessingRoomInterface = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { claimId, roomId } = params;
  console.log(roomId)
  const allClaims = useSelector(state => state.claims.allClaims);
  console.log(allClaims)
  const [claimData, setClaimData] = useState(allClaims.find(cur => cur.claimId === claimId));
  const [roomData, setRoomData] = useState(claimData?.rooms || {});
  const [interfaceData, setInterfaceData] = useState({});
  const [isRendered, setRendered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [preview, setPreview] = useState(false);
  const [selectedPhotosIndex, setSelectedPhotosIndex] = useState(carouselIndex);
  const [hasError, setHasError] = useState(null);

  const isBeforeProcess = roomData?.imagesToProcess?.length === 0;

  const additionalImages_links = ["https://hosta-input-images.s3.amazonaws.com/e78aa642-5f35-4b9d-8d85-e7ea7fec1779.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAXPOPDSGN7LUTWQSG%2F20210903%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20210903T093446Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEOH%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMiJHMEUCIGostSdrc1DIGro0umRDf7%2FrDWsDwchK2tpk1wIat%2BbjAiEAgHEFUPmp5q1JQSE5QvuikZzgB0XVIPGMzL3uNClqnqIqjAIIKxABGgw1MTQyMTk4MDcxMzEiDEyHsRt%2FkuBY4NwCeSrpAV%2Fh7VaG6QoOoJPvq0kUiEaeLwwxBVkNkC4ri8AfXbZYVKPX%2BIQAM61mXxao3AGdwEyFeLpiZdbZhwu3ra2OVsOBecG9mbyXBauMt26SdERXDBltv1v4gZxr8XfZTF3sqYuDxg4rSGP2DnnFK%2B%2FYKoPZ6%2F3MXBKG1eXCvD86tyQ4wKEeUSsowmT1KYO0LDW4ahhaKTjM9BdsDPlaIqzwbR70ABOtcOUYsRJtpI43nuYjsYQr6ijV9hLelCwtlQWMyxqfsLh2FQuh8Ad3jp9C8TIAyMXPDEjMDd%2BTPJRwTJf6kT2G49roi6kWMJXWx4kGOpoBz7NwUOETWx9MJ8yMoZwKgtaNVTvOK45ocC0eFLtgs7vog5eKSEtBQEPqsuy6OveciK%2F0or05vA07K68TRXFegbz3XPQh79W349T6CCE3GTkg2cT5DvlKYVsUDO7%2BbI2UigUe4COIJH64oORi5DeIqrOYwkArdM%2F6%2FNjZJckJ7P527EWDR%2BPYm7ev2jxc0WgYvnoKrT0otWphEw%3D%3D&X-Amz-Signature=7918708c56f921f5720be23b73510f1bc68e32403d5898f71b7f31b84928bd9c",
    "https://hosta-input-images.s3.amazonaws.com/d7bcfb04-d8ed-4ffe-ae27-4a6813f13cc0.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAXPOPDSGN7LUTWQSG%2F20210903%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20210903T093446Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEOH%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMiJHMEUCIGostSdrc1DIGro0umRDf7%2FrDWsDwchK2tpk1wIat%2BbjAiEAgHEFUPmp5q1JQSE5QvuikZzgB0XVIPGMzL3uNClqnqIqjAIIKxABGgw1MTQyMTk4MDcxMzEiDEyHsRt%2FkuBY4NwCeSrpAV%2Fh7VaG6QoOoJPvq0kUiEaeLwwxBVkNkC4ri8AfXbZYVKPX%2BIQAM61mXxao3AGdwEyFeLpiZdbZhwu3ra2OVsOBecG9mbyXBauMt26SdERXDBltv1v4gZxr8XfZTF3sqYuDxg4rSGP2DnnFK%2B%2FYKoPZ6%2F3MXBKG1eXCvD86tyQ4wKEeUSsowmT1KYO0LDW4ahhaKTjM9BdsDPlaIqzwbR70ABOtcOUYsRJtpI43nuYjsYQr6ijV9hLelCwtlQWMyxqfsLh2FQuh8Ad3jp9C8TIAyMXPDEjMDd%2BTPJRwTJf6kT2G49roi6kWMJXWx4kGOpoBz7NwUOETWx9MJ8yMoZwKgtaNVTvOK45ocC0eFLtgs7vog5eKSEtBQEPqsuy6OveciK%2F0or05vA07K68TRXFegbz3XPQh79W349T6CCE3GTkg2cT5DvlKYVsUDO7%2BbI2UigUe4COIJH64oORi5DeIqrOYwkArdM%2F6%2FNjZJckJ7P527EWDR%2BPYm7ev2jxc0WgYvnoKrT0otWphEw%3D%3D&X-Amz-Signature=938b5033c5c182264dee3268be5e8e4dbc557f38038e2198ee82c2ba269250cc"]
  let photos = roomData.images_links ? roomData.images_links.concat(roomData.additionalImages_links) : roomData.additionalImages_links;
  if (isBeforeProcess) {
    photos = roomData.overviewImages_links
  }
  console.log("photos", photos);
  photos = (photos || []).concat(roomData?.damageImages_links || []).concat(roomData?.platformImages_links || [])
  useEffect(() => {
    (async () => {
      await roomData;
      await interfaceData;
      if (!isRendered) {

        if (!Array.isArray(roomData) && !roomData.interface) {
          console.log(`roomId ${roomId}`)
          const res = await dispatch(getRoom(claimId, roomId));
          if (res?.error) {
            setHasError(res.error?.response?.data || 'Unable to view room. Try again later.');
            return;
          }
          const res1 = await dispatch(getClaim(claimId));
          setClaimData(res1);
          setInterfaceData(res);
          setRoomData(res);
        } else {
          const orgRoom = roomData?.find(cur => cur.roomId === roomId);
          setRoomData(orgRoom);
          if (orgRoom.interface) {
            setInterfaceData(orgRoom.interface);
          } else {
            const res = await dispatch(getRoom(claimId, roomId));
            setInterfaceData(res);
          }
        }
      }
      if (!isObjEmpty(roomData) && !isObjEmpty(interfaceData) && claimData) {
        setRendered(true)
      }
    })();
  }, [roomData]);

  const handlePhotosCarouselSlide = e => {
    setCarouselIndex(e);
  };
  const onNextPhotos = () => {
    setSelectedPhotosIndex((selectedPhotosIndex + 1) % photos.length);
  };
  const onPrevPhotos = () => {
    setSelectedPhotosIndex(Math.abs(selectedPhotosIndex - 1) % photos.length);
  };

  useEffect(() => {
    setCarouselIndex(carouselIndex);
    setSelectedPhotosIndex(carouselIndex)
  }, [carouselIndex]);

  const handleBack = () => {
    history.push(`/app/allroom/${claimId}`);
  }

  console.log("Check Has Error", hasError, isRendered)

  if (hasError) {
    return <Dialog open={Boolean(hasError)} onClose={handleBack}>
      <DialogContent>
        <h6>{hasError}</h6>
      </DialogContent>
      <DialogActions>
        <PrimaryButton
          className="new_claims_btn float-left mr-3"
          title="Ok"
          onClick={handleBack}
        />
      </DialogActions>
    </Dialog>
  }

  if (!isRendered) return <Loader type="half" />;
  return (
    <>
      {(claimData.claimInfo.customerID !== undefined && claimData.claimInfo.customerID !== "") &&
        <p className="claimIDHeader">Claim ID: {claimData.claimInfo.customerID}</p>
      }
      <div id="room-interface">
        <div className="interface_top">
          <span className="dd" role="none" onClick={() => history.push(`/app/allroom/${claimId}/`)}>
            {claimData.claimInfo.address2 ? `${claimData.claimInfo.address2}, ` : ''}
            {claimData.claimInfo.address1},&nbsp;
            {claimData.claimInfo.city},&nbsp;
            {claimData.claimInfo.state},&nbsp;{claimData.claimInfo.country},&nbsp;
            {claimData.claimInfo.zip}
          </span>
          <FiChevronRight size={30} color="#537BB8" />
          <span className="breadcrumb_title">{roomData.roomName}</span>
        </div>
        <Row className="room_interface_container">
          <Col className="carousel_room_interface" lg={6}>
            {/* <Carousel carousel={carousel} setCarousel={setCarousel} /> */}
            <div id="interface-carousel">
              <div className="carousel_TAB"
                style={{ width: "100%", backgroundColor: "#6895eb", color: "#ffffff", borderTopLeftRadius: 15, borderTopRightRadius: 15, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <h3 style={{ margin: "1%" }}>Photos</h3>
              </div>
              <ReactCarousel
                className="bootstrap_carousel"
                interval={null}
                prevIcon={<FiChevronLeft color="#8282828E" size={50} />}
                nextIcon={<FiChevronRight color="#8282828E" size={50} />}
                onSelect={handlePhotosCarouselSlide}
                activeIndex={carouselIndex}
              >
                {roomData ?
                  photos.map((cur) => (
                    <ReactCarousel.Item>
                      <img
                        className="carousel_image"
                        src={cur}
                        responsive
                      />
                    </ReactCarousel.Item>
                  ))
                  : null}
              </ReactCarousel>
              <div className="preview-btn">
                <PrimaryButton
                  className="new_claims_btn float-left mr-3"
                  title="Zoom"
                  onClick={() => setPreview(true)}
                />
              </div>
            </div>
          </Col>
          <Col className="details_room_interface" lg={6}>
            <div id="interface-photos">
              <Box type="header" title="All Images" className="photos_box">
                <h4>Overview Images {isBeforeProcess && <small>(Before Processing)</small>}</h4>
                {roomData ?
                  photos.map((cur, i) => {
                    if ([...roomData?.images_links, ...roomData?.additionalImages_links].includes(cur) || (isBeforeProcess && roomData?.overviewImages_links?.includes(cur)))
                      return <img
                        src={cur}
                        style={carouselIndex === i ? { cursor: "pointer", width: "23%", height: "23%", margin: "1%" } : { opacity: 0.5, cursor: "pointer", width: "23%", height: "23%", margin: "1%" }}
                        onClick={() => { setCarouselIndex(i) }}
                        responsive
                      />
                    return <></>
                  }) : null}
                <Divider />
                {roomData?.damageImages_links.length > 0 && <><h4>Damage Images</h4>
                  {roomData ?
                    photos.map((cur, i) => {
                      if (roomData?.damageImages_links.includes(cur))
                        return <img
                          src={cur}
                          style={carouselIndex === i ? { cursor: "pointer", width: "23%", height: "23%", margin: "1%" } : { opacity: 0.5, cursor: "pointer", width: "23%", height: "23%", margin: "1%" }}
                          onClick={() => { setCarouselIndex(i) }}
                          responsive
                        />
                      return <></>
                    }) : null}
                </>}
                {roomData?.platformImages_links.length > 0 && <><h4>Additional Images</h4>
                  {roomData ?
                    photos.map((cur, i) => {
                      if (roomData?.platformImages_links.includes(cur))
                        return <img
                          src={cur}
                          style={carouselIndex === i ? { cursor: "pointer", width: "23%", height: "23%", margin: "1%" } : { opacity: 0.5, cursor: "pointer", width: "23%", height: "23%", margin: "1%" }}
                          onClick={() => { setCarouselIndex(i) }}
                          responsive
                        />
                      return <></>
                    }) : null}
                </>}
                <div className="btn_wrapper" style={{ display: "flex", justifyContent: "flex-end" }}>
                  <PrimaryButton
                    title={loading ? <img src={simpleLoader} style={{ width: 20, margin: "0px 50px" }} /> : "Download All"}
                    className="photos_button"
                    onClick={() => {
                      setLoading(true);
                      var zip = new JSZip();
                      photos.forEach((cur, i) => {
                        var date = new Date();
                        //uncomment below code for 4 image downoads
                        const imageBlob = fetch(`${cur}`, { method: "GET", headers: { "Cache-Control": 'no-cache' } }).then(response => response.blob());
                        console.log(imageBlob);
                        zip.file(`${claimId}_` + (i + 1) + ".jpg", imageBlob, {
                          base64: true
                        });
                      })
                      zip.generateAsync({ type: "blob" })
                        .then(function (content) {
                          saveAs(content, "downloadImages.zip");
                          setLoading(false);
                          setSaveSuccess(true);
                          setModal(true);
                        });
                    }}
                  /><simpleLoader style={{ width: 30, height: 30 }} />
                </div>
              </Box>
            </div>
          </Col>
        </Row>
        <Modal
          onHide={() => {
            setPreview(false);
            setSelectedPhotosIndex(carouselIndex);
          }}
          contentClassName="preview-img-modal modelzoomeffect"
          show={preview}
          centered
          animation
          size="md"
          className="shadow-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" />
          </Modal.Header>
          <div onClick={onPrevPhotos} className="arrow arrow-left">
            <FiChevronLeft className="arrow-icon" color="#ffffff" />
          </div>
          <InnerImageZoom
            className="image-wrapper"
            src={photos[selectedPhotosIndex]}
            zoomSrc={photos[selectedPhotosIndex]}
          />
          <div onClick={onNextPhotos} className="arrow arrow-right">
            <FiChevronRight className="arrow-icon" color="#ffffff" />
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ProcessingRoomInterface;
