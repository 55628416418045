import React from 'react';
import styled from 'styled-components';
import imgOne from '../../new-portal-images/One_Combined_V2/imgOne.png';
import imgTwo from '../../new-portal-images/One_Combined_V2/imgTwo.png';
import imgThree from '../../new-portal-images/One_Combined_V2/imgThree.png';


const PhotoMapContainer = styled.div`
  @media (min-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const OneStep = styled.div`
  height: 5.7rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 1rem;
  border-radius: 17px;
  font-size: .95rem;  
  color: #616F83;
  background-color: #E5EFFF;
  
  @media (min-width: 768px) {
    width: 70%;
    height: 8rem;
    font-size: 1.05rem;  
    margin-top: 1rem;  
  }
`;

const MapperNumber = styled.span`
  font-weight: 600;
  margin-left: 1rem;
  margin-right: .7rem;
  
  @media (min-width: 768px) {
    margin-left: 1.5rem;
  }
`;

const MapperText = styled.p`
  width: 9.75rem;
  font-weight: 400;
  margin-top: 1rem;
 
`;

const MapperImg = styled.img`
  width: 7.5rem;
  height: 5.25rem;
  margin-top: .4rem;

  @media (min-width: 768px) {
    width: 10rem;
    height: 6.5rem;
    margin-top: 1.4rem;
  }
`;

const PhotoMapper = () => {
  const mapArray = [
    {
      mapNum: '1',
      mapText: 'Take a picture of each wall',
      mapImg: imgOne
    },
    {
      mapNum: '2',
      mapText: 'Upload your photos to  Hosta',
      mapImg: imgTwo
    },
    {
      mapNum: '3',
      mapText: 'Hosta will turn your photos into a property data report!',
      mapImg: imgThree
    }
  ];

  return (
    <PhotoMapContainer>
      { mapArray.map(el => ( 
        <OneStep key={el.mapText}>
          <MapperNumber>{el.mapNum}</MapperNumber>
          <MapperText>{el.mapText}</MapperText>
          <MapperImg src={el.mapImg} />
        </OneStep>
      ))}
    </PhotoMapContainer>
  );
};

export default PhotoMapper;
