import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FiEdit } from 'react-icons/fi';

const Input = props => {
  const { className, variant, inputClassName, icon, innerText, combined } = props;
  const inputProps = { ...props };
  delete inputProps.className;
  delete inputProps.variant;
  delete inputProps.inputClassName;
  delete inputProps.icon;
  delete inputProps.innerText;
  delete inputProps.combined;

  return (
    <div
      id="custom-input"
      className={classNames('input_wrapper', {
        [className]: className,
        'input_wrapper--x2s': variant === 'x2s',
        'input_wrapper--xxs': variant === 'xxs',
        'input_wrapper--xs': variant === 'xs',
        'input_wrapper--sm': variant === 'sm',
        'input_wrapper--md': variant === 'md',
        'input_wrapper--lg': variant === 'lg',
        'input_wrapper--full': variant === 'full',
        'input_wrapper--combined': combined,
      })}
    >
      <input
        type="text"
        className={classNames('custom_input', {
          [inputClassName]: inputClassName,
          'custom_input--25': variant === 'xs',
          'custom_input--2': icon,
          'custom_input--4': innerText && icon,
        })}
        onChange={() => {}}
        {...inputProps}
      />
      <div className="input_inner">
        {innerText && <span>{innerText}</span>}
        {icon && <FiEdit color="#717D88F9" size={16} />}
      </div>
    </div>
  );
};

Input.defaultProps = {
  className: '',
  inputClassName: '',
  icon: true,
  innerText: '',
  combined: false,
};

Input.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string.isRequired,
  inputClassName: PropTypes.string,
  icon: PropTypes.bool,
  innerText: PropTypes.string,
  combined: PropTypes.bool,
};

export default Input;
