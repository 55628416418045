import { globalAPICallback } from './UserPool';
import axios, { api } from '../axios';

export const getRoom = (claimId, roomId) => async dispatch =>
  globalAPICallback(async () => {
    try {
      const response = await axios({
        method: 'GET',
        url: `/claim/${claimId}/room/${roomId}`,
      });
      dispatch({
        type: 'GET_ROOM',
        payload: {
          claimId,
          roomId,
          data: response.data,
          error: null
        },
      });
      return response?.data;
    }
    catch (ex) {
      dispatch({
        type: 'GET_ROOM',
        payload: {
          claimId,
          roomId,
          data: null,
          error: ex,
        },
      });
      return { error: ex }
    }
  });

export const getRoomData = claimId => async dispatch =>
  globalAPICallback(async () => {
    const response = await axios({
      method: 'GET',
      url: `/claim/${claimId}/rooms`,
    });
    return response.data;
  });

export const getClaim = claimId => async dispatch =>
  globalAPICallback(async () => {
    const response = await axios({
      method: 'GET',
      url: `/claim/${claimId}`,
    });
    return response.data;
  });

export const getJSONData = JSON => async dispatch => {
  const response = await api(JSON);
  return response.data;
};

export const postAddItem = async (claimId, roomId, data) =>
  globalAPICallback(async () => {
    const response = await axios({
      method: 'POST',
      url: `/claim/${claimId}/room/${roomId}/json`,
      data,
    });
    return response.data;
  });

export const putEditItem = async (claimId, roomId, data) =>
  globalAPICallback(async () => {
    const response = await axios({
      method: 'PUT',
      url: `/claim/${claimId}/room/${roomId}/json`,
      data,
    });
    return response.data;
  });

export const deleteItem = async (claimId, roomId, data) =>
  globalAPICallback(async () => {
    const response = await axios({
      method: 'DELETE',
      url: `/claim/${claimId}/room/${roomId}/json`,
      data,
    });
    return response.data;
  });

export const reassignItem = async (claimId, roomId, data) =>
  globalAPICallback(async () => {
    const response = await axios({
      method: 'PUT',
      url: `/claim/${claimId}/room/${roomId}/json/parent`,
      data,
    });
    return response.data;
  });
