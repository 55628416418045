
import React, { useState, useContext } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IoIosClose } from 'react-icons/io';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton/PrimaryButton';
import { getAllRoom } from '../../../../../store/actions/allRoom';
import { closeLink } from '../../../../../store/actions/linkApi';
import "../../NewRoom.css"
import Submitmg from "../../../../../images/Submitmg.svg";
import NewPortalContext from '../../../../../new-portal-context/NewPortalContext';


const RoomAddedLink = () => {
  const history = useHistory();
  const location = useLocation();
  const { linkId } = useParams();
  const dispatch = useDispatch();

  const [success, setSuccess] = useState(false);

  if (success)
    return (
      <div className="item-center">
        <h1 className="success">Thank You</h1>
      </div>
    );

  return (
    <div className="main-popup-container" id="room-added">
      <div className="popup-container">
        <div
          className="closeImage"
          role="none"
          //X-button to close window
          //X-button to close window
          onClick={() => {
            sessionStorage.removeItem('ssRoomId');
            sessionStorage.removeItem('roomType');
            sessionStorage.removeItem('returnUrlPath');
            if (location.state ?.newLink) {
              //closes LinkId, LinkId is no longer needed
              closeLink(location.state ?.newLink, { roomType: location.state ?.roomType });
              setSuccess(true);
            } else {
              dispatch(getAllRoom(linkId, { start: 0, end: 9 }));
              history.push(`/app/allroom/${linkId}`);
            }
          }}
        >
          <IoIosClose style={{ cursor: 'pointer' }} size={30} color="#316FD0" />
        </div>
        <div className="email-field-new">
          <div className="input-label-new">
            <label htmlFor="reqlink" className="heading-labels">
              All done! Your room is submitted.
            </label>
          </div>
          <div className="content-label-new">
            <img src={Submitmg} />
          </div>
          <div className="reqLinkBtn">

            <PrimaryButton
              className="complete_btn"
              title="Finish!"
              onClick={ () => {
                sessionStorage.removeItem('ssRoomId');
                sessionStorage.removeItem('roomType');
                sessionStorage.removeItem('returnUrlPath');
                if (location.state ?.newLink) {
                  //closes LinkId, LinkId is no longer needed
                  closeLink(linkId, { roomType: location.state ?.roomType }).then(res => {
                    setSuccess(true);
                  });
                } else setSuccess(true);
              }}
            />
            <PrimaryButton
              className="add_another_room"
              title="Add Another Room"
              onClick={() => {
                sessionStorage.removeItem('ssRoomId');
                sessionStorage.removeItem('roomType');
                history.push(`/startme/${linkId}`)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default RoomAddedLink;
