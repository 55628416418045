import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/Fullscreen';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        backgroundColor: '#537bb8',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function GlbViewer({ url, title, scale = 0.3 }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography>{title}</Typography>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <IconButton edge="start" color="inherit" onClick={handleClickOpen} aria-label="expand">
                        <ExpandMoreIcon />
                    </IconButton>
                    <IconButton href={atob(url)} edge="start" color="inherit" onClick={() => { }} aria-label="download">
                        <CloudDownloadIcon />
                    </IconButton>
                </div>
            </div>
            <iframe src={`/glb/index.html?scale=${scale}&url=${url}`} title="GLB Viewer" width="100%" height="400px" />

            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {title}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div style={{ width: '100%', height: '100%' }}>
                    <iframe src={`/glb/index.html?scale=${scale}&url=${url}`} title="GLB Viewer" width="100%" height="100%" />
                </div>
            </Dialog>
        </div>
    )
}
