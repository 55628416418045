import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { forgotPassword, resetPasswordWithCode } from '../../../store/actions/auth';
import PrimaryButton from '../../../components/Buttons/PrimaryButton/PrimaryButton';
import '../Common.scss';
import newLogo from '../../../assets/images/HostaAI_LogoWithTagline.png';
import logo from '../../../assets/images/logo.png';
import logoText from '../../../assets/images/logoText.png';
import MustContainItem from '../Signup/MustContainItem';
import { isValidEmailOrPhoneNumber } from '../../../utils/utils';

const ForgotPassword = () => {
  const [stage, setStage] = useState(1); // 1 = email stage, 2 = code stage
  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [modalShown, setModalShown] = useState(false);
  const [modalErrorText, setModalErrorText] = useState('');
  const [showInst, setShowInst] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const checkEnter = (e, type) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      if (type === 1) {
        sendCode();
      }
      if (type === 2) {
        resetPassword();
      }
    }
  };
  const sendCode = async event => {
    // event.preventDefault();
    setErrorEmail(null);
    if (!isValidEmailOrPhoneNumber(email.trim(), true, false)) {
      setErrorEmail('Please Enter A Valid Email Address!');
    } else {
      const res = await dispatch(
        forgotPassword({
          email,
        })
      );
      if (res === 'send') {
        setModalErrorText('A link to reset your password has been sent to your email.');
        setStage(2);
      } else {
        setModalErrorText(res);
      }
      setModalShown(true);
    }
  };
  const resetPassword = async event => {
    // event.preventDefault();
    const decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,40}$/;
    setErrorCode(null);
    setErrorPassword(null);
    setErrorConfirmPassword(null);
    if (code.trim() === '') {
      setErrorCode('Please Enter Code!');
    } else if (!decimal.test(password.trim())) {
      setErrorPassword(
        'Password must be 6 to 15 characters long which contain at least one lowercase letter, one uppercase letter, one numeric digit and one special character'
      );
    } else if (!(password === confirmPassword)) {
      setErrorConfirmPassword('Confirm password does not match with password!');
    } else {
      const res = await dispatch(
        resetPasswordWithCode({
          email,
          code,
          password,
        })
      );
      if (res === 'success') {
        setModalErrorText('Your password has been changed successfully.');
        setModalShown(true);
        history.push('/');
      } else {
        setModalErrorText(res);
      }
      setModalShown(true);
    }
  };

  const mustContainData = [
    ['An uppercase letter (A-Z)', /[A-Z]/],
    ['A lowercase letter (a-z)', /[a-z]/],
    ['A number (0-9)', /\d/],
    ['A special character (!@#$)', /[^\w]/],
    ['Must contain atleast 10 characters', /^.{10,}$/],
    ['Password and Confirm Password must match', new RegExp(`^${confirmPassword}$`)],
  ];


  return (
    <div id="forgotpassword" className="main-conatiner">
      <div className="bgImg" />
      <div className="inner-div">
        <img className='width-full' src={newLogo} alt="Logo" />
        {stage === 1 && (
          <div className="floating-input-div">
            <div className="input-container">
              <input
                className="floating-input"
                type="text"
                placeholder="Email"
                value={email}
                onKeyDown={e => checkEnter(e, 1)}
                onChange={event => setEmail(event.target.value)}
              />
              {errorEmail ? (
                <div>
                  <p className="ErrorDivEmail">{errorEmail}</p>
                </div>
              ) : null}
            </div>
            <div className="button">
              <Button className="newButton" onClick={sendCode}>
                Send verification code
              </Button>
            </div>
            <div className="createAcc">
              <p role="none" className="createAccTxt" onClick={history.goBack}>
                Back
              </p>
            </div>
          </div>
        )}
        {stage === 2 && (
          <div className="floating-input-div">
            <div className="input-container">
              <input
                className="floating-input"
                type="text"
                placeholder="6 Digit Code"
                maxLength={6}
                onKeyDown={e => checkEnter(e, 2)}
                value={code}
                onChange={event => setCode(event.target.value)}
              />
              {errorCode ? (
                <div>
                  <p className="ErrorDivEmail">{errorCode}</p>
                </div>
              ) : null}
              <input
                className="floating-input"
                type={passwordShown ? 'text' : 'password'}
                placeholder="Password"
                onKeyDown={e => checkEnter(e, 2)}
                onChange={e => setPassword(e.target.value)}
                onFocus={e => setShowInst(true)}
                onBlur={e => setShowInst(false)}
                value={password}
              />
              {passwordShown ? (
                <FaEyeSlash
                  className="trailIcon"
                  size={30}
                  onClick={() => setPasswordShown(pass => !pass)}
                />
              ) : (
                <FaEye
                  className="trailIcon"
                  size={30}
                  onClick={() => setPasswordShown(pass => !pass)}
                />
              )}
              {errorPassword ? (
                <div>
                  <p className="ErrorDivEmail">{errorPassword}</p>
                </div>
              ) : null}
              <input
                className="floating-input"
                type={confirmPasswordShown ? 'text' : 'password'}
                placeholder="Confirm Password"
                onKeyDown={e => checkEnter(e, 2)}
                onChange={e => setConfirmPassword(e.target.value)}
                onFocus={e => setShowInst(true)}
                onBlur={e => setShowInst(false)}
                value={confirmPassword}
              />
              {confirmPasswordShown ? (
                <FaEyeSlash
                  className="trailIcon"
                  size={30}
                  onClick={() => setConfirmPasswordShown(pass => !pass)}
                />
              ) : (
                <FaEye
                  className="trailIcon"
                  size={30}
                  onClick={() => setConfirmPasswordShown(pass => !pass)}
                />
              )}
              {errorConfirmPassword ? (
                <div>
                  <p className="ErrorDivEmail">{errorConfirmPassword}</p>
                </div>
              ) : null}
            </div>
            {showInst && (
              <div className="passwordCheckcointener">
                <h4>Password Must Contain:</h4>
                <div className="must-container cfb">
                  {mustContainData.map(data => (
                    <MustContainItem data={[data[0], data[1].test(password)]} />
                  ))}
                </div>
              </div>
            )}
            <div className="button">
              <Button className="newButton" disabled={mustContainData.map(e => e[1].test(password)).some(e => !e)} onClick={resetPassword}>
                Change password
              </Button>
            </div>
          </div>
        )}
      </div>
      <Modal
        show={modalShown}
        onHide={() => setModalShown(false)}
        centered
        animation
        size="md"
        className="shadow-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" />
        </Modal.Header>
        <Modal.Body className="text-center">
          <h3>{modalErrorText}</h3>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <div>
            <PrimaryButton
              className="new_claims_btn"
              title="OK"
              onClick={() => setModalShown(false)}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default ForgotPassword;
