export const setActiveClaimPage = val => async dispatch => {
  dispatch({
    type: 'SET_ACTIVE_CLAIM',
    payload: val,
  });
};

export const setActiveRoomPage = val => async dispatch => {
  dispatch({
    type: 'SET_ACTIVE_ROOM',
    payload: val,
  });
};
