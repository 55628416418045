import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BaseButton = styled.button`
  width: 100%;
  height: 3.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 6px;
  font-family: 'Work Sans';
  font-size: 1rem;
  font-weight: 700;
  margin-top: .25rem;

  @media (min-width: 768px) {
    width: 70%;
    height: 3.75rem;
    font-size: 1.25rem;
  }
`;

const ThreeButtonWrapper = styled.div`
  visibility: ${props => props.hideThreeButtonPanel ? 'hidden' : ''};
  padding-bottom:  2rem;
  @media (min-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    visibility: ${props => props.hideThreeButtonPanel ? 'hidden' : ''}; 
  }
`;

const ButtonOne = styled(BaseButton)`
  background-color: #6A9EEA;
  &:active {
    background-color: #aac7f2;
  }
  &:disabled {
    opacity: 0.5;
  }
  color: #ffffff;
  margin-top: 1rem;
`;

const OtherButtons = styled(BaseButton)`
  border: 1.5px solid #6A9EEA;
  background-color: #ffffff;
  &:active {
    background-color: #a4c1ed;
  }
  color: #6A9EEA;
  margin-top: 1rem;
`;

const ThreeButtonPanel = ({ 
  hideThreeButtonPanel,
  buttonOneDisabled,
  buttonOneText,
  buttonTwoText,
  buttonThreeText,
  buttonOneShow,
  buttonTwoShow,
  buttonThreeShow,
  buttonOneCallback,
  buttonTwoCallback,
  buttonThreeCallback,
  ThreeButtonTopButtonParameter,
  nextButtonDisabled,

}) => {

  return (
    < ThreeButtonWrapper hideThreeButtonPanel={hideThreeButtonPanel}>
      { buttonOneShow && 
      <ButtonOne 
        disabled={nextButtonDisabled}
        onClick={() => buttonOneCallback(ThreeButtonTopButtonParameter)}>
          {buttonOneText}
      </ButtonOne> }
      { buttonTwoShow && <OtherButtons> buttonTwoText</OtherButtons> }
      { buttonThreeShow && <OtherButtons> buttonThreeText</OtherButtons>} 
    </ThreeButtonWrapper> 
  );
};

export default ThreeButtonPanel;

ThreeButtonPanel.defaultProps = {
  hideThreeButtonPanel: false,
  buttonOneText: 'Next',
  buttonTwoText: '',
  buttonThreeText: '',
  buttonOneDisabled: false,
  buttonOneShow: true,
  buttonTwoShow: false,
  buttonThreeShow: false,
  buttonOneCallback: ()=>{},
  buttonTwoCallback: ()=>{},
  buttonThreeCallback: ()=>{},
  ThreeButtonTopButtonParameter: '',
  nextButtonDisabled: false,
  claimId: ''
}

ThreeButtonPanel.propTypes = {
  hideThreeButtonPanel: PropTypes.bool,
  buttonOneText: PropTypes.string,
  buttonTwoText: PropTypes.string,
  buttonThreeText: PropTypes.string,
  buttonOneDisabled: PropTypes.bool,
  buttonOneShow: PropTypes.bool,
  buttonTwoShow: PropTypes.bool,
  buttonThreeShow: PropTypes.bool,
  buttonOneCallback: PropTypes.func,
  buttonTwoCallback: PropTypes.func,
  buttonThreeCallback: PropTypes.func,
  ThreeButtonTopButtonParameter: PropTypes.string,
  claimId: PropTypes.string
}

