import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IoIosClose, IoIosArrowBack } from "react-icons/io";
import { Modal } from "react-bootstrap";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton/PrimaryButton";
import RequestLink from "../RequestLink/RequestLink";
import { postRoom } from "../../../../../store/actions/reqImage";
import Loader from "../../../../../components/Loader/Loader";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { createSharablelink } from "../../../../../store/actions/allClaim";
import { isValidEmailOrPhoneNumber } from "../../../../../utils/utils";

const ReqImage = () => {
  const { claimId, userType } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [modalShown, setModalShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalErrorText, setModalErrorText] = useState("");
  const [sharablelink, setSharableLink] = useState("");
  const [back, setBack] = useState();
  const [displayPopup, setDisplayPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [setcopyclipboard, setCopyclipboard] = useState(sharablelink);
  const [errorEmail, setErrorEmail] = useState();
  const [copy, setCopy] = useState(false);

  const handleClose = (async) => {
    history.goBack();
  };




  const updateEmailValue = (e) => {
    setEmail(e.target.value);
  };

  const handleCheck = () => {
    if (!isValidEmailOrPhoneNumber(email, true, true)) {
      setErrorEmail("Please Enter A Valid Email Address or A Phone Number");
    } else handleBtn();
  };

  const handleBtn = async (type) => {
    setLoading(true);

    const postRoomRes = await dispatch(postRoom(claimId, userType, { email }));
    console.log(postRoomRes);
    setLoading(false);
    if (postRoomRes && postRoomRes.status == 200) {
      setDisplayPopup(true);
    } else {
      setModalErrorText("Something went wrong! Try Again!");
      setModalShown(true);
    }
  };




  const handleClaimBtn = async => {
    history.push(`/app/allroom/${claimId}/`);
  };




  const getSharableLink = async () => {
    setLoading(true);
    const link = await dispatch(createSharablelink(claimId, userType));
    setSharableLink(link.link);
    setLoading(false);
  };

  useEffect(() => {
    getSharableLink();
  }, []);






  const updateSharableLinkInputField = (e) => {
    setSharableLink(e.target.value);
  };



  return (
    <div className="main-popup-container" id="req-image">
      <Loader loading={loading} absolute overlay />
      <div className={displayPopup ? "blur-bg" : "popup-container"}>
        <div className="inner-fields">
          <div className="closeImage" role="none" onClick={handleClose}>
            <IoIosClose size={30} color="#316FD0" />
          </div>
          <div className="email-field">
            <div className="input-label">
              <label htmlFor="enterPropertyName" className="heading-labels">
                Enter Email Address or Phone Number
              </label>
            </div>

            <div className="input-text-main">
              <input
                size="38"
                type="text"
                placeholder="john.doe@hosta.com, or (800)123-1234"
                className="input-text pr-0"
                id="address1"
                value={email}
                onChange={updateEmailValue}
              />
              <div className="reqImgBtn1">
                <PrimaryButton
                  className="new_claim_btns_ab"
                  title="REQUEST IMAGE"
                  onClick={handleCheck}
                />
              </div>
              {errorEmail ? (
                <div className="errorDiv">{errorEmail} </div>
              ) : null}
            </div>
            <div className="reqImgBtn">
              <PrimaryButton
                className="new_claim_btns_ab"
                title="GO TO CLAIM"
                onClick={handleClaimBtn}
              />
            </div>

            <label htmlFor="enterPropertyName" className="heading-labels">
              Shareable Link
            </label>
            <div className="input-text-main">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <input
                  size="42"
                  type="text"
                  placeholder="no link available"
                  className="input-text"
                  value={sharablelink}
                  onChange={updateSharableLinkInputField}
                  disabled={true}
                />
                <CopyToClipboard text={sharablelink}>
                  <PrimaryButton
                    className="copyBtn"
                    title={copy == false ? "Copy Link" : "copied"}
                    onClick={() => { setCopy(true); setTimeout(() => { setCopy(false) }, 1500) }}
                  />
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <div onClick={handleClose} className="go_back">
            <IoIosArrowBack color="#537BB8" />
            <a href="#"> Back</a>
          </div>
        </div>
      </div>
      {displayPopup ? (
        <div className="ReqLinkPopup">
          {/*  REQUEST LINK REQUEST LINK  REQUEST LINK  REQUEST LINK  REQUEST LINK  REQUEST LINK */}
          {/*  REQUEST LINK REQUEST LINK  REQUEST LINK  REQUEST LINK  REQUEST LINK  REQUEST LINK */}
          {/*  REQUEST LINK REQUEST LINK  REQUEST LINK  REQUEST LINK  REQUEST LINK  REQUEST LINK */}
          <RequestLink claimId={claimId} closePopup={() => setDisplayPopup(false)} />
        </div>
      ) : null}

      <Modal
        show={modalShown}
        onHide={() => setModalShown(false)}
        centered
        animation
        size="md"
        className="shadow-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" />
        </Modal.Header>
        <Modal.Body className="text-center">
          <h3>{modalErrorText}</h3>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <div>
            <PrimaryButton
              className="new_claims_btn"
              title="OK"
              onClick={() => setModalShown(false)}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ReqImage;
