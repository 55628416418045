import React, { Fragment } from 'react';
import { Navbar, Nav, NavDropdown, OverlayTrigger } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FiChevronDown } from 'react-icons/fi';
import { logout } from '../../store/actions/auth';
import Tooltip from '../Tooltip/Tooltip';

const Header = ({ showHeader }) => {
  const credits = useSelector(state => state.credit.credits);
  const user = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleLogout = async () => {
    await dispatch(logout());
  };

  const loadReleaseNotes = () => {
    history.push({
      pathname: '/app/releasenotes'
    });
  }

  return (
    <Fragment>
      {showHeader && (
        <div id="header">
          <Navbar variant="dark" className="header_navbar">
            {/* <IoReorderThreeOutline size={40} color="white" /> */}
            <Nav className="ml-auto" id="header-dropdown">
              <NavDropdown title={user ? user.email : 'Username'} id="username-dropdown" alignRight>
                {credits ? (
                  <NavDropdown.ItemText>{credits} Room Credits</NavDropdown.ItemText>
                ) : (
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 400 }}
                    overlay={Tooltip({
                      title: 'Additional credits required. Contact info@hostalabs.com',
                    })}
                  >
                    <NavDropdown.ItemText>{credits} Room Credits</NavDropdown.ItemText>
                  </OverlayTrigger>
                )}
                {/* <NavDropdown.Item>Account Setting</NavDropdown.Item>
                <NavDropdown.Item>Subscription</NavDropdown.Item>
                <NavDropdown.Item>Summary</NavDropdown.Item> */}
                <NavDropdown.Divider />
                    {/* <Nav.Link eventKey="releaseNotes" href="#/app/releaseNotes"> */}
                <NavDropdown.Item id="release-notes" onClick={() => loadReleaseNotes()}>Release Notes</NavDropdown.Item>
                    {/* </Nav.Link> */}
      
                <NavDropdown.Divider />
                <NavDropdown.Item id="logout" onClick={() => handleLogout()}>Logout</NavDropdown.Item>
              </NavDropdown>
              <FiChevronDown className="username_arrow" color="white" size={18} />
            </Nav>
          </Navbar>
          <div className="header_logo--block">
            <div className="hexagon">
              <img
                className="header_logo"
                role="none"
                src="/logo.png"
                alt="icon"
                onClick={() => history.push('/app/allclaim')}
              />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Header;
