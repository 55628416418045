import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const ScrollToTop = React.memo(props => {
  useEffect(() => {
    if (!props.scrollDisabledPathname.includes(props.location.pathname)) {
      window.scrollTo(0, 0);
    }
  }, [props.location.pathname, props.scrollDisabledPathname]);

  return props.children;
});

ScrollToTop.propTypes = {
  scrollDisabledPathname: PropTypes.array.isRequired,
};

export default withRouter(ScrollToTop);
