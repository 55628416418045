import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FiDownload } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { RoomInterfaceContext } from '../../../../../../context/RoomInterface';
import { saveAs } from 'file-saver';
import { Modal } from 'react-bootstrap';
import simpleLoader from "../../../../../../assets/images/simpleLoader.gif";
var JSZip = require("jszip");

const CarouselTabs = props => {
  const { carousel, setCarousel } = props;
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const params = useParams();
  const { claimId } = params;
  const { roomId } = params;

  const { roomInterfaceData, photos } = useContext(RoomInterfaceContext);

  const { interfaceData } = roomInterfaceData;
  const { floorPlan } = interfaceData;

  const isDemo = localStorage.getItem('demo') === 'true';

  const download = imgUrl => {
    window.open(imgUrl);
  };

  return (
    <div id="carousel-tabs">
      <div
        role="none"
        className={classNames('images_tab', {
          custom_tab_active: carousel === 'images',
          'full-width': !isDemo
        })}
        onClick={() => setCarousel('images')}
      >
        <p>Images</p>
      </div>
      {isDemo && <div
        role="none"
        className={classNames('damage_tab', {
          custom_tab_active: carousel === 'damage'
        })}
        onClick={() => setCarousel('damage')}
      >
        <p>Damage</p>
      </div>}
      <div
        role="none"
        className={classNames('floor_tab', {
          custom_tab_active: carousel === 'floor',
        })}
        onClick={() => setCarousel('floor')}
      >
        <p>Floor Plan</p>
        <FiDownload
          onClick={() => {
            const url = floorPlan;
            const link = document.createElement('a');
            link.href = 'javascript:void(0);';
            // link.setAttribute('download', 'image.JPEG');
            // link.target = 'blank';
            // document.body.appendChild(link);
            link.click(download(url));
          }}
          size={20}
          color={carousel === 'floor' ? '#fff' : '#828282'}
        />
      </div>
      <div
        role="none"
        className={classNames('photos_tab', {
          custom_tab_active: carousel === 'photos',
        })}
        onClick={() => setCarousel('photos')}
      >
        <p>Photos</p>
        {loading ? <img src={simpleLoader} style={{ width: 20, margin: "0px 0px 0px 10px" }} />
          : <FiDownload
            onClick={() => {
              setLoading(true);
              var zip = new JSZip();
              photos.forEach((cur, i) => {
                //uncomment below code for 4 image downoads
                const imageBlob = fetch(`${cur}`, { method: "GET", headers: { "Cache-Control": 'no-cache' } }).then(response => response.blob());
                console.log(imageBlob);
                zip.file(`${claimId}_` + (i + 1) + ".jpg", imageBlob, {
                  base64: true
                });

                //code for download  additionalImages
                // if(i>=2){
                //   const imageBlob = fetch(`${cur}`).then(response => response.blob());
                //   console.log(imageBlob);
                //   zip.file(`${claimId}_` + (i + 1) + ".jpg", imageBlob, {
                //     base64: true
                //   });
                // }
              })
              zip.generateAsync({ type: "blob" })
                .then(function (content) {
                  saveAs(content, "downloadImages.zip");
                  setLoading(false);
                  setSaveSuccess(true);
                  setModal(true);
                });
            }}
            size={20}
            color={carousel === 'photos' ? '#fff' : '#828282'}
          />}
      </div>
      <Modal
        show={modal}
        onHide={() => {
          setSaveSuccess(false);
          setModal(false);
        }}
        centered
        animation
        size="md"
        className="shadow-lg roomps buttondonload"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" />
        </Modal.Header>
        <Modal.Body className="text-center fgd">
          {saveSuccess ? <><h3>Successful download!</h3> <h3>Please check your downloads folder to access your photos</h3></>
            : <h3>Somethin went wrong</h3>}
        </Modal.Body>
      </Modal>
    </div>
  );
};

CarouselTabs.propTypes = {
  carousel: PropTypes.string.isRequired,
  setCarousel: PropTypes.func.isRequired,
};

export default CarouselTabs;
