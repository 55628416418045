import React, { useContext, useEffect } from 'react';

import styled from 'styled-components';
import NewPortalContext from '../../new-portal-context/NewPortalContext';
import basement from '../../new-portal-images/Two_SelectTheRoom/Basement.png';
import bathroom from '../../new-portal-images/Two_SelectTheRoom/Bathroom.png';
import bedroom from '../../new-portal-images/Two_SelectTheRoom/Bedroom.png';
import dining from '../../new-portal-images/Two_SelectTheRoom/Dining.png';
import kitchen from '../../new-portal-images/Two_SelectTheRoom/Kitchen.png';
import laundry from '../../new-portal-images/Two_SelectTheRoom/Laundry.png';
import living from '../../new-portal-images/Two_SelectTheRoom/Living.png';
import sunroom from '../../new-portal-images/Two_SelectTheRoom/Sunroom.png';
import other from '../../new-portal-images/Two_SelectTheRoom/Other.png';
import check from '../../new-portal-images/Two_SelectTheRoom/check.png';

const ButtonContainer = styled.div`
  //height: 95%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows:   repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 12px;
  
  @media (min-width: 768px) {
    width: 72%;
    padding-left: .5rem;
    padding-top: .75rem;
    grid-column-gap: 17px;
    grid-row-gap: 26px;
  }
`;

const RoomButton = styled.div`
  display: flex; 
  flex-direction: column;
  border: 1px solid #c7c7c7;
  border-radius: 8px;
  width: 7rem;
  height: 7rem;
  background-color: ${props => props.backdrop ? '#EFF6FF' : ''};
  @media (min-width: 768px) {
    height: 7.5rem;
    width: 8rem;
    padding-top: .5rem;
  }
`;

const CheckImgContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  // padding-right: .4rem; 
  margin-top: -.25rem;

`;
const CheckImg = styled.img`
  width: 1.2rem;
  height: 1.1rem;
  margin-right: .5rem;
  margin-top: .5rem;
    visibility: ${props => props.hideImg ? '' : 'hidden'};
`;

const IconContainer = styled.div`
  display: flex; 
  flex-direction: column;
  align-items: center;
`;

const IconImg = styled.img`
  width: 2.25rem;
  height: 2rem;
`;

const IconText = styled.p`
  font-size: 1rem;
  font-family: 'Work Sans';
  font-weight: 600;
  margin-top: .5rem;
`;

const RoomButtonMapper = () => {
  // context allows NextButton in ThreeButtonPanel to toggle to enabled 
  //when a Two_Select button is selected here in RoomButtonMapper
  
  const buttonData = [
    { roomType: 'Bathroom', image: bathroom },
    { roomType: 'Bedroom', image: bedroom },
    { roomType: 'Kitchen', image: kitchen },
    { roomType: 'Basement', image: basement },
    { roomType: 'Living', image: living },
    { roomType: 'Laundry', image: laundry },
    { roomType: 'Sunroom', image: sunroom },
    { roomType: 'Dining', image: dining },
    { roomType: 'Other', image: other }
  ];

  sessionStorage.oneImagefromSession = true;

  const { roomType, updateRoomType } = useContext(NewPortalContext);

  useEffect(() => {
    updateRoomType(sessionStorage.roomType);
  }, []);
  
  const changeButton = (roomType) => {
    updateRoomType(roomType);
    sessionStorage.roomType = roomType;
  };

  console.log('SS Roomtype Is:', sessionStorage.roomType);
  return (
    <ButtonContainer>
      { buttonData.map(button => (
          <RoomButton 
            key={button.roomType}
            type="button"
            role="button"
            onClick={() => changeButton(button.roomType)}
            backdrop={roomType === button.roomType ? true: false}>
            <CheckImgContainer>
              <CheckImg 
                src={check} 
                hideImg={roomType === button.roomType ? true: false}>
              </CheckImg>
            </CheckImgContainer>
            <IconContainer>   
              <IconImg src={button.image} />
              <IconText>{button.roomType}</IconText> 
            </IconContainer>
          </RoomButton>
      ))} 
    </ButtonContainer>
  );
};

export default RoomButtonMapper;