import React, { Fragment, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { FiEdit, FiSave, FiX } from 'react-icons/fi';
import { Row, Col, Modal } from 'react-bootstrap';
import { RoomInterfaceContext } from '../../../../../context/RoomInterface';
import Box from '../../../../../components/Box/Box';
import Select from '../../../../../components/Select/Select';
import Input from '../../../../../components/Input/Input';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton/PrimaryButton';
import { changeRoomType, changeSummery } from '../../../../../store/actions/allRoom';
import { downloadInterfaceReport } from '../../../../../store/actions/downloads';
import { isArrEmpty } from '../../../../../utils/utils';
import { useSelector } from 'react-redux';

const Variables = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { claimId, roomId } = params;

  const { roomInterfaceData, setRoomInterfaceData } = useContext(RoomInterfaceContext);
  const { interfaceData } = roomInterfaceData;
  const [editMode, setMode] = useState(false);
  const [modal, setModal] = useState(false);
  const [data, setData] = useState(interfaceData.summary);
  const [dataType, setDataType] = useState(interfaceData.roomType);
  const [saveData, setSaveData] = useState({ type: dataType, ...data });
  const userType = useSelector(state => state.auth.user.userType);

  const handleSetData = (key, value, text) => {
    setData(prev => ({ ...prev, [key]: `${value}` }));
  };

  const handleSaveData = async () => {
    await dispatch(changeRoomType(claimId, roomId, dataType));

    const updateData = Object.keys(data).map(key => [key, data[key]]);
    const newData = updateData.map(key => [`summary.${key[0]}`, key[1]]);
    const finalData = Object.fromEntries(newData);
    await dispatch(changeSummery(claimId, roomId, finalData));
    setModal(true);
    setSaveData({ type: dataType, ...data });
    setRoomInterfaceData({ ...roomInterfaceData, roomType: dataType });
    setMode(prevProp => !prevProp);
  };

  const downloadReportFn = async (format = '') => {
    await dispatch(downloadInterfaceReport(claimId, roomId, format));
  };

  return (
    <div id="interface-variables">
      <Box
        type="noHeader"
        title="Variables"
        className={classNames('variable_box', {
          'variable_box--active': editMode,
        })}
      >
        {userType === 'AppUser' ? null : (
          <div>
            {editMode ? (
              <Fragment>
                <FiSave
                  className="left_icon"
                  size={16}
                  color="#8282828E"
                  onClick={handleSaveData}
                />
                <FiX
                  className="right_icon"
                  size={16}
                  color="#8282828E"
                  onClick={() => setMode(prevProp => !prevProp)}
                />
              </Fragment>
            ) : (
              <FiEdit
                className="right_icon"
                size={16}
                color="#8282828E"
                onClick={() => setMode(prevProp => !prevProp)}
              />
            )}
          </div>
        )}

        <Row className="variable_form">
          <Col xs={12}>
            <div className="form_group form_group--half">
              <p className="form_title">Type: </p>
              {editMode ? (
                <Select
                  className="variable_select"
                  variant="sm"
                  options={[
                    'Bathroom',
                    'Living',
                    'Bedroom',
                    'Dining',
                    'Kitchen',
                    'Garage',
                    'Hall',
                    'Laundry',
                    'Library',
                    'Sunroom',
                    'Den',
                    'Walk-in closet',
                  ]}
                  value={dataType}
                  onChange={e => setDataType(e.target.value)}
                />
              ) : (
                <p className="form_answer">{saveData.type}</p>
              )}
            </div>
          </Col>
          {!isArrEmpty(Object.values(data).filter(e => e)) ? (
            <Fragment>
              {Object.entries(data).map((cur, i) => {
                const [key, value] = cur;

                if (!value) return null;
                const [val, text] = value.split(' ');

                return (
                  <Col sm={6} className="form_group" key={i}>
                    <p className="form_title">{`${key}:`}</p>
                    {editMode ? (
                      <Input
                        //disabled
                        variant="sm"
                        value={val}
                        onChange={e => handleSetData(key, e.target.value, text)}
                        innerText={text}
                        icon={false}
                      />
                    ) : (
                      <p className="form_answer">{saveData[key]}</p>
                    )}
                  </Col>
                );
              })}
            </Fragment>
          ) : (
            <div className="w-100">
              <p className="m-0 text-center">No Results Found</p>
            </div>
          )}
        </Row>
        <div className="reportBtnWrapper">
          <PrimaryButton
            title="Download Report"
            className="download-report"
            onClick={() => downloadReportFn('')}
          />
          <PrimaryButton
            title="Download Report (PDF)"
            className="download-report ml-1"
            onClick={() => downloadReportFn('format=PDF')}
          />
        </div>
        {editMode && (
          <div className="save_container">
            <PrimaryButton title="Save" onClick={handleSaveData} />
          </div>
        )}
        <Modal
          show={modal}
          onHide={() => {
            setModal(false);
          }}
          centered
          animation
          size="md"
          className="shadow-lg summerysucc"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter" />
          </Modal.Header>
          <Modal.Body className="text-center">
            <h3>Saved Successfully!</h3>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center" />
        </Modal>
      </Box>
    </div>
  );
};

export default Variables;
