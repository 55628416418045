const initialErrorState = {
    error: null
};

const ErrorReducer = (state = initialErrorState, action) => {
    const { type, payload } = action;
    switch (type) {
        case 'SHOW_ERROR':
            return { error: payload };
        case 'HIDE_ERROR':
            return { error: null };
        default:
            return state;
    }
};

export default ErrorReducer;
