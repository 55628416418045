import React from 'react';
import styled from 'styled-components';
import LargeHeadline from '../../new-portal-components/SpanHeadlines/LargeHeadline';
import SecondaryHeadline from '../../new-portal-components/SpanHeadlines/SecondaryHeadline';
import tip1 from '../../new-portal-images/Three_TipInstructions/tip1.png';
import tip2a from '../../new-portal-images/Three_TipInstructions/tip2a.png';
import tip2b from '../../new-portal-images/Three_TipInstructions/tip2b.png';
import tip3a from '../../new-portal-images/Three_TipInstructions/tip3a.png';
import tip3b from '../../new-portal-images/Three_TipInstructions/tip3b.png';  
import tip4svg from '../../new-portal-images/Three_TipInstructions/tip4svg.svg';
import accepted from  '../../new-portal-images/Three_TipInstructions/accepted.png';
import declined from  '../../new-portal-images/Three_TipInstructions/declined.png';
import downArrow from '../../new-portal-images/Three_TipInstructions/downArrow.png';


const tipsData = [
  {
    tipName: 'Tip 1',
    tipText: 'Pick one wall to start with. Stand back to get a photo of each wall and the edges', 
    tipImgOne: tip1,
  },
  {
    tipName: 'Tip 2',
    tipText: 'Make sure the ceiling, the floor, and all four corners are in the photo',
    tipImgOne: tip2a,
    tipImgTwo: tip2b
  },
  {
    tipName: 'Tip 3',
    tipText: 'Hold the camera straight on and avoid blurry shots',
    tipImgOne: tip3a,
    tipImgTwo: tip3b
  },
  {
    tipName: 'Tip 4',
    tipText: 'Capture walls in a clockwise direction, moving from left to right',
    tipImgOne: tip4svg
  },
  {
    tipName: 'Tip 5',
    tipText: 'If you have an open concept kitchen/dining room, you can picture imaginary walls separating the kitchen and dining room space. ',
    tipTextTwo: 'Then, take pictures of the space as if it were two rooms',
  }
];

const TipsWrapper = styled.div`
  position: relative;
`;

const TipContainer = styled.div`
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const TwoImgsWrapper = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;

const TipImgContainer = styled.div`
  margin-top: 1.1rem;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const ArrowImg = styled.img`
  height: 2.7rem;
  width: 1.1rem;  
  position: absolute;
  top: 9rem;
  left: 11rem;

  @media (min-width: 768px) { 
    top: 8.4rem;
    left: 19rem;
  }
`;

const TipImg = styled.img`
  @media (min-width: 768px) { 
    margin-left: .5rem;
    margin-right: .5rem;
  }
`;

const TipImgLarge = styled.img`
  margin-top: .75rem;
  width: 100%;
  @media (min-width: 768px) {
    width: 60%;
    height: 60%;
  }
`;

const TipImgFive = styled.img`
  margin-top: 1rem;
  margin-left: 3.8rem;

  @media (min-width: 768px) {
    width: 25rem;
    height: 18rem;
    margin-left: 1rem;
  }

`;

const XorCheck = styled.img`
  margin-left: 3rem;
  @media (min-width: 768px) {
    margin-top: 1.5rem;
    margin-right: 2.5rem;
  }
`;

const BaseTipWTwoImgs = ({ tipData }) => {
  return (
    <>
      <TwoImgsWrapper>
        <TipImgContainer>
          <TipImg src={tipData.tipImgOne} /><XorCheck src={declined} />
        </TipImgContainer>
        <TipImgContainer>
          <TipImg src={tipData.tipImgTwo} /><XorCheck src={accepted} /> 
        </TipImgContainer>
      </TwoImgsWrapper>
    </>
  );
};

const BaseTipWOneOrNoImgs = ({ tipData }) => {
  return (
    <>
      {(tipData.tipImgOne && tipData.tipName === 'Tip 1') && (<TipImgLarge src={tipData.tipImgOne} />)}
      {(tipData.tipImgOne && tipData.tipName === 'Tip 4') && (<TipImgFive src={tipData.tipImgOne} />)}
      {tipData.tipTextTwo && <SecondaryHeadline text={tipData.tipTextTwo} />}
    </>
  );
};

const Three_TipsInstructions = () => {
  return ( 
    <TipsWrapper>
       <ArrowImg src={downArrow} />
      {tipsData.map(tipData => (
        <TipContainer key={tipData.tipText}>
          <LargeHeadline text={tipData.tipName} />
          <SecondaryHeadline text={tipData.tipText} />
          { tipData.tipImgTwo ? 
            ( <BaseTipWTwoImgs tipData={tipData} /> ) : (<BaseTipWOneOrNoImgs tipData={tipData}  /> )
          }
        </TipContainer>
      ))}
    </TipsWrapper>
  );
};

export default Three_TipsInstructions;