import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const RoomInterfaceContext = createContext({
  setInterfaceData: null,
  allAreas: [],
  setAllAreas: null,
  detailsActive: {},
  setDetailsActive: null,
  calculationActive: {},
  setCalculationActive: null,
  calculationList: [],
  setCalculationList: null,
  calculationIds: [],
  setCalculationIds: null,
  calculationCheckbox: [],
  setCalculationCheckbox: null,
  roomInterfaceData: {},
  setRoomInterfaceData: null,
  isCalculationChecked: '',
  setCalculationChecked: null,
  detailJSON: [],
  setDetailsJSON: null,
  carouselIndex: 0,
  setCarouselIndex: null,
  selectedArea: {},
  setSelectedArea: null,
  carouselPhotosIndex: 0,
  setCarouselPhotosIndex: null,
  photos: [],
  setPhotos: null,
  addData: null,
  setAddData: null,
  editData: null,
  setEditData: null,
  changeData: null,
  setChangeData: null,
});

const RoomInterfaceProvider = props => {
  const { data, children } = props;
  const { interfaceData, allArea, detailsJSON, calculationInfo, getAllRoomInterfaceData } = data;
  const { calculationsCheckbox, calculationsIds, calculationsList } = calculationInfo;
  const [allAreas, setAllAreas] = useState(allArea);
  const [detailsActive, setDetailsActive] = useState({});
  const [calculationActive, setCalculationActive] = useState({});
  const [calculationList, setCalculationList] = useState(calculationsList);
  const [calculationIds, setCalculationIds] = useState(calculationsIds);
  const [calculationCheckbox, setCalculationCheckbox] = useState(calculationsCheckbox);
  const [roomInterfaceData, setRoomInterfaceData] = useState(interfaceData || []);
  const [isCalculationChecked, setCalculationChecked] = useState(null);
  const [detailJSON, setDetailsJSON] = useState(detailsJSON);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [selectedArea, setSelectedArea] = useState({});
  const [carouselPhotosIndex, setCarouselPhotosIndex] = useState(0);
  const [photos, setPhotos] = useState([undefined]);
  const [addData, setAddData] = useState(null);
  const [editData, setEditData] = useState(null);
  const [changeData, setChangeData] = useState(null);
  const setInterfaceData = async () => {
    const [fetchData, allAreas] = await getAllRoomInterfaceData(interfaceData.roomData);
    setDetailsJSON(fetchData);
    setAllAreas(allAreas);
  };

  return (
    <RoomInterfaceContext.Provider
      value={{
        setInterfaceData,
        allAreas,
        setAllAreas,
        detailsActive,
        setDetailsActive,
        calculationActive,
        setCalculationActive,
        calculationList,
        setCalculationList,
        calculationIds,
        setCalculationIds,
        calculationCheckbox,
        setCalculationCheckbox,
        roomInterfaceData,
        setRoomInterfaceData,
        isCalculationChecked,
        setCalculationChecked,
        detailJSON,
        setDetailsJSON,
        carouselIndex,
        setCarouselIndex,
        selectedArea,
        setSelectedArea,
        carouselPhotosIndex,
        setCarouselPhotosIndex,
        photos,
        setPhotos,
        editData,
        setEditData,
        addData,
        setAddData,
        changeData,
        setChangeData
      }}
    >
      {children}
    </RoomInterfaceContext.Provider>
  );
};

RoomInterfaceProvider.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RoomInterfaceProvider;
