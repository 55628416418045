import { v4 as uuidv4 } from 'uuid';
import { isArrEmpty, isObjEmpty } from '../../../../utils/utils';
import variables from '../../../../styles/variables.module.scss';

const recursiveMap = (array, results, title, history, carouselIndex) => {
  array.map((arrObj, i) => {
    const temp = Array.isArray(arrObj) ? [...arrObj] : { ...arrObj };
    const activeKey = `${carouselIndex}_0${i + 1}`;
    if (Array.isArray(temp)) {
      return recursiveMap(
        temp,
        results,
        title[i],
        [...history, { title: 'root', id: activeKey }],
        carouselIndex
      );
    }
    if (!isArrEmpty(temp.children)) {
      temp.orgId = temp.unique_id;
      temp.id = activeKey;
      temp.parentTitle = title;
      temp.history = [...history, { title, id: activeKey }];
      temp.variables = temp.variables.map(cur => ({ ...cur, variableId: uuidv4() }));
      results.unshift(temp);
      return recursiveMap(temp.children, results, temp.title, temp.history, carouselIndex);
    }
    temp.orgId = temp.unique_id;
    temp.id = activeKey;
    temp.parentTitle = title;
    temp.history = [...history, { title, id: activeKey }];
    temp.variables = temp.variables.map(cur => ({ ...cur, variableId: uuidv4() }));
    return results.unshift(temp);
  });
};

export const getColor = id => {
  const [, customId] = id.split('_');
  if (customId === '02') {
    return variables[`light-green`];
  }
  if (customId === '03') {
    return variables[`light-yellow`];
  }
  return variables[`light-tomato`];
};

const getNavigate = history =>
  history.reduce((acc, val) => {
    const prevKey = Object.keys(acc)[Object.keys(acc).length - 1];
    acc[val.title] = isObjEmpty(acc)
      ? `${val.id}`
      : `${acc[prevKey]}_${val.id.split('_').slice(1).join('')}`;
    return acc;
  }, {});

export const makingArea = (currentArea, carouselIndex) => {
  const valueEntry = Object.values(currentArea);
  const results = [];
  recursiveMap(valueEntry, results, Object.keys(currentArea), [], carouselIndex);
  return results.map(cur => {
    const temp = { ...cur };
    temp.key = uuidv4();
    temp.name = temp.title;
    temp.shape = 'poly';
    temp.coords = temp.polygon ? temp.polygon.flatMap(poly => poly) : [];
    temp.fillColor = getColor(temp.history[0].id);
    temp.navigate = getNavigate(temp.history);
    return temp;
  });
};