import React, { Fragment, useState, useEffect, useContext } from 'react';
import { FiChevronDown, FiChevronRight, FiX } from 'react-icons/fi';
import Checkbox from '../../Checkbox/Checkbox';
import { RoomInterfaceContext } from '../../../context/RoomInterface';
import Input from '../../Input/Input';

const BrokenListChild = props => {
  const {
    current: defaultCurrent,
    active,
    dataKey,
    singleKey,
    onSelect,
    isArrowSeen,
    type,
  } = props;
  const {
    setCalculationIds,
    calculationCheckbox,
    setCalculationCheckbox,
    setCalculationList,
    setSelectedArea,
    editData,
    setEditData,
    addData,
    setAddData,
  } = useContext(RoomInterfaceContext);
  const [current, setCurrent] = useState(defaultCurrent || {});
  const newCurrent = addData ?? editData;
  const setNewCurrent = addData ? setAddData : setEditData;

  const isWriteMode =
    (editData || addData) && !active[current.title] && active[dataKey] === singleKey;
  const ChevronIcon = active[dataKey] === singleKey ? FiChevronDown : FiChevronRight;

  useEffect(() => setCurrent(defaultCurrent || {}), [defaultCurrent]);

  const handleCheckbox = async (key, current) => {
    setSelectedArea(current);
    const selectedKey = await onSelect(key);
    setCalculationIds(prev => {
      const bool = prev.some(cur => JSON.stringify(cur) === JSON.stringify(selectedKey));
      return !bool
        ? [...prev, selectedKey]
        : prev.filter(cur => JSON.stringify(cur) !== JSON.stringify(selectedKey));
    });
    setCalculationCheckbox(prev => {
      const bool = prev.some(cur => cur === current.unique_id);
      return !bool ? [...prev, current.unique_id] : prev.filter(cur => cur !== current.unique_id);
    });
  };

  const handleDelete = detail => {
    setCalculationIds(prev => {
      const bool = prev.some(cur => JSON.stringify(cur) === JSON.stringify(detail.navigate));
      return !bool
        ? [...prev, detail.navigate]
        : prev.filter(cur => JSON.stringify(cur) !== JSON.stringify(detail.navigate));
    });
    setCalculationList(prev => prev.filter(cur => cur.unique_id !== detail.unique_id));
    setCalculationCheckbox(prev => {
      const bool = prev.some(cur => cur === detail.unique_id);
      return !bool ? [...prev, detail.unique_id] : prev.filter(cur => cur !== detail.unique_id);
    });
  };

  const handleChange = (value, type) => {
    if (type === 'title') return setNewCurrent(prev => ({ ...prev, title: value }));
    if (type === 'unit') return setNewCurrent(prev => ({ ...prev, unit: value }));
    if (type === 'quantity') return setNewCurrent(prev => ({ ...prev, quantity: value }));
  };

  const common = () => {
    if (isWriteMode) {
      return (
        <Fragment>
          {Array.isArray(current.unit) ? (
            <select className="broken_list_select">
              {current.unit.map((option, i) => (
                <option key={i} value={i} className="broken_list_select_option">
                  {option}
                </option>
              ))}
            </select>
          ) : (
            <Input
              inputClassName="no-xs"
              value={newCurrent.unit}
              variant="xs"
              icon={false}
              onChange={e => handleChange(e.target.value, 'unit')}
            />
          )}
          <Input
            inputClassName="no-xs"
            value={newCurrent.quantity}
            variant="xs"
            icon={false}
            onChange={e => handleChange(e.target.value, 'quantity')}
          />
        </Fragment>
      );
    }

    return (
      <Fragment>
        {Array.isArray(current?.unit) ? (
          <select className="broken_list_select">
            {current.unit.map((option, i) => (
              <option key={i} value={i} className="broken_list_select_option">
                {option}
              </option>
            ))}
          </select>
        ) : (
          <p className="broken_list_select_text">{current.unit}</p>
        )}
        <p className="broken_list_quantity">{current.quantity}</p>
      </Fragment>
    );
  };

  if (type === 'calculation') {
    return (
      <Fragment>
        <div className="broken_list_title_wrapper">
          <p className="broken_list_title">
            <span className="text-capitalize">{(current.history || [])[1]?.title}</span>
            <span> :: </span>
            <span>{current?.title}</span>
          </p>
        </div>
        {common()}
        <FiX onClick={() => handleDelete(current)} />
      </Fragment>
    );
  }

  const isDisabled = () => {
    if (current?.ImageIDs === [] || (Array.isArray(current?.ImageIDs) && current?.ImageIDs.length === 0))
      return true;
    return false;
  }

  return (
    <Fragment>
      <div className="broken_list_title_wrapper">
        {isArrowSeen(current) && (
          <ChevronIcon size={26} className="broken_list_arrow" color="#6a9eea" />
        )}
        {isWriteMode ? (
          <Input
            value={newCurrent.title}
            variant="lg"
            icon={false}
            onChange={e => handleChange(e.target.value, 'title')}
          />
        ) : (
          <p className="broken_list_title">{current.title}</p>
        )}
      </div>
      {common()}
      <Checkbox
        id="tempBrokenListDamaged-checkbox"
        checked={calculationCheckbox.includes(current.unique_id)}
        onChange={() => handleCheckbox(singleKey, current)}
        random={singleKey}
        disabled={isDisabled()}
      />
    </Fragment>
  );
};

export default BrokenListChild;
