import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { IoIosClose, IoIosArrowBack } from 'react-icons/io';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton/PrimaryButton';

const RequestLink = props => {
  const { claimId } = useParams();
  const { closePopup } = props;
  const [back, setBack] = useState();
  const history = useHistory();
  const handleBtn = async => {
    history.push(`/app/allroom/${claimId}`);
  };
  const handleClose = async => {
    if (closePopup) closePopup();
    else history.goBack();
  };

  return (
    <div className="main-popup-container" id="req-link">
      <div className="popup-container">
        <div className="closeImage" role="none" onClick={handleClose}>
          <IoIosClose size={30} color="#316FD0" />
        </div>
        <div className="email-field-new">
          <div className="input-label-new">
            <label htmlFor="reqlink" className="heading-labels">
              Request Link Sent
            </label>
          </div>
          <div className="reqLinkBtn">
            <PrimaryButton className="new_claims_btn" title="GO TO CLAIM" onClick={handleBtn} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default RequestLink;
