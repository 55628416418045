import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { v4 as uuidv4 } from 'uuid';
import Snackbar from '@material-ui/core/Snackbar';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
	dropzone: {
		border: '2px dashed #888',
		padding: '20px',
		borderRadius: '8px',
		backgroundColor: '#f5f5f5',
		cursor: 'pointer',
		textAlign: 'center',
		transition: 'border-color 0.3s',
		position: 'relative',
		minWidth: '1064px',
		maxWidth: '1064px',
		minHeight: '200px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		'&:hover': {
			borderColor: '#0057e7',
		},
	},
	previewContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		marginTop: '20px',
	},
	previewImageWrapper: {
		position: 'relative',
		margin: '10px',
		'&:hover $removeButton': {
			opacity: 1,
		},
	},
	previewImage: {
		width: '150px',
		height: '150px',
		objectFit: 'cover',
		borderRadius: '4px',
		boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
	},
	removeButton: {
		position: 'absolute',
		top: 2,
		right: 2,
		backgroundColor: 'rgba(255,255,255)',
		borderRadius: '50%',
		opacity: 0,
		transition: 'opacity 0.3s',
		'&:hover': {
			backgroundColor: 'rgba(255,255,255, 0.7)',
		}
	},
	progressWrapper: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '150px',
		height: '150px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'rgba(255, 255, 255, 0.5)',
		borderRadius: '4px',
	},
	message: {
		margin: '10px 0',
	},
	buttonsContainer: {
		marginTop: '20px',
	},
	resetButton: {
		marginLeft: 10,
		backgroundColor: '#e90001',
		'&:hover': {
			backgroundColor: '#e90001',
			opacity: 0.8,
		}
	},
	uploadButton: {
		backgroundColor: '#537bb8',
		'&:hover': {
			backgroundColor: '#537bb8',
			opacity: 0.8,
		}
	}
});

const maxTotalSize = 10 * 1024 * 1024; // 10MB in bytes

const UploadRoomImages = () => {
	const classes = useStyles();
	const [files, setFiles] = useState([]);
	const [imagesUploaded, setImagesUploaded] = useState(false);
	const [uploadInProgress, setUploadInProgress] = useState(false);
	const [uploadingError, setUploadingError] = useState(false);
	const [totalSize, setTotalSize] = useState(0);
	const maxSizeReached = totalSize >= maxTotalSize;
	const history = useHistory();

	useEffect(() => {
		const newTotalSize = files.reduce((acc, { file }) => {
			return acc + file.size;
		}, 0);
		setTotalSize(newTotalSize);
	}, [files, totalSize]);

	const onDrop = useCallback(acceptedFiles => {
		const newFiles = acceptedFiles.map(file => ({
			file,
			preview: URL.createObjectURL(file),
			progress: 0
		}));

		setFiles(prevFiles => [...prevFiles, ...newFiles]);
	}, []);

	const removeFile = file => event => {
		event.stopPropagation();

		setFiles(prevFiles => {
			const newFiles = prevFiles.filter(f => f.file !== file.file);
			URL.revokeObjectURL(file.preview);
			return newFiles;
		});
	};

	const resetFiles = () => {
		files.forEach(file => URL.revokeObjectURL(file.preview));
		setFiles([]);
		setImagesUploaded(false);
		setUploadInProgress(false);
		setUploadingError(false);
	};

	const uploadFiles = async () => {
		setUploadInProgress(true);
		setUploadingError(false);

		const captureId = uuidv4();
		const url = `${process.env.REACT_APP_NEURO_API_URL}/capture/${captureId}`;
		const formData = new FormData();

		files.forEach((fileObj, idx) => {
			formData.append('files_' + (idx + 1), fileObj.file); // Use 'files' to match expected backend parameter name
		});

		formData.append('capture_id', captureId);

		try {
			await axios.post(url, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				onUploadProgress: progressEvent => {
					const total = progressEvent.total;
					const loaded = progressEvent.loaded;

					const fileProgress = Math.round((loaded * 100) / (total));
					setFiles(prevFiles => prevFiles.map(f => ({ ...f, progress: fileProgress })));
				}
			});
			setImagesUploaded(true);
			axios.post(url + '/start', { capture_id: captureId }).then((response) => {
				history.push(`/app/newcapture/${captureId}`);
			});
		} catch (error) {
			console.error('Error uploading files:', error);
			setUploadingError(true);
		} finally {
			setUploadInProgress(false);
		}
	};

	useEffect(() => {
		return () => {
			files.forEach(file => URL.revokeObjectURL(file.preview));
		};
	}, [files]);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		multiple: true,
		accept: {
			'image/png': ['.png'],
			'image/jpeg': ['.jpg', '.jpeg'],
		}
	});

	const previews = files.map((fileObj) => (
		<div key={fileObj.file.name + fileObj.file.size} className={classes.previewImageWrapper}>
			<img
				src={fileObj.preview}
				alt="Preview"
				className={classes.previewImage}
			/>
			{fileObj.progress > 0 && fileObj.progress < 100 && (
				<div className={classes.progressWrapper}>
					<CircularProgress variant="determinate" value={fileObj.progress} />
				</div>
			)}
			<IconButton size="small" className={classes.removeButton} onClick={removeFile(fileObj)}>
				<CloseIcon />
			</IconButton>
		</div>
	));

	return (
		<Container maxWidth="xl">
			<Box sx={{
				minHeight: 'calc(100dvh - 80px)',
				justifyContent: 'center',
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'column',
			}}>
				<div {...getRootProps()} className={classes.dropzone}>
					<input {...getInputProps()} />
					{!imagesUploaded && !uploadingError && !maxSizeReached && (
						<p className={classes.message}>{
							isDragActive
								? 'Drop the files here ...'
								: 'Drag and drop the images here, or click to select images'}
						</p>
					)}
					{imagesUploaded && (
						<p className={classes.message} style={{ color: 'green' }}>
							Images uploaded successfully!
						</p>
					)}
					{uploadingError && (
						<p className={classes.message} style={{ color: 'red' }}>
							An error occurred while uploading images, pleaser try again!
						</p>
					)}
					{maxSizeReached && (
						<p className={classes.message} style={{ color: 'red' }}>
							Total size of images exceeds the limit of 10MB!
						</p>
					)}
					<div className={classes.previewContainer}>
						{previews}
					</div>
				</div>
				<Snackbar open={imagesUploaded} autoHideDuration={6000} onClose={() => setImagesUploaded(false)} message="Execution Started." />
				<div className={classes.buttonsContainer}>
					<Button
						variant="contained"
						color="primary"
						disableElevation
						onClick={uploadFiles}
						className={classes.uploadButton}
						disabled={files.length === 0 || imagesUploaded || uploadInProgress || maxSizeReached}
					>
						Upload Files
					</Button>
					<Button
						variant="contained"
						color="secondary"
						disableElevation
						onClick={resetFiles}
						className={classes.resetButton}
						disabled={files.length === 0 || uploadInProgress}
					>
						Reset
					</Button>
				</div>
			</Box>
		</Container>
	);
};

export default UploadRoomImages;
