import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

const PrimaryButton = ({ title, className, Right, onClick, disabled }) => (
  <Button
    disabled={disabled}
    id="interface-button--primary"
    className={className}
    onClick={onClick}
  >
    <p>{title}</p>
    {Right ? <Right /> : null}
  </Button>
);

PrimaryButton.defaultProps = {
  className: '',
  Right: null,
  onClick: null,
  disabled: false,
};

PrimaryButton.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  Right: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default PrimaryButton;
