import React from 'react';
import styled from "styled-components";
import PropTypes from 'prop-types';
// NEW MODAL AND MODAL BODY ARE CURRENTLY INTEGRATED INTO THREE TIPS PAGE
const ModalOverlay = styled.div`
 @media (max-width: 415px) {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: ${props => props.showNone === false ? 'none' : 'flex'};
  justify-content: center;
  align-items: center;
  background-color: rgba(32, 32, 32, 0.5);
  z-index: 400;
 }

  @media (min-width: 416px) {
    display: none;
    background-color: #FFFFFF;
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 95%;
  bottom: 0;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-color: #FFFFFF;
`;

const FixedWidthModalInnerContent = styled.div`
  width: 370px;
  height: ${props => props.showNone === false ? '0' : '100%'};
  padding-left: .9rem;
  padding-left: .9rem;
  transition:  all .5;
  //transition-duration 10s linear;
`;

const NewModal = ({ children, modalState}) => {

  return (
    <ModalOverlay showNone={modalState}>
      <ModalContainer showNone={modalState}>
        <FixedWidthModalInnerContent>
          {children}
        </FixedWidthModalInnerContent>
      </ModalContainer>
    </ModalOverlay>        
  );
};

export default NewModal; 

NewModal.defaultProps = {
 modalState: false,
 children: ''
}

NewModal.propTypes = {
  modalState: PropTypes.bool,
  children: PropTypes.node
}

