import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GLBViewer from './GlbViewer';
import RoomSummaryTable from './RoomSummary';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper
    },
    appBar: {
        backgroundColor: '#537bb8',
    }
}));

export default function SimpleTabs() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [data, setData] = useState();
    const { captureId } = useParams();
    const history = useHistory();
    const [activeIndex, setActiveIndex] = useState(0);
    const [polling, setPolling] = useState(true);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_NEURO_API_URL}/capture/${captureId}/executions`).then((response) => {
            console.log(response.data);
        });
        axios.get(`${process.env.REACT_APP_NEURO_API_URL}/capture/${captureId}/results`).then((response) => {
            setData(response.data);
            setPolling(true);
        });
    }, []);

    useEffect(() => {
        if (polling) {
            const interval = setInterval(() => {
                axios(data?.['2d_floorplan_url']).then((response) => {
                    setPolling(false);
                    clearInterval(interval);
                }).catch((error) => {
                    console.log(error);
                });
            }, 5000);
        }
    }, [polling, data]);

    return (
        <div id="room-interface">
            <Button style={{ backgroundColor: '#537bb8' }} color='secondary' startIcon={<ArrowBack />} variant='contained' onClick={() => history.push(`/app/allclaim`)} >
                Back
            </Button>
            <div className="interface_top">
                <span style={{ textTransform: 'capitalize' }} className="breadcrumb_title">Room Information</span>
            </div>
            <Row>
                <Col>
                    <Row>
                        <Col xs='12' lg='4'>
                            {data?.images?.length < 1 && <h6>No Images Found for this Room</h6>}
                            <Carousel controls={false} interval={null} activeIndex={activeIndex}>
                                {data && data.images.map((image, index) => (
                                    <Carousel.Item key={index}>
                                        <img
                                            data-testid={`image-${index}`}
                                            className="d-block w-100"
                                            src={image?.url}
                                            alt={`Slide ${index}`}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </Col>
                        <Col>
                            <div style={{ display: 'flex', gap: 10 }} className='m-3 flex flex-row flex-wrap'>
                                {data && data.images.map((image, index) => (
                                    <img data-testid={`image-thumbnail-${index}`} onClick={() => setActiveIndex(index)} src={image?.url} key={index} style={{ width: '100px', height: 'auto', marginBottom: '10px', cursor: 'pointer' }} />
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Col>
                {!polling && <Col>
                    <div className={classes.root}>
                        <AppBar className={classes.appBar} position="static">
                            <Tabs indicatorColor='primary' value={value} onChange={handleChange} aria-label="simple tabs example">
                                <Tab label="Floor Plan 2D" {...a11yProps(0)} />
                                <Tab label="Floor Plan 3D" {...a11yProps(1)} />
                                <Tab label="Model" {...a11yProps(2)} />
                                <Tab label="Output" {...a11yProps(3)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton edge="start" color="inherit" href={data?.['2d_floorplan_url']} aria-label="download">
                                    <CloudDownloadIcon />
                                </IconButton>
                            </div>
                            <img data-testid='2d_floorplan_url' alt='No Floor Plan' width='auto' height='auto' style={{ maxHeight: 500, cursor: 'pointer' }} src={data?.['2d_floorplan_url']} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Row>
                                <Col>
                                    <div data-testid='3d_floorplan_url' style={{ minHeight: '600px' }}>
                                        <GLBViewer scale={0.03} url={btoa(data?.['3d_floorplan_url'])} />
                                    </div>
                                </Col>
                            </Row>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <div data-testid='pointcloud_url'>
                                <GLBViewer scale={0.5} title={'Regular'} url={btoa(data?.['pointcloud_url'])} />
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <div data-testid='segmented_pointcloud_url'>
                                <GLBViewer scale={0.5} title={'Segmented'} url={btoa(data?.['segmented_pointcloud_url'])} />
                            </div>
                        </TabPanel>
                    </div>
                </Col>}
            </Row>
        </div>
    );
}
