import React, { Fragment, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import { FiX } from 'react-icons/fi';
import { RoomInterfaceContext } from '../../../context/RoomInterface';
import Input from '../../Input/Input';
import Select from '../../Select/Select';
import Checkbox from '../../Checkbox/Checkbox';

const Information = props => {
  let { variables, isEditMode } = props;
  const [orgVariables, setOrgVariables] = useState(variables);
  const { editData, setEditData } = useContext(RoomInterfaceContext);

  useEffect(() => setOrgVariables(variables), [variables]);

  const getData = el => editData.variables.find(cur => cur.variableId === el.variableId) ?? {};

  const handleChange = (value, obj, type) => {
    setEditData(prev => ({
      ...prev,
      variables: prev.variables.map(cur => {
        const temp = { ...cur };
        if (obj.variableId === temp.variableId) {
          if (temp.value.split(' ').length > 1) {
            if (type === 'first-value') {
              const innerText = temp.value.split(' ')[1].trim();
              return { ...temp, value: [value, innerText].join(' ') };
            }
            if (type === 'second-value') {
              const innerValue = temp.value.split(' ')[0].trim();
              return { ...temp, value: [innerValue, value].join(' ') };
            }
          }
          if (type === 'value') return { ...temp, value };
          if (type === 'title') return { ...temp, title: value };
          return temp;
        }
        return temp;
      }),
    }));
  };

  const handleAddParams = () => {
    const variableId = uuidv4();

    const addParam = state => [
      ...state,
      {
        variableId,
        title: '',
        value: '\t',
        options: [],
        type: 'display',
      },
    ];
    setOrgVariables(addParam);
    setEditData(prev => ({ ...prev, variables: addParam(prev.variables) }));
  };

  const handleDelete = el => {
    const deleteParam = state => state.filter(cur => cur.variableId !== el.variableId);
    setOrgVariables(deleteParam);
    setEditData(prev => ({ ...prev, variables: deleteParam(prev.variables) }));
  };

  const Delete = ({ data }) => (
    <p onClick={() => handleDelete(getData(data))} style={{ margin: 'initial', marginLeft: 5 }}>
      <FiX />
    </p>
  );

  if (isEditMode) {
    return (
      <div
        className="sub_details--information_wrapper sub_details--edit_information_wrapper"
        style={{ paddingTop: 0 }}
      >
        {orgVariables.map((cur, i) => (
          <Fragment key={i}>
            {cur.type === 'select' && (
              <div className="form_group">
                <p className="information_title">{cur.title}:</p>
                <Select variant="sm" options={cur.options} />
                <Delete data={cur} />
              </div>
            )}
            {cur.type === 'display' && cur.value && (
              <Fragment>
                {cur.value.split(' ').length > 1 ? (
                  <div className="form_group">
                    <Input
                      className="information_title"
                      value={getData(cur).title.trim()}
                      variant="sm"
                      icon={false}
                      onChange={e => handleChange(e.target.value, getData(cur), 'title')}
                    />
                    <Input
                      inputClassName="no-xs"
                      value={getData(cur).value.split(' ')[0].trim()}
                      variant="xs"
                      icon={false}
                      onChange={e => handleChange(e.target.value, getData(cur), 'first-value')}
                    />
                    <Input
                      className="ml-3"
                      value={getData(cur).value.split(' ')[1].trim()}
                      variant="x2s"
                      icon={false}
                      onChange={e => handleChange(e.target.value, getData(cur), 'second-value')}
                    />
                    <Delete data={cur} />
                  </div>
                ) : (
                  <div className="form_group">
                    <Input
                      className="information_title"
                      value={getData(cur).title.trim()}
                      variant="sm"
                      icon={false}
                      onChange={e => handleChange(e.target.value, getData(cur), 'title')}
                    />
                    <Input
                      value={getData(cur).value.trim()}
                      variant="sm"
                      icon={false}
                      onChange={e => handleChange(e.target.value, getData(cur), 'value')}
                    />
                    <Delete data={cur} />
                  </div>
                )}
              </Fragment>
            )}
            {cur.type === 'checkbox' && (
              <div className="form_group">
                <p className="information_title">{cur.title}:</p>
                <Checkbox id="tempBrokenList-checkbox" defaultChecked={cur.value} />
                <Delete data={cur} />
              </div>
            )}
          </Fragment>
        ))}
        <p style={{ padding: '4px 0 0 0', margin: 0 }} onClick={handleAddParams}>
          + Add Parameter
        </p>
      </div>
    );
  }

  return (
    <div
      className={classNames('sub_details--read_information_wrapper', {
        'sub_details--information_wrapper': orgVariables.length,
      })}
    >
      {orgVariables.map((cur, i) => (
        <Fragment key={i}>
          {cur.type === 'select' && (
            <div className="form_group">
              <p className="information_title">{cur.title}:</p>
              <Select variant="sm" options={cur.options} />
            </div>
          )}
          {cur.type === 'display' && cur.value && (
            <Fragment>
              {cur.value.split(' ').length > 1 ? (
                <div className="form_group">
                  <p className="information_title">{cur.title}:</p>
                  <Input
                    value={cur.value.split(' ')[0].trim()}
                    variant="sm"
                    innerText={cur.value.split(' ')[1].trim()}
                    icon={false}
                    readOnly
                  />
                </div>
              ) : (
                <div className="form_group">
                  <p className="information_title">{cur.title}:</p>
                  <Input value={cur.value} variant="sm" icon={false} readOnly />
                </div>
              )}
            </Fragment>
          )}
          {cur.type === 'checkbox' && (
            <div className="form_group">
              <p className="information_title">{cur.title}:</p>
              <Checkbox id="tempBrokenList-checkbox" defaultChecked={cur.value} />
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

Information.propTypes = {
  variables: PropTypes.array.isRequired,
};

export default Information;
