import React, { useState, Fragment, useEffect } from 'react';


const Qaclaim = () => {

  return (
    <Fragment>
      <div className="qauserpage">Qa user</div>
    </Fragment>
  );
};

export default Qaclaim;







