import React from 'react';
import PropTypes from 'prop-types';

const Box = ({ children, title, className, type, Right }) => (
  <div id="custom-box">
    {type === 'header' ? (
      <div className="custom_header_box box_layout">
        <div className="header">
          <p>{title}</p>
          {Right ? <Right /> : null}
        </div>
        <div className={`content_box ${className}`}>{children}</div>
      </div>
    ) : (
      <div className={`box_layout content_box ${className}`}>{children}</div>
    )}
  </div>
);

Box.defaultProps = {
  className: '',
  title: '',
  Right: null,
};

Box.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  Right: PropTypes.func,
};

export default Box;
