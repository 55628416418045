const initialClaimState = {
  isRendered: false,
  allClaims: [],
  totalClaims: 0,
  totalRooms: 0,
};

const ClaimReducer = (state = initialClaimState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SORT_CLAIM': {
      return {
        ...state,
        allClaims: payload,
      };
    }
    case 'GET_ALL_CLAIM': {
      return {
        ...state,
        allClaims: payload.claims,
        isRendered: true,
        totalClaims: payload.totalCount,
      };
    }
    case 'ADD_CLAIM': {
      return {
        ...state,
        allClaims: [...state.allClaims, payload],
        totalClaims: state.totalClaims + 1,
      };
    }
    case 'ADD_SHARABLE_LINK': {
      console.log('payload',payload);
      return {
        ...state,
        sharablablink: payload,
        // sharablablink: [...state.sharablablink, payload],
      };
    }
    case 'UPDATE_CLAIM': {
      const newClaims = state.allClaims.map(cur =>
        cur.claimId === payload.claimId ? payload : cur
      );

      return {
        ...state,
        allClaims: newClaims,
      };
    }
    case 'DELETE_CLAIM': {
      const newClaims = state.allClaims.filter(cur => cur.claimId !== payload);

      return {
        ...state,
        allClaims: newClaims,
        totalClaims: state.totalClaims - 1,
      };
    }
    case 'GET_ALL_ROOM': {
      // console.log('aaaaaaaaa', state.allClaims);
      const newClaims = state.allClaims.map(cur => {
        if (cur.claimId === payload.claimId) {
          const temp = { ...cur };
          console.log("asadsds",payload.data.rooms)
          temp.rooms = payload.data.rooms;
          return temp;
        }
        return cur;
      });

      return {
        ...state,
        allClaims: newClaims,
        totalRooms: payload.data.totalCount,
      };
    }
    case 'DELETE_ROOM': {
      const newClaims = state.allClaims.map(cur => {
        if (cur.claimId === payload.claimId) {
          const temp = { ...cur };
          temp.rooms = temp.rooms.filter(room => room.roomId !== payload.roomId);
          return temp;
        }
        return cur;
      });

      return {
        ...state,
        allClaims: newClaims,
        totalRooms: state.totalRooms - 1,
      };
    }
    case 'GET_ROOM': {
      const newClaims = state.allClaims.map(cur => {
        if (cur.claimId === payload.claimId) {
          const temp = { ...cur };
          temp.rooms = temp.rooms?.map(room => {
            if (room.roomId === payload.roomId) {
              const tempRoom = { ...room };
              tempRoom.interface = payload.data;
              return tempRoom;
            }
            return room;
          });
          return temp;
        }
        return cur;
      });

      return {
        ...state,
        allClaims: newClaims,
      };
    }
    default:
      return state;
  }
};

export default ClaimReducer;
