import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';


const userTypes = ['policy_holder', 'adjuster', 'contractor'];
const userLabels = ['Policy Holder', 'Adjuster', 'Contractor'];

export default function UserType({ onClose, onSelect, open }) {


    const handleListItemClick = (value) => {
        onSelect(value);
    };

    return (
        <Dialog fullWidth maxWidth='sm' open={open}>
            <DialogTitle>Please select an user Type (If applicable)</DialogTitle>
            <DialogContent>
                <List>
                    {userTypes.map((type, idx) => (
                        <>
                            <Divider />
                            <ListItem button onClick={() => handleListItemClick(type)} key={type}>
                                <ListItemText primary={userLabels[idx]} />
                            </ListItem>
                        </>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={() => onSelect('unknown')}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}