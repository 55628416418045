import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { login } from '../../../store/actions/auth';
import newLogo from '../../../assets/images/HostaAI_LogoWithTagline.png';
import PrimaryButton from '../../../components/Buttons/PrimaryButton/PrimaryButton';
import '../Common.scss';
import { isValidEmailOrPhoneNumber } from '../../../utils/utils';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [modalShown, setModalShown] = useState(false);
  const [modalErrorText, setModalErrorText] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  const checkEnter = e => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      Checks();
    }
  };
  const Checks = async () => {
    setErrorEmail(null);
    setErrorPassword(null);
    if (!isValidEmailOrPhoneNumber(email.trim(), true, false)) {
      setErrorEmail('Please Enter A Valid Email Address!');
    } else if (password.trim() === '') {
      setErrorPassword('Please Enter Password!');
    } else {
      const res = await dispatch(
        login({
          email,
          password,
        })
      );
      console.log('res:', res);
      if (res === 'login') {
        history.push('/app/allclaim');

      } else if (res === 'newPassword') {
        history.push('/app/allclaim');

      } else {
        setModalErrorText(res);
        setModalShown(true);
      }
    }
  };

  return (
    <div id="login" className="main-conatiner">
      <div className="bgImg" />
      <div className="inner-div">

        <img className='width-full' src={newLogo} alt="Logo" />

        <div className="floating-input-div">
          <input
            className="floating-input"
            type="text"
            placeholder="Email"
            onKeyDown={e => checkEnter(e)}
            onChange={e => setEmail(e.target.value)}
            value={email}
          />
          {errorEmail ? (
            <div>
              <p className="ErrorDivEmail">{errorEmail}</p>
            </div>
          ) : null}
        </div>
        <div className="floating-input-div input-container">
          <input
            className="floating-input"
            type={passwordShown ? 'text' : 'password'}
            placeholder="Password"
            onKeyDown={e => checkEnter(e)}
            onChange={e => setPassword(e.target.value)}
            value={password}
          />
          {passwordShown ? (
            <FaEyeSlash
              className="trailIcon"
              size={30}
              onClick={() => setPasswordShown(pass => !pass)}
            />
          ) : (
            <FaEye
              className="trailIcon"
              size={30}
              onClick={() => setPasswordShown(pass => !pass)}
            />
          )}
          {errorPassword ? (
            <div>
              <p className="ErrorDivEmail">{errorPassword}</p>
            </div>
          ) : null}
          <div
            className="textLink"
            role="presentation"
            onClick={() => history.push('/forgotpassword')}
          >
            <u className="forgotPassTxt">Forgot Password?</u>
          </div>
        </div>
        <div className="button">
          <Button className="newButton" onClick={Checks}>
            Login
          </Button>
        </div>
        <div className="createAcc">
          <p role="none" className="createAccTxt" onClick={() => history.push('/signup')}>
            Create your account
          </p>
        </div>
      </div>
      <Modal
        show={modalShown}
        onHide={() => setModalShown(false)}
        centered
        animation
        size="md"
        className="shadow-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" />
        </Modal.Header>
        <Modal.Body className="text-center">
          <h3>{modalErrorText}</h3>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <div>
            <PrimaryButton
              className="new_claims_btn"
              title="OK"
              onClick={() => setModalShown(false)}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Login;
