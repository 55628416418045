import React, { useState } from 'react';
import { Provider } from 'react-redux';
import Routing from './Routing';
import store from './store/store';
import './styles/style.scss';
import Theme from './new-portal-components/Theme/Theme';
import NewPortalContext from './new-portal-context/NewPortalContext';
import '@fontsource/work-sans';
import * as Sentry from "@sentry/react";
import 'date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const App = () => {
  const [roomType, updateRoomType] = useState('');
  const [returnUrlPath, updateReturnUrlPath] = useState('');
  const [ oneImageFromContext, updateOneImageFromContext] = useState(false);
  const [ preservedTempClaimIdInContext, updatePreservedTempClaimIdInContext ] = useState('');
  const [ preservedRoomIdInContext, updatePreservedRoomIdInContext ] = useState('');
  const [ linkIdFromParameter, updateLinkIdFromParameter ] = useState('');
  const [ returnedFromNewUI, updateReturnedFromNewUI ] = useState(false);
  
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Provider store={store}>
        <NewPortalContext.Provider
          value={{
            roomType, updateRoomType,
            returnUrlPath, updateReturnUrlPath,
            oneImageFromContext, updateOneImageFromContext,
            preservedTempClaimIdInContext, updatePreservedTempClaimIdInContext,
            preservedRoomIdInContext, updatePreservedRoomIdInContext,
            linkIdFromParameter, updateLinkIdFromParameter,
            returnedFromNewUI, updateReturnedFromNewUI
          }}>
          <Theme>
            <Routing />
          </Theme>
        </NewPortalContext.Provider>
      </Provider>
    </MuiPickersUtilsProvider>
  );
};

export default Sentry.withProfiler(App);

