import React, { useState } from 'react';
import { IoIosAdd, IoIosClose, IoMdTrash, IoMdInformationCircleOutline ,IoIosCloudUpload } from 'react-icons/io';
import { IoTriangle } from "react-icons/io5";
import { v4 as uuidv4 } from 'uuid';
import { useDropzone } from 'react-dropzone';
import Compressor from 'compressorjs';
import fallback from '../../../../../src/images/fallimageh.png';

const ImageAdd = ({ index, currImg, images, setImages, isWall, isTrash , isImagesError,isUploadingImages}) => {
  const [info, setInfo] = useState(false);
  const [retake, setRetake] = useState(null);
  // const [compressedPhoto, addCompressedPhoto] = useState('');
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".png"]
    },
    maxFiles: 1,
    onDrop: acceptedFiles => {
      // NewAddedSTARTSHere
      acceptedFiles.map(file => {
        const filename = file.name.toLowerCase();
        Object.defineProperty(file, 'name', {
          writable: true,
          value: filename
        });
        console.log('TOLOWER', file.name);
        const origFileSize = file.size;
        const originalFileSize = file.size;
        const compressionLevel =  originalFileSize < 1000000 ? 1 : .9;
        console.log('THIS IS LOWER FILE NAME', file.name);
        console.log(file.name, 'OriginalFileSize:', originalFileSize);
        console.log('compressionLevel', compressionLevel);
        console.log('OriginalFileSize:', origFileSize);
        new Compressor(file, {
            maxHeight: 1024,
            maxWidth: 1024,
            // maxHeight: 2048,
            // maxWidth: 2048,
            //   maxHeight: 3072,
            // maxWidth: 3072,
            // maxHeight: 4096,
            // maxWidth: 4096,
            quality: compressionLevel,
            success: (compressedResult) => {
              const newCompressedFile = new File([compressedResult], file.name);
              console.log(file.name, 'CompressedFileSize', newCompressedFile.size);
              addCompressedPhoto(newCompressedFile);
            },
            error(err) {
              console.log('COMPRESSOR-ERROR', err.message);
            }
        });
      });
      // NewAddedENDSHere
      const addCompressedPhoto = (compressedPhoto) => {
        const getUUID = sumVal => uuidv4();
        const ids = getUUID(compressedPhoto);
        const assignObject = file => {
          return (
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              id: ids
            })
          );
        };
        const newFile = assignObject(compressedPhoto);
        let newImages = [...images];
        newFile && (newImages[index] = newFile);
        if (!isWall && newImages?.length === 2 && !newImages?.includes(null)) {
          newImages = [...newImages, null, null];
        }
        setImages(newImages);
      }
    },
  });

  const onError = e => {
    e.target.src = fallback;
  };

  const deleteConfirm = () => {
    if (window.confirm("Are you sure , you want to delete?")) {
      handleImgDelete();
    }
  };

  const handleImgDelete = () => {
    let newImages = [...images];
    newImages[index] = null;
    setImages(newImages);
  };

  const possibleFileTypes = [ '.jpg', '.jpeg', '.png', '.heic'];
  const verifiedType = pathName => possibleFileTypes.includes(currImg.path);

  return (
    <div className="singleimageDiv" {...getRootProps({ className: 'dropzone singleimageDiv' })}>
      {!currImg ? <input {...getInputProps()} /> : verifiedType(currImg.path) === true?  <input {...getInputProps()} /> : isImagesError === true ? <input {...getInputProps()} /> : null} 
      {/* {!currImg ? <input {...getInputProps()} /> : currImg?.path.includes(".gif") || currImg?.path.includes(".webp") || currImg?.path.includes(".tiff") ?  <input {...getInputProps()} /> : isImagesError === true ? <input {...getInputProps()} /> : null} */}
      {retake === currImg ? <input {...getInputProps()} /> : null}
      <div>
        {!currImg ? (
          <div className="uploadImageDiv">
            {/* <PrimaryButton title="UPLOAD IMAGE" className="btn-upload-img" /> */}
            <IoIosAdd size={50} />
            <p>Add Image</p>
          </div>
        ) : (
          <div className="img image">
            {isTrash ? <IoMdTrash size={25} color="#red" className="closeImgIconAddImage"
              onClick={() => {
                deleteConfirm()
              }}
            /> : null}
              { isUploadingImages === true &&
                <div style={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)",zIndex:100}}>
                  {/* <PrimaryButton title="UPLOAD IMAGE" className="btn-upload-img" /> */}
                  <IoIosCloudUpload size={50} />
                  <p>Uploading...</p>
                </div>
              }
              { isUploadingImages === true &&
                <div className="uploadedimg uploadedimage" style={{position:"absolute",background:"black",opacity:0.7}}>
                </div>
              }
            <img
              src={currImg?.preview}
              className="uploadedimg uploadedimage"
              id={currImg?.id}
              alt="upload"
              style={verifiedType(currImg.path) === true ? { border: "1px dotted red" } : isImagesError === true ? { border: "1px dotted red" } : {}}
              //style={currImg?.path.includes(".gif") || currImg?.path.includes(".webp") || currImg?.path.includes(".tiff") ? { border: "1px dotted red" } : isImagesError === true ? { border: "1px dotted red" } : {}}
              onError={e => onError(e)}
            />
          </div>
        )}
      </div>
      {!currImg ? (
        isWall ? (
          <p style={{ color: '#2E3949' }}>Wall&nbsp;{index + 1}</p>
        ) : null
      ) : (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            margin: '3px 0px 5px',
            fontFamily: "WorkSans-Regular",
            padding: "0px 20px"
          }}
        >
          {' '}
         
          {verifiedType(currImg.path) === true ?
            <p style={{ color: "red" }}>Sorry, this is  an unsupported file type <IoMdInformationCircleOutline onMouseEnter={() => setInfo(true)} onMouseLeave={() => setInfo(false)} onTouchStart={() => setInfo(true)} onTouchEnd={() => setInfo(false)} /></p>
            :
            isImagesError === true ?
            <p style={{ color: "red" }}>Sorry, this is  an unsupported file type <IoMdInformationCircleOutline onMouseEnter={() => setInfo(true)} onMouseLeave={() => setInfo(false)} onTouchStart={() => setInfo(true)} onTouchEnd={() => setInfo(false)} /></p>
            :
            <>
              {isWall ? <p style={{ color: '#2E3949' }}>Wall&nbsp;{index + 1}</p> : <span>&nbsp;&nbsp;&nbsp;</span>}
              <p style={{ cursor: 'pointer' }} onMouseDown={() => { setRetake(currImg); }} onMouseUp={() => { setRetake(currImg); }}>Retake photo &nbsp;</p></>
          }
          {info ?
            <>
              <IoTriangle className='tostArrow' />
              <div className='tostMessageDiv'>
                <p>Awailable Formats</p>
                <span>PNG, JPG, JPEG</span>
              </div>
            </>
            : null}
        </div>
      )}
    </div>
  );
};

export default ImageAdd;
