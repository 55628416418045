import { globalAPICallback } from './UserPool';
import axios from '../axios';

export const getAllRoom = (claimId, params) => async dispatch =>
  globalAPICallback(async () => {
    console.log(claimId)
    const response = await axios({
      method: 'GET',
      url: `/claim/${claimId}/rooms?start=${params.start}&end=${params.end}`,
    });
    dispatch({
      type: 'GET_ALL_ROOM',
      payload: {
        claimId,
        data: response.data,
      },
    });
    return response.data.rooms;
  });

export const createRoom = (claimId, data) => {
    return globalAPICallback(async () => {
      const response = await axios({
        method: 'POST',
        url: `/claim/${claimId}/room`,
        data,
      });
      return response.data.roomId;
    });
  };

export const deleteRoom = (claimId, roomId) => async dispatch =>
  globalAPICallback(async () => {
    try {
      await axios({
        method: 'DELETE',
        url: `/claim/${claimId}/room/${roomId}`,
      });
      dispatch({
        type: 'DELETE_ROOM',
        payload: {
          claimId,
          roomId,
        },
      });
    } catch (e) {
      return false;
    }
    return true;
  });

export const getImageInformation = (claimId, roomId, ext, imageField, imageNumber) => async dispatch =>
globalAPICallback(async () => {
    const res = await axios({
      method: 'POST',
      url: `/claim/${claimId}/room/${roomId}/image`,
      data: {
        ext,
        imageField,
        imageNumber
      },
    }).catch((error)=>{
      return error
    });
    return res.data;
  });

export const saveCalculationList = (claimId, roomId, data) => async dispatch =>
  globalAPICallback(async () => {
    const res = await axios({
      method: 'PUT',
      url: `/claim/${claimId}/room/${roomId}`,
      data: { fieldsToUpdate: { calculationList: data } },
    });
    return res.data;
  });

export const changeRoomType = (claimId, roomId, value) => async dispatch =>
  globalAPICallback(async () => {
    const res = await axios({
      method: 'PUT',
      url: `/claim/${claimId}/room/${roomId}`,
      data: { fieldsToUpdate: { roomType: value } },
    });
    return res.data;
  });
export const changeSummery = (claimId, roomId, fieldsToUpdate) => async dispatch =>
  globalAPICallback(async () => {
    const res = await axios({
      method: 'PUT',
      url: `/claim/${claimId}/room/${roomId}`,
      data: { fieldsToUpdate },
    });
    return res.data;
  });


export const addImage = (link, data) => async dispatch =>
  // console.log('ADDIMAGE LINK', link);
  // console.log('ADDIMAGE DATA', data);
  globalAPICallback(async () => {
    await axios({
      method: 'POST',
      url: link,
      headers: {
        Authorization: '',
        'content-type': 'multipart/form-data',
      },
      data,
    });
    return true;
  });

export const sortRoom = data => async dispatch =>
  dispatch({
    type: 'SORT_ROOM',
    payload: data,
  });

export const downloadFloorPlan = (claimId, roomId) =>
  globalAPICallback(async () => {
    axios({
      method: 'GET',
      responseType: 'blob',
      url: `/claim/${claimId}/room/${roomId}/report?dynamic=True`,
      headers: {
        'Content-Type': 'application/octet-stream',
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  });
