import React, { useState, useContext } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IoIosClose } from 'react-icons/io';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton/PrimaryButton';
import { getAllRoom } from '../../../../../store/actions/allRoom';
import { closeLink } from '../../../../../store/actions/linkApi';
import "../../NewRoom.css"
import Submitmg from "../../../../../images/Submitmg.svg";


const RoomAdded = () => {
  const history = useHistory();
  const location = useLocation();
  const { claimId } = useParams();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
 
  if (success)
    return (
      <div className="item-center">
        <h1 className="success">Thank You</h1>
      </div>
    );

  return (
    <div className="main-popup-container" id="room-added">
      <div className="popup-container">
        <div
          className="closeImage"
          role="none"
          //X-button to close window
          //X-button to close window
          onClick={() => {
            sessionStorage.removeItem('ssRoomId');
            sessionStorage.removeItem('roomType');
            sessionStorage.removeItem('oneImagefromSession');
            sessionStorage.removeItem('returnUrlPath');
            sessionStorage.removeItem('returnedFromNewUI');
            if (location.state?.newLink) {
              //closes LinkId, LinkId is no longer needed
              closeLink(location.state?.newLink, { roomType: location.state?.roomType });
              setSuccess(true);
            } else {
              dispatch(getAllRoom(claimId, { start: 0, end: 9 }));
             
              history.push(`/app/allroom/${claimId}`);
            }
          }}
        >
          <IoIosClose style={{ cursor: 'pointer' }} size={30} color="#316FD0" />
        </div>
        <div className="email-field-new">
          <div className="input-label-new">
            <label htmlFor="reqlink" className="heading-labels">
            All done! Your room is submitted.
            </label>
          </div>
          <div className="content-label-new">
            <img src={Submitmg} />
          </div>
          <div className="reqLinkBtn">
            
            <PrimaryButton
              className="complete_btn"
              title="Finish!"
              onClick={() => {
                sessionStorage.removeItem('ssRoomId');
                sessionStorage.removeItem('roomType');
                sessionStorage.removeItem('oneImagefromSession');
                sessionStorage.removeItem('preservedTempClaimId');
                sessionStorage.removeItem('returnUrlPath');
                sessionStorage.removeItem('returnedFromNewUI');
                if (location.state?.newLink) {
                  //closes LinkId, LinkId is no longer needed
                  closeLink(location.state?.newLink, { roomType: location.state?.roomType });
                  setSuccess(true);
                } else {
                  dispatch(getAllRoom(claimId, { start: 0, end: 9 }));
                  history.push(`/app/allroom/${claimId}/`);
                }
              }}
            />
            <PrimaryButton
              className="add_another_room"
              title="Add Another Room"
              onClick={() => {
                sessionStorage.removeItem('ssRoomId');
                sessionStorage.removeItem('roomType');
                sessionStorage.removeItem('oneImagefromSession');
                //in this context linkId has been passed in as claimId
                if (location.state?.newLink) history.push(`/app/startme/${claimId}`);
                 //in this context claimId has been passed in as claimId
                 //(retrieved from context in /referencePageLayout route)
                else history.push(`/startme/param`);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default RoomAdded;
