import { createContext } from "react";

const NewPortalContext = createContext({
  //selectedRoom, is set in new Ui RoomButtonMapper
  //consumed by NewRoom NewClaim NewRoomLink to set roomvalue
  roomType: '', 
  updateRoomType: '', 
  //urlPath is set w route/Idstring in newRoom newClaim newRoomLink to set roomvalue
  //consumed when new UI needs to route back into old UI
  returnUrlPath:'',
  updateReturnUrlPath: '',
  //oneImageFromContext is set in NewClaim 381 handAddClaimAndRoom method
  //oneImageFromContext true supresses ENTER PROPERTY ADDRESS modal in NewClaim
  //oneImageFromContext true displays count === modal in NewClaim
  //Allroom 209 breadcrumbjs step1Onclick 'All Property' onclick update OneImageFromContext
  //from true to false, so that if "Add Property" button is clicked,  NewProperty Modal
  //will display, instead of imgUpload Modal
  oneImageFromContext: '',
  updateOneImageFromContext: '',
  //preservedClaimIdInContext stores TempClaimId at generation in 
  //handleAddClaimAndRoom.  Makes it available to HandleRoomId
  //on return to newUI (because state reloads)
  preservedTempClaimIdInContext: '',
  updatePreservedTempClaimIdInContext: '',
  //preservedRoomIdInContext stores roomId(ClaimRoomId) at generation in ReferencePageLayout
  //Previously this occured in  handleRoomId in /newRoom /newClaim /newRoomLink 
  //moved to RPLO because api call to create roomId was not completing in time before successive api call that
  //required roomId, causing errors on imgUpload
  preservedRoomIdInContext: '',
  updatePreservedRoomIdInContext: '',
  //the case of shareableLink when LinkId should be used to create roomId instead of claimId 
  //LinkId is pulled from param  in /startme, consumed in ReferencePageReferencePageLayout, to call createRoomWithLinkId
  linkIdFromParameter: '',
  updateLinkIdFromParameter: '',
  //This flag to call createRoomId  is needed ONLY in newClaim.js because out
  // of the three routes its the ONLY one that starts in itself then retuns to itself after,
  // newUi so we need this context flag on Useeffect to be set to false when we first  true when we leave
  //to the new ui and create roomid so that it does not fire the 
  // first time we load the page, ONLY when we've returned to the page, the second time, 
  // after newUi
  returnedFromNewUI: '',
  updateReturnedFromNewUI: ''
});

export default NewPortalContext;
  