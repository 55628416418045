import axios from 'axios';

const apiAddress = 'https://' + process.env.REACT_APP_ADD_ROOM_API_URL;
const apiStage = 'v1';

export const getLink = linkId =>
  new Promise((resolve, reject) => {
    axios
      .get(`${apiAddress}/${apiStage}/${linkId}`)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });

export const createRoomWithLinkId = (linkId, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${apiAddress}/${apiStage}/${linkId}`, data)
      .then(res => {
        console.log('GOT THE LINKROOMCREATED');
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });

export const getImageInformation = (linkId, roomId, ext, imageField, imageNumber) =>
  new Promise((resolve, reject) => {
    axios
      .post(
        `${apiAddress}/${apiStage}/${linkId}/${roomId}/image`,
       {
          ext,
          imageField,
          imageNumber
        },
        {
          headers: {
            Authorization: '',
            'content-type': 'multipart/form-data',
          },
        }
      )
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve();
      });
  });

export const addImage = (link, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(link, data, {
        headers: {
          Authorization: '',
          'content-type': 'multipart/form-data',
        },
      })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });

export const processRoom = (linkId, roomId, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${apiAddress}/${apiStage}/${linkId}/${roomId}/process`, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });

export const closeLink = (linkId, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${apiAddress}/${apiStage}/${linkId}/complete`, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
