import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { verify } from '../../../store/actions/auth';
import PrimaryButton from '../../../components/Buttons/PrimaryButton/PrimaryButton';
// import logo from '../../../assets/images/logo.png';
// import logoText from '../../../assets/images/logoText.png';
import newLogo from '../../../assets/images/HostaAI_LogoWithTagline.png';
import '../Common.scss';
import { Helmet } from 'react-helmet';

const NewLogo = styled.img`

  @media (max-width: 768px) {
    width: 75%;
    height:120%;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    width: 55%;
    height:120%;
  }

  @media (min-width: 991px)  {
    width: 35%;
    height:120%;
  }
`;

const Errors = () => {
    const [data, setData] = useState(null)
    const { roomId, linkId } = useParams()

    useEffect(async () => {
        try {
            const result = await fetch(`https://${process.env.REACT_APP_TASKS_API_URL}/v1/imageQa/${roomId}/${linkId}/rejections`)
            const json = await result.json()
            console.log(json)
            setData(json)
        }
        catch (ex) {
            console.log(ex)
        }
    }, [])

    const getCaptureLink = () => {
        return `https://${process.env.REACT_APP_CAPTURE_URL}/#/capture/${linkId}/${roomId}/tips`
    }

    return (
        <>
            <Helmet>
                <script src="https://cdn.tailwindcss.com"></script>
                <link href="https://cdn.jsdelivr.net/npm/tailwindcss@0.7.4/dist/tailwind.min.css" rel="stylesheet" />
            </Helmet>
            <div className="grid h-full place-items-center p-4">
                <div className='flex justify-center'>
                    <img className='m-auto w-64' src={newLogo} />
                </div>
                <h5 className='text-center'>Thank you for your photo submission through the Hosta a.i. room capture process.</h5>
                <h6 className='text-center'>Unfortunately, we have found some errors with the following image(s)</h6>

                <div className='flex justify-center'>
                    <div className="flex flex-col overflow-x-auto">
                        <div className="sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                                <div className="overflow-x-auto">
                                    <table className="min-w-full text-left text-sm font-light">
                                        <thead className="border-b font-medium dark:border-neutral-500">
                                            <tr>
                                                <th scope="col" className="px-6 py-4">Errors</th>
                                                <th scope="col" className="px-6 py-4">Image(s)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.roomBasedErrors && <tr className="border-b dark:border-neutral-500">
                                                <td className="whitespace-pre-wrap px-6 py-4 font-medium">
                                                    <div className='flex flex-col justify-center items-start'>
                                                        {data?.roomBasedErrors?.filter(e => e.error).map((e, idx) => { return <p key={idx}>{e.error}</p> })}
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap px-6 py-4">
                                                    {data?.roomBasedErrors?.filter(e => e.presignedUrls).map((e, idx) => {
                                                        return <div key={idx} className='flex flex-col justify-center'>{Object.values(e?.presignedUrls || {}).map(x => {
                                                            return <img alt='error desc' key={x} className='max-w-xs m-1' src={x} />
                                                        })
                                                        }</div>

                                                    })
                                                    }
                                                    {data?.roomBasedErrors?.filter(e => e.damageData).map(x => {
                                                        return x.damageData?.filter(e => e.length === 2).map((e, idx) => {
                                                            return <div key={idx} className='flex flex-col'>
                                                                <h5>Damage Set {idx + 1}</h5>
                                                                <div className='flex flex-col'>
                                                                    <img alt='error desc' className='h-1/6 w-full object-cover md:h-full md:w-48 m-1' src={e[0]} />
                                                                    <img alt='error desc' className='h-1/6 w-full object-cover md:h-full md:w-48 m-1' src={e[1]} />
                                                                </div>
                                                            </div>
                                                        })
                                                    })}
                                                </td>
                                            </tr>}
                                            {data?.imageBasedErrors?.map((e, idx) => {
                                                return <tr className="border-b dark:border-neutral-500" key={idx}>
                                                    <td className="whitespace-pre-wrap px-6 py-4 font-medium "><p>{e?.error?.join(', ')}</p></td>
                                                    <td className="whitespace-nowrap px-6 py-4">
                                                        <img  className='max-w-xs m-1' src={e?.presignedUrl} />
                                                    </td>

                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >
                <div className='flex justify-center'>
                    <p><b>IMPORTANT</b>: To successfully complete the process, please
                        click <a className='underline font-bold blue-text' href={data?.addRoomLink} target='_self'>here</a>&nbsp;to review our tips for capturing photos and
                        resubmit the room.</p>
                </div>
            </div >
        </>
    );
}

export default Errors;
