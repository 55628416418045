import React from 'react';
import styled from 'styled-components';
import LargeHeadline from '../../new-portal-components/SpanHeadlines/LargeHeadline';
import RoomButtonMapper from '../../new-portal-components/RoomButtonMapper/RoomButtonMapper';

const CombinedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Two_SelectTheRoom = () => {
  const smallText = 'Please select the room you’d like to begin with';
  
  return (
    <CombinedContainer>
      <LargeHeadline text={smallText} />
      <RoomButtonMapper />
    </CombinedContainer>
  );
};

export default Two_SelectTheRoom;