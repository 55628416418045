import React, { useState, Fragment } from 'react';
import Loader from '../../assets/images/image_loader.gif';

const CustomImage = props => {
  const [loading, setLoading] = useState(true);

  return (
    <Fragment>
       {loading ? <img style={{ position: 'absolute' }} src={Loader} alt={''} /> : null}
      <img onLoad={() => setLoading(false)} alt={''} {...props} />
    </Fragment>
  );
};

export default CustomImage;
