const initialClaimState = {
  credits: 0,
};

const CreditReducer = (state = initialClaimState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'GET_CREDITS': {
      return {
        ...state,
        credits: payload.credits,
      };
    }
    case 'SET_CREDITS': {
      return {
        ...state,
        credits: payload,
      };
    }
    default:
      return state;
  }
};

export default CreditReducer;
