import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import LargeHeadline from '../../new-portal-components/SpanHeadlines/LargeHeadline';
import SecondaryHeadline from '../../new-portal-components/SpanHeadlines/SecondaryHeadline';
import PhotoMapper from '../../new-portal-components/PhotoMapper/PhotoMapper';
import NewPortalContext from '../../new-portal-context/NewPortalContext';


const CombinedContainer = styled.div``;

const One_Combined_V2 = () => {
  //shareable newRoomLink will arrive here in /startme in newUI
  //if this is newroom or newclaim, param is 'param' returnURLpath was stored previously in context in those files
  //BUT if this is newroomlink, param is linkId. returnURLpath was NOT stored previously and is now empty 
  //For case of newRoomlink, linkId received here from param on arrival, used to create returnURlPath
  //and link Id passed to Roomchooser as PreservedTempClaimIdInContext
  //to pass into createRoom as parameter
  const { linkId } = useParams(); 
  // const { updateLinkIdFromParameter } = useContext(NewPortalContext);
 
  // useEffect (() => {
  //   if (linkId !== 'param') {
  //     sessionStorage.ssLinkIdFromParameter = linkId;
  //   }
  // }, []) ;
  if (linkId !== 'param') {
    sessionStorage.returnUrlPath = `/app/newroomlink/${linkId}`
    // const newRoomLinkReturnUrlPath =  `/app/newroomlink/${linkId}`;
    // const { updateReturnUrlPath } = useContext(NewPortalContext);
  };
  
 
  
  //used to create PreservedTempClaimIdInContext for RoomId in referencePageLayout, only in case of newRoomLInk
  //in case of newroom newclaim linkId will === 'param' and  PreservedTempClaimId is created in those files and pushed to context
  //also for roomId create in referencePageLayout
  // const { updatePreservedTempClaimIdInContext } = useContext(NewPortalContext);
  
 
  const largeText = 'Mapping your space, made simple';
  const secondaryText = 'This takes most people about 2 minutes per room to complete.';
 
  return (
    <CombinedContainer>
      <LargeHeadline text={largeText} />
      <SecondaryHeadline text={secondaryText} />
      <PhotoMapper />
    </CombinedContainer>
  );
};

export default One_Combined_V2;

