import React, { Fragment, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Accordion } from 'react-bootstrap';
import classNames from 'classnames';
import { FiEdit, FiCheck, FiX, FiRepeat, FiTrash2 } from 'react-icons/fi';
import { RiMenuAddFill } from 'react-icons/ri';
import { BiCopy, BiCheck } from 'react-icons/bi';
import { isArrEmpty } from '../../utils/utils';
import Information from './Information/Information';
import { RoomInterfaceContext } from '../../context/RoomInterface';
import BrokenListChild from './BrokenListChild/BrokenListChild';
import {
  deleteItem,
  postAddItem,
  putEditItem,
  reassignItem,
} from '../../store/actions/roomInterface';

const BrokenList = props => {
  const { className, data, dataKey, active, activeKey, isAccordion, recursive, type } = props;
  const changeParentRef = useRef();
  const params = useParams();
  const userType = useSelector(state => state.auth.user.userType);
  const { claimId, roomId } = params;
  const {
    setInterfaceData,
    calculationIds,
    setDetailsActive,
    setCalculationActive,
    setCalculationChecked,
    carouselIndex,
    setCarouselIndex,
    addData,
    setAddData,
    editData,
    setEditData,
    selectedArea,
    setSelectedArea,
    allAreas,
    changeData,
    setChangeData,
  } = useContext(RoomInterfaceContext);
  const recursiveNumber = activeKey.split('_').length - 1;

  const isArrowSeen = cur => !isArrEmpty(cur.children) || !isArrEmpty(cur.variables);

  const onSelect = async val => {
    if ((addData || editData) && !val) return;

    setCalculationChecked(false);
    await cancelAddData();
    await cancelEditData();
    cancelChangeData();
    const newActive = Object.entries(active).reduce((acc, value, index) => {
      const [k, v] = value;
      const number = !recursiveNumber ? 2 : recursiveNumber;
      if (index < number) {
        acc[k] = v;
        return acc;
      }
      return acc;
    }, {});

    if (type === 'calculation') {
      setCalculationActive({ [dataKey]: val });
      if (val) {
        const activeNavigation = calculationIds[+val.split('_')[1] - 1];
        const currentIndex = +activeNavigation.root.split('_')[0];
        if (carouselIndex !== currentIndex) setCarouselIndex(currentIndex);
        setDetailsActive(activeNavigation);
      }
      return false;
    }
    setCalculationActive({ [dataKey]: null });
    setDetailsActive({ ...newActive, [dataKey]: val });
    return { ...newActive, [dataKey]: val };
  };

  const setActiveToParent = current => {
    const parent =
      current ?? allAreas[carouselIndex].find(cur => cur.title === selectedArea.parentTitle);
    setDetailsActive(parent.navigate);
    setSelectedArea(parent);
  };

  const handleAdd = async cur => {
    const id = uuidv4();

    cur.children.push({
      children: [],
      polygon: [],
      id,
      title: '',
      unit: '',
      quantity: '',
      variables: [],
      temporary: true,
    });

    setCalculationChecked(false);
    await cancelEditData();
    setDetailsActive({
      ...active,
      [selectedArea.title]: `${active[dataKey]}_0${cur.children.length}`,
    });
    setAddData({ parentId: cur.unique_id });
  };

  const submitAddData = async cur => {
    const { parentId, title, unit, quantity } = addData;

    const res = await postAddItem(claimId, roomId, {
      parentId,
      title,
      unit,
      quantity,
    });
    if (res) {
      await setInterfaceData();
      setSelectedArea({ ...cur, title, unit, quantity });
    }
    setAddData(null);
  };

  const cancelAddData = async clearData => {
    if (clearData) {
      await setInterfaceData();
      setActiveToParent(selectedArea);
    }
    setAddData(null);
  };

  const submitEditData = async () => {
    const { unique_id, title, unit, quantity, variables } = editData;

    const data = {
      itemId: unique_id,
      updatedFields: {
        title,
        unit,
        quantity,
        variables: variables.map(cur => ({ ...cur, variableId: undefined })),
      },
    };

    const res = await putEditItem(claimId, roomId, data);
    if (res) await setInterfaceData();
    setEditData(null);
  };

  const cancelEditData = async clearData => {
    if (clearData) await setInterfaceData();
    setEditData(null);
  };

  const reAssignData = async (itemId, parentId) => {
    const res = await reassignItem(claimId, roomId, { itemId, parentId });
    setChangeData(null);
    if (res) {
      await setInterfaceData();
      setActiveToParent();
    }
  };

  const cancelChangeData = () => setChangeData(null);

  const handleSubmit = async cur => {
    if (addData) return submitAddData(cur);
    if (editData) return submitEditData();
    return null;
  };

  const handleCancel = async () => {
    if (addData) return cancelAddData(true);
    if (editData) return cancelEditData(true);
    return null;
  };

  const handleDelete = async item => {
    const res = await deleteItem(claimId, roomId, { itemId: item.unique_id });
    if (res) {
      await setInterfaceData();
      setActiveToParent();
    }
  };

  return (
    <Accordion
      className={classNames(className, `recursive_count_${recursiveNumber || 1}`)}
      id="broken-list"
      onSelect={onSelect}
      activeKey={active[dataKey]}
    >
      {data.map((cur, index) => {
        const singleKey = recursive ? `${activeKey}` : `${activeKey}_0${index + 1}`;

        return (
          <div className="broken_list_container" key={index}>
            <Accordion.Toggle
              as="div"
              variant="link"
              eventKey={singleKey}
              className={classNames('broken_list_header', {
                broken_list_header_active: singleKey === active[dataKey],
                broken_list_header_active_collapsed: !active[cur?.title],
                [`broken_list_header--${cur?.history && cur?.history[1]?.title}`]:
                  type === 'calculation',
              })}
            >
              <BrokenListChild
                current={cur}
                active={active}
                dataKey={dataKey}
                singleKey={singleKey}
                onSelect={onSelect}
                isArrowSeen={isArrowSeen}
                type={type}
              />
            </Accordion.Toggle>
            {type !== 'calculation' && (
              <Accordion.Collapse eventKey={singleKey}>
                <div className="broken_list_sub_details">
                  <Fragment>
                    {!active[cur.title] && userType !== 'AppUser' && (
                      <>
                        <div
                          className="sub_details--information_wrapper sub_details--buttons_information_wrapper"
                          style={{
                            borderRadius: !!editData ? 0 : cur.variables.length ? 0 : undefined,
                          }}
                        >
                          {!addData && !editData && (
                            <p className="temp_buttons" onClick={() => handleAdd(cur)}>
                              <RiMenuAddFill />
                            </p>
                          )}
                          {!addData && !editData && (
                            <p className="temp_buttons" onClick={() => setEditData(selectedArea)}>
                              <FiEdit />
                            </p>
                          )}
                          {(addData || editData) && (
                            <p className="temp_buttons" onClick={() => handleSubmit(cur)}>
                              <FiCheck />
                            </p>
                          )}
                          {(addData || editData) && (
                            <p className="temp_buttons" onClick={handleCancel}>
                              <FiX />
                            </p>
                          )}
                          {!addData && !editData && (
                            <p className="temp_buttons" onClick={() => setChangeData(cur)}>
                              <FiRepeat />
                            </p>
                          )}
                          {!addData && !editData && (
                            <p className="temp_buttons" onClick={() => handleDelete(cur)}>
                              <FiTrash2 />
                            </p>
                          )}
                        </div>
                        <div
                          className="sub_details--information_wrapper"
                          style={{
                            borderRadius: !!editData ? 0 : cur.variables.length ? 0 : undefined,
                          }}
                        >
                          <div style={{ width: '100%', margin: 0, textAlign: 'right' }}>
                            ID : <input value={cur.unique_id} disabled />
                            <BiCopy
                              onClick={() => navigator.clipboard.writeText(cur.unique_id)}
                              style={{ height: 'auto', width: 20 }}
                            />
                          </div>
                          {!addData && !editData && changeData === cur && (
                            <div style={{ width: '100%', margin: 0, textAlign: 'right' }}>
                              NEW PARENT ID : <input ref={changeParentRef} />
                              <BiCheck
                                style={{ height: 'auto', width: 20 }}
                                onClick={() => {
                                  changeParentRef.current.value.trim() === ''
                                    ? setChangeData(null)
                                    : reAssignData(cur.unique_id, changeParentRef.current.value);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    {!active[cur.title] && !addData && (
                      <Information
                        variables={
                          allAreas[carouselIndex].find(c => c.unique_id === cur.unique_id)
                            ?.variables ?? []
                        }
                        isEditMode={!!editData && editData.unique_id === cur.unique_id}
                      />
                    )}
                    {isAccordion &&
                      cur.children.map((sub, i) => {
                        const recursiveKey = `${singleKey}_0${i + 1}`;

                        return (
                          <div
                            className={classNames('recursive_broken_list_container', {
                              recursive_broken_list_container_has_children: isArrowSeen(sub),
                            })}
                            key={i}
                          >
                            <BrokenList
                              className="recursive_broken_list"
                              data={[sub]}
                              dataKey={cur.title}
                              active={active}
                              activeKey={recursiveKey}
                              isAccordion={isArrowSeen(sub)}
                              type="brokenList"
                              recursive
                            />
                          </div>
                        );
                      })}
                  </Fragment>
                </div>
              </Accordion.Collapse>
            )}
          </div>
        );
      })}
    </Accordion>
  );
};

BrokenList.defaultProps = {
  className: '',
  isAccordion: true,
  recursive: false,
};

BrokenList.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string.isRequired,
  active: PropTypes.object.isRequired,
  activeKey: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isAccordion: PropTypes.bool,
  recursive: PropTypes.bool,
};

export default BrokenList;
