import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LargeSpan = styled.p`
  font-size: 1.8rem;
  font-weight: 700; 
  line-height: 2.2rem;

  @media (min-width: 768px) {
    font-family: 'Work Sans';
    font-size: 3rem;
    font-weight: 720; 
    line-height: 4rem;
    text-align: center;
  }
`;

const LargeHeadline = ({ text }) => {
  return (
    <LargeSpan>{text}</LargeSpan>
  );
};
 
export default LargeHeadline;

LargeHeadline.defaultProps = {
  text: 'text here'
}

LargeHeadline.propTypes = {
  text: PropTypes.string
}