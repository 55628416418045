import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import headerLogoMobile from '../../new-portal-images/HeaderImgs/HostaAI_Logo.png';
import headerLogoDesktop from '../../new-portal-images/HeaderImgs/HostaAI_LogoWithTagline.png';
import backArrow from '../../new-portal-images/HeaderImgs/backArrow.svg';

const HeaderContainer = styled.div`
  width: 100%;
  height: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;

  @media (min-width: 768px) and (max-width: 990px) {
    width: 100vw;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    padding-right: 28rem;
    padding-bottom: 2.5rem;
  }

  @media (min-width: 991px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 195px;
    min-height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 3rem;
  }
`;

const BackButtonContainer = styled.div`
  display: flex;
  flex-direction:row;
  align-items: center;
  visibility: ${props => props.hideButton  &&  'hidden'};
  
  @media (min-width: 768px) and (max-width: 990px) {
    margin-top: 3rem;
  }

  @media (min-width: 991px) {
   margin-top: 3rem;
  }
`;

const BackArrow = styled.img`
`;

const NavSpan = styled.span`
  margin-left: 10px;
  font-size: 1rem;
  font-family: 'Work Sans';
  font-weight: 400;
  color: #2E3949;
  visibility: ${props => props.hideButton  &&  'hidden'};
  &:hover {
    color: #6d706e;
    cursor: pointer;
  }

  @media (min-width: 768px) {
    margin-top: .1rem;
   }
`;

const HeaderLogoMobile = styled.img`
  width: 5rem;
  height: 1.3rem;

  @media (min-width: 768px) {
    display: none;
  }
`;

const HeaderLogoDesktop = styled.img`
  width: 11rem;
  height: 2.7rem;
  margin-top: 3rem;

  @media (max-width: 768px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    margin-top: 3rem;
    margin-left: 20rem;
  }
`;

const NewHeader = ({
  hideBackButton, 
  hideResetButton
}) => {
  const history = useHistory();
  
  
  return (
    <HeaderContainer >
      {/* switch logo here!!! */}
      <HeaderLogoDesktop src={headerLogoDesktop}/> 
      <BackButtonContainer  
        onClick={() => history.goBack()}
        hideButton={hideBackButton}>
        <BackArrow src={backArrow} />
        <NavSpan>
          Back
        </NavSpan> 
      </BackButtonContainer>
      <HeaderLogoMobile src={headerLogoMobile}/> 
      <NavSpan hideButton={hideResetButton}>Reset</NavSpan> 
    </HeaderContainer>
  );
};

export default NewHeader;

NewHeader.defaultProps = {
  hideBackButton: false,
  hideResetButton: true
}

NewHeader.propTypes = {
  hideBackButton: PropTypes.bool,
  hideResetButton: PropTypes.bool
}
